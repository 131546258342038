import {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
  SetStateAction,
} from 'react';
import { useParams } from 'react-router-dom';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';

import {
  fetchManageGroupUserList,
  updateManageGroupUserList,
} from '../utils/group/manageGroupUserList';
import { fetchManageUnGroupUserList } from '../utils/group/manageUnGroupUserList';


import {
  ManageGroupUserListResult,
  ManageGroupUserListUpdateResult,
  UserInfo,
} from '../types/Group/manageGroupUserList.d';
import {
  MANAGE_GROUP_USER_LIST_API_RESULT_CODE,
  MANAGE_GROUP_USER_LIST_API_ERROR_TYPE,
} from '../apis/callManageGroupUserList';
import {
  MANAGE_UNGROUP_USER_LIST_API_ERROR_TYPE,
  MANAGE_UNGROUP_USER_LIST_API_RESULT_CODE,
} from '../apis/callManageUnGroupUserList';
import {
  isUnGroupUserListErrorType,
  isManageGroupUserListErrorType,
  isManageGroupUserListUpdateErrorType,
} from '../types/apis/apiErrorTypeGuard';
import { MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE } from '../apis/callManageGroupUserListUpdate';
import useSnackbar from './useSnackbar';
import useRedirectDialog from './useRedirectDialog';
import { colorsArray, SERVER_MESSAGE } from '../constants/constants';
import errorMessageCodes from '../constants/errorMessageCodes';

type IDParams = {
  groupId: string;
};

const useManageGroupUserList = (
  pageNumberForGroup: number,
  searchTextForGroup: '' | string,
  pageNumberForUnGroup: number,
  searchTextForUnGroup: '' | string,
  isFirstManageUserRender: boolean,
) => {
  // const { accessKey } = useAccessKey();
  const { t } = useSwitchLocaleLanguage();
  // const { onClickLogoff } = useLogout();

  const [isCallingApi, setIsCallingApi] = useState<boolean>(false);
  const [isCallingUnGroupUserApi, setCallingUnGroupUserApi] =
    useState<boolean>(false);
  const [isCallingUpdateApi, setIsCallingUpdateApi] = useState<boolean>(false);

  const [originalUserList, setOriginalUserList] = useState<UserInfo[]>([]);
  const [originalUnGroupUserList, setOriginalUnGroupUserList] = useState<
    UserInfo[]
  >([]);

  const [errorType, setErrorType] = useState<string>('');
  const [errorTypeUnGroup, setErrorTypeUnGroup] = useState<string>('');
  const [errorTypeUpdate, setErrorTypeUpdate] = useState<string>('');
  const [successTypeUpdate, setSuccessTypeUpdate] = useState<string>('');

  const [moreUser, setMoreUser] = useState<boolean>(false);
  const [moreUnGroupUser, setMoreUnGroupUser] = useState<boolean>(false);

  const totalGroupUser = useRef(0);
  const totalUnGroupUser = useRef(0);
  const { groupId } = useParams<IDParams>();

  const { displaySnackbar } = useSnackbar();
  const { displayRedirectDialog } = useRedirectDialog();

  // Group User List API Call
  const callManageGroupUserListApi = useCallback(() => {
    setIsCallingApi(true);
    /*eslint-disable*/
    fetchManageGroupUserList(
      // accessKey,
      parseInt(groupId!),
      pageNumberForGroup,
      searchTextForGroup,
    )
      .then((result: ManageGroupUserListResult) => {
        if (
          result.resultCode ===
            MANAGE_GROUP_USER_LIST_API_RESULT_CODE.NO_INTERNET ||
          result.resultCode ===
            MANAGE_GROUP_USER_LIST_API_RESULT_CODE.SUBSCRIPTION_PLAN_CHANGED ||
          result.resultCode ===
            MANAGE_GROUP_USER_LIST_API_RESULT_CODE.USER_CONSENT_REQUIRED ||
          result.resultCode ===
            MANAGE_GROUP_USER_LIST_API_RESULT_CODE.USER_ROLE_CHANGED
        ) {
          if (
            result.resultCode ===
            MANAGE_GROUP_USER_LIST_API_RESULT_CODE.NO_INTERNET
          ) {
            setErrorType(result.resultCode);
          }
          return;
        }
        totalGroupUser.current = result.totalCount;
        if (
          result.resultCode ===
          MANAGE_GROUP_USER_LIST_API_RESULT_CODE.INFO_NO_GROUP
        ) {
          displayRedirectDialog({
            open: true,
            title: t('common.error.invalidGroup'),
            message: t('common.error.unavailableGroup') + errorMessageCodes['common.error.unavailableGroup'],
          });
          setIsCallingApi(false);
          throw result.resultCode;
        } else if (
          result.resultCode ===
          MANAGE_GROUP_USER_LIST_API_RESULT_CODE.GROUP_ACCESS_DENIED
        ) {
          displayRedirectDialog({
            open: true,
            title: t('common.error.notAuthorized'),
            message: t('common.error.unauthorizedAccess') + errorMessageCodes['common.error.unauthorizedAccess.GROUP_ACCESS_DENIED'],
          });
          setIsCallingApi(false);
          throw result.resultCode;
        }
        if (
          result.resultCode !==
          MANAGE_GROUP_USER_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED
        ) {
          setOriginalUserList([]);
          setIsCallingApi(false);
          setErrorType(result.resultCode);
          setMoreUser(false);
          throw result.resultCode;
        }
        let updatedUserListWithColors: SetStateAction<UserInfo[]> = [];

        if (result.users != null) {
          updatedUserListWithColors = result.users.map((user, index) => ({
            ...user,
            avatarColor: colorsArray[index % 16],
          }));
        }

        if (result.users != null && originalUserList.length === 0) {
          setOriginalUserList(updatedUserListWithColors);
          setMoreUser(totalGroupUser.current < result.users.length);
          return;
        }
        if (
          result.users != null &&
          originalUserList.length > 0 &&
          !searchTextForGroup &&
          pageNumberForGroup === 1
        ) {
          setOriginalUserList(updatedUserListWithColors);
          setMoreUser(totalGroupUser.current < originalUserList.length);

          return;
        }
        if (
          result.users != null &&
          originalUserList.length > 0 &&
          !searchTextForGroup
        ) {
          const updatedListArray = Array.from(updatedUserListWithColors);

          setOriginalUserList((prevUserList) => [
            ...prevUserList,
            ...updatedListArray,
          ]);
          setMoreUser(totalGroupUser.current < originalUserList.length);

          return;
        }

        if (searchTextForGroup) {
          if (
            result.users != null &&
            originalUserList.length > 0 &&
            pageNumberForGroup > 1
          ) {
            const updatedListArray = Array.from(updatedUserListWithColors);

            setOriginalUserList((prevUserList) => [
              ...prevUserList,
              ...updatedListArray,
            ]);
            setMoreUser(totalGroupUser.current < originalUserList.length);

            return;
          }

          setOriginalUserList(updatedUserListWithColors);
          setMoreUser(totalGroupUser.current < originalUserList.length);

          return;
        }
      })
      .catch((error: MANAGE_GROUP_USER_LIST_API_ERROR_TYPE | unknown) => {
        if (isManageGroupUserListErrorType(error)) {
          setErrorType(error);
          if (
            error === MANAGE_GROUP_USER_LIST_API_RESULT_CODE.WARN_INVALID_AUTH
          ) {
            // displayRedirectDialog({
            //   open: true,
            //   title: t('common.error.notAuthorized'),
            //   message: t('common.error.roleChanged'),
            //   routePath: onClickLogoff,
            // });
          }
          if (error === MANAGE_GROUP_USER_LIST_API_RESULT_CODE.INFO_NO_GROUP) {
            displayRedirectDialog({
              open: true,
              title: t('common.error.invalidGroup'),
              message: t('common.error.unavailableGroup') + errorMessageCodes['common.error.unavailableGroup'],
            });
          }
          if (
            error === MANAGE_GROUP_USER_LIST_API_RESULT_CODE.GROUP_ACCESS_DENIED
          ) {
            displayRedirectDialog({
              open: true,
              title: t('common.error.notAuthorized'),
              message: t('common.error.unauthorizedAccess') + errorMessageCodes['common.error.unauthorizedAccess.GROUP_ACCESS_DENIED'],
            });
          }
        }
        if (
          error ===
          MANAGE_GROUP_USER_LIST_API_RESULT_CODE.SUBSCRIPTION_PLAN_CHANGED
        ) {
          setErrorType(
            MANAGE_GROUP_USER_LIST_API_RESULT_CODE.SUBSCRIPTION_PLAN_CHANGED,
          );
        } else {
          setErrorType(MANAGE_GROUP_USER_LIST_API_RESULT_CODE.ERR_UNKNOWN);
        }
      })
      .finally(() => {
        setIsCallingApi(false);
        setMoreUser(totalGroupUser.current < originalUserList.length);
      });
    /*eslint-disable*/
  }, [pageNumberForGroup, searchTextForGroup]);

  // UnGroup API Call
  const callManageUnGroupUserListApi = useCallback(() => {
    setCallingUnGroupUserApi(true);
    /*eslint-disable*/
    fetchManageUnGroupUserList(
      // accessKey,
      parseInt(groupId!),
      pageNumberForUnGroup,
      searchTextForUnGroup,
    )
      .then((result: ManageGroupUserListResult) => {
        totalUnGroupUser.current = result.totalCount;
        if (
          result.resultCode ===
            MANAGE_UNGROUP_USER_LIST_API_RESULT_CODE.NO_INTERNET ||
          result.resultCode ===
            MANAGE_UNGROUP_USER_LIST_API_RESULT_CODE.SUBSCRIPTION_PLAN_CHANGED ||
          result.resultCode ===
            MANAGE_UNGROUP_USER_LIST_API_RESULT_CODE.USER_ROLE_CHANGED ||
          result.resultCode ===
            MANAGE_UNGROUP_USER_LIST_API_RESULT_CODE.USER_CONSENT_REQUIRED
        ) {
          return;
        }
        if (
          result.resultCode ===
          MANAGE_UNGROUP_USER_LIST_API_RESULT_CODE.INFO_NO_GROUP
        ) {
          displayRedirectDialog({
            open: true,
            title: t('common.error.invalidGroup'),
            message: t('common.error.unavailableGroup') + errorMessageCodes['common.error.unavailableGroup'],
          });
          setIsCallingApi(false);
          throw result.resultCode;
        }
        if (
          result.resultCode !==
          MANAGE_GROUP_USER_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED
        ) {
          // Failed to acquire information
          setOriginalUnGroupUserList([]);
          setIsCallingApi(false);
          setMoreUnGroupUser(false);
          throw result.resultCode;
        }
        let updatedUserListWithColors: SetStateAction<UserInfo[]> = [];

        if (result.users != null) {
          updatedUserListWithColors = result.users.map((user, index) => ({
            ...user,
            avatarColor: colorsArray[index % 16],
          }));
        }
        if (result.users != null && originalUnGroupUserList.length === 0) {
          setOriginalUnGroupUserList(updatedUserListWithColors);
          setMoreUnGroupUser(totalUnGroupUser.current < result.users.length);
          return;
        }
        if (
          result.users != null &&
          originalUserList.length > 0 &&
          !searchTextForUnGroup &&
          pageNumberForUnGroup === 1
        ) {
          setOriginalUnGroupUserList(updatedUserListWithColors);
          setMoreUnGroupUser(
            totalGroupUser.current < originalUnGroupUserList.length,
          );

          return;
        }
        if (
          result.users != null &&
          originalUnGroupUserList.length > 0 &&
          !searchTextForUnGroup
        ) {
          const updatedListArray = Array.from(updatedUserListWithColors);
          setOriginalUnGroupUserList((prevUserList) => [
            ...prevUserList,
            ...updatedListArray,
          ]);
          setMoreUnGroupUser(
            totalUnGroupUser.current < originalUnGroupUserList.length,
          );

          return;
        }

        if (searchTextForUnGroup) {
          if (
            result.users != null &&
            originalUnGroupUserList.length > 0 &&
            pageNumberForUnGroup > 1
          ) {
            const updatedListArray = Array.from(updatedUserListWithColors);
            setOriginalUnGroupUserList((prevUserList) => [
              ...prevUserList,
              ...updatedListArray,
            ]);
            setMoreUnGroupUser(
              totalUnGroupUser.current < originalUnGroupUserList.length,
            );

            return;
          }
          setOriginalUnGroupUserList(updatedUserListWithColors);
          setMoreUnGroupUser(
            totalUnGroupUser.current < originalUnGroupUserList.length,
          );

          return;
        }
      })
      .catch((error: MANAGE_UNGROUP_USER_LIST_API_ERROR_TYPE | unknown) => {
        if (isUnGroupUserListErrorType(error)) {
          setErrorTypeUnGroup(error);
        } else {
          setErrorTypeUnGroup(
            MANAGE_UNGROUP_USER_LIST_API_RESULT_CODE.ERR_UNKNOWN,
          );
        }
      })
      .finally(() => {
        setCallingUnGroupUserApi(false);
        setMoreUnGroupUser(
          totalUnGroupUser.current < originalUnGroupUserList.length,
        );
      });
    /*eslint-disable*/
  }, [pageNumberForUnGroup, searchTextForUnGroup]);

  useEffect(() => {
    // manage user api call handle (call after manage user btn click)
    if (isFirstManageUserRender) {
      callManageGroupUserListApi();
      callManageUnGroupUserListApi();
    }
  }, [isFirstManageUserRender]);

  // call update users api
  const callUpdateGroupUserListApi = useCallback(
    (addedUserListPayload, removedUserListPayload) => {
      setIsCallingUpdateApi(true);
      /*eslint-disable*/
      updateManageGroupUserList(
        // accessKey,
        parseInt(groupId!),
        addedUserListPayload,
        removedUserListPayload,
      )
        .then((result: ManageGroupUserListUpdateResult) => {
          if (
            result.resultCode === SERVER_MESSAGE.USER_CONSENT_REQUIRED ||
            result.resultCode === SERVER_MESSAGE.USER_ROLE_CHANGED ||
            result.resultCode === SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED
          ) {
            return;
          }
          if (
            result.resultCode ===
            MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.INFO_NO_GROUP
          ) {
            // Failed to acquire information
            displayRedirectDialog({
              open: true,
              title: t('common.error.invalidGroup'),
              message: t('common.error.unavailableGroup') + errorMessageCodes['common.error.unavailableGroup'],
            });
            setErrorTypeUpdate(result.resultCode);
            throw result.resultCode;
          }
          if (
            result.resultCode ===
            MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.GROUP_ACCESS_DENIED
          ) {
            // Failed to acquire information
            displayRedirectDialog({
              open: true,
              title: t('common.error.notAuthorized'),
              message: t('common.error.unauthorizedAccess') + errorMessageCodes['common.error.unauthorizedAccess.GROUP_ACCESS_DENIED'],
            });
            setErrorTypeUpdate(result.resultCode);
            throw result.resultCode;
          }
          if (
            result.resultCode !==
            MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.SUCCESSFULLY_UPDATED
          ) {
            // Failed to acquire information
            setErrorTypeUpdate(result.resultCode);
            throw result.resultCode;
          }
          setSuccessTypeUpdate(result.resultCode);
          displaySnackbar({
            message: t('userListModal.message.updateSuccess'),
            timeout: 3002,
          });
        })
        .catch((error: MANAGE_GROUP_USER_LIST_API_ERROR_TYPE | unknown) => {
          if (isManageGroupUserListUpdateErrorType(error)) {
            setErrorTypeUpdate(error);
            if (
              error ===
              MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.WARN_INVALID_AUTH
            ) {
              // displayRedirectDialog({
              //   open: true,
              //   title: t('common.error.notAuthorized'),
              //   message: t('common.error.roleChanged'),
              //   routePath: onClickLogoff,
              // });
            }
          } else {
            setErrorTypeUpdate(
              MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.ERR_UNKNOWN,
            );
          }
        })
        .finally(() => {
          setIsCallingUpdateApi(false);
          setMoreUser(totalGroupUser.current < originalUserList.length);
        });
      /*eslint-disable*/
    },
    [],
  );

  // Update Group User List Error handle
  const errorMessageGroupUserUpdate = useMemo((): string => {
    if (!errorTypeUpdate) {
      return '';
    }
    switch (errorTypeUpdate) {
      case MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.WARN_INVALID_ACCESS_KEY:
        return t('groupDetail.error.unauthorizedUser') + errorMessageCodes['groupDetail.error.unauthorizedUser.WARN_INVALID_ACCESS_KEY'];
      case MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.WARN_INPUT_PARAM:
        return t('groupDetail.error.invalidURL') + errorMessageCodes['groupDetail.error.invalidURL'];
      case MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.INFO_NO_GROUP:
        return t('common.error.unavailableGroup') + errorMessageCodes['common.error.unavailableGroup'];
      case MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.INFO_NO_USER:
        return t('common.error.unavailableGroup') + errorMessageCodes['common.error.unavailableGroup.INFO_NO_USER'];
      case MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.ERR_UPDATE_DENIED:
        return t('userListModal.message.updateFailed') + errorMessageCodes['userListModal.message.updateFailed.ERR_UPDATE_DENIED'];

      default:
        return t('common.error.serverErr');
    }
  }, [errorTypeUpdate]);

  return {
    moreUser,
    moreUnGroupUser,
    totalGroupUser: totalGroupUser.current,
    totalUnGroupUser: totalUnGroupUser.current,
    isCallingApi,
    isCallingUnGroupUserApi,
    originalUserList,
    originalUnGroupUserList,
    setOriginalUserList,
    setOriginalUnGroupUserList,
    callManageGroupUserListApi,
    callManageUnGroupUserListApi,
    errorType,
    errorTypeUnGroup,
    errorTypeUpdate,
    callUpdateGroupUserListApi,
    isCallingUpdateApi,
    successTypeUpdate,
    errorMessageGroupUserUpdate,
    setIsCallingApi,
  };
};

export default useManageGroupUserList;

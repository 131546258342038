/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useLocation, useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import InputBase from '@mui/material/InputBase';
import {
  Button,
  Box,
  Container,
  Typography,
  styled,
  ClickAwayListener,
  List,
  Skeleton,
  ListItemButton,
  useMediaQuery,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Popper from '@mui/material/Popper';
import { useSelector } from 'react-redux';
import GroupFeatureTemplate from '../Templates/GroupFeatureTemplate';
import CommonDialog from '../Atoms/CommonDialog';
// assets
import { ReactComponent as GroupDevice } from '../../images/icon/no-device-latest.svg';
import { ReactComponent as EditIcon } from '../../images/icon/edit.svg';
import { ReactComponent as EditPenIcon } from '../../images/icon/edit-pen.svg';
import { ReactComponent as MenuIcon } from '../../images/icon/menu.svg';
import { ReactComponent as SaveEditIcon } from '../../images/icon/save-edit.svg';
import { ReactComponent as Close } from '../../images/icon/close-blue.svg';
import { ReactComponent as Delete } from '../../images/icon/delete.svg';
import { ReactComponent as RemoteTools } from '../../images/icon/remotetools.svg';
import { ReactComponent as ViewUserIcon } from '../../images/icon/view-user.svg';
import SearchField from '../Modecules/SearchField';
import useGroupDetail from '../../hooks/useGroupDetail';
import {
  FEATURE_PERMISSION,
  GROUP_NAME_VALIDATION,
  PAGE_PATH_NAME,
} from '../../constants/constants';
import UserDataDialog from '../Dialog/UserDataDialog';
import ManageDialog from '../Dialog/ManageDialog';
import RemoteDeviceSetupDialog from '../Dialog/RemoteDeviceSetupDialog';
import Loader from '../Atoms/Loader';

// common style
import { useStyles } from '../Templates/CommonStyle';
import GroupDeviceListTableTemplate from '../Templates/GroupDeviceListTable';
import { GROUP_NAME_EDIT_API_RESULT_CODE } from '../../apis/callGroupNameEdit';
import useGroupNameEdit from '../../hooks/useGroupNameEdit';
import { sendGAEvent, sendPageView } from '../../hooks/useGAEvent';
import useAccountInfo from '../../hooks/useAccountInfo';
import { AppDispatch, RootState, useAppDispatch } from '../../state/store';
import {
  GroupDetailsState,
  groupDetailsSlice,
} from '../../state/slice/groupDetailsSlice';
import useLocale, { LOCALE_CODE } from '../../hooks/useLocale';
import { GROUP_DETAIL_API_RESULT_CODE } from '../../apis/callGroupDetailApi';
import { groupListSlice } from '../../state/slice/groupListSlice';
import errorMessageCodes from '../../constants/errorMessageCodes';

type Input = {
  groupName: string;
};

const GroupDetailsPage = (): JSX.Element => {
  const { localeLanguage } = useLocale();
  const [openViewUsers, setOpenViewUsers] = React.useState(false);
  const [openManageGroupDialog, setOpenManageGroupDialog] =
    React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const navigate = useNavigate();

  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [remoteDeviceSetupDialogOpen, setRemoteDeviceSetupDialogOpen] =
    React.useState(false);

  const [tooltipEnabledGroupName, setTooltipEnabledGroupName] = useState(false);

  const groupDetailsStateInfo = useSelector<RootState, GroupDetailsState>(
    (state) => state.groupDetailsState,
  );

  const handleShowTooltipGroupName = ({
    currentTarget,
  }: React.MouseEvent<Element>) => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      setTooltipEnabledGroupName(true);
    } else {
      setTooltipEnabledGroupName(false);
    }
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);

    // GA event tag
    sendGAEvent('press_btn', 'btn_name', '3_point_leader');
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = isMenuOpen ? 'simple-popper' : undefined;
  // common styles
  const classes = useStyles();
  const [editState, setEditState] = useState(false);
  const IconButton = styled(Button)(() => ({
    minWidth: '38px',
    minHeight: '38px',
    backgroundColor: '#FFFFFF',
    padding: 0,
    border: '0.5px solid rgba(217, 217, 217, .5)',
  }));
  const { groupData, isCallingGroupDetailApi, t, onClickGroupDetail } =
    useGroupDetail(openManageGroupDialog);

  const {
    isCallingGroupNameEditApi,
    onClickGroupNameEdit,
    errorTypeEdit,
    setErrorTypeEdit,
    groupNameEditSuccess,
  } = useGroupNameEdit();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    clearErrors,
    resetField,
  } = useForm<Input>({ mode: 'onChange' });

  const validateGroupName = (value: string) => {
    const trimmedValue = value.trim();
    if (trimmedValue.length > 256) {
      return t('groupDetail.validation.maxLength');
    }

    return true;
  };

  const { groupName } = watch();
  const { addSearchText } = groupDetailsSlice.actions;

  // eslint-disable-next-line no-shadow
  const onSubmit: SubmitHandler<Input> = (groupName) => {
    /*eslint-disable*/
    onClickGroupNameEdit(
      groupName.groupName.trim(),
      groupData.current?.groupId!,
    );

    // GA event tag
    sendGAEvent(
      'edit_text_char',
      'input_group_name_value',
      groupName.groupName,
    );
  };

  useEffect(() => {
    setErrorTypeEdit(null);
  }, [groupName, setErrorTypeEdit]);

  useEffect(() => {
    if (groupNameEditSuccess) {
      resetField('groupName');
    }
  }, [groupNameEditSuccess]);

  const closeEditMode = () => {
    setEditState(false);
    // if (!isCallingGroupNameEditApi && errorTypeEdit === 'OK') {
    //   onClickGroupList();
    // }
    reset();

    // GA event tag
    sendGAEvent('press_btn', 'btn_name', 'cancel_edit_group_name');
  };

  const handleUserBtnClick = () => {
    if (groupData.current?.groupCode === 0) {
      accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPUSER?.includes(
        FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPUSER.VIEWLIST,
      ) && setOpenViewUsers(true);
    } else {
      (accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPUSERMODAL?.includes(
        FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPUSERMODAL.VIEWLIST,
      ) ||
        accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPDEVICEMODAL?.includes(
          FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPDEVICEMODAL.VIEWLIST,
        )) &&
        setOpenManageGroupDialog(true);
      reset();
      setEditState(false);
      // GA event tag
      sendGAEvent('press_btn', 'btn_name', 'manage_users');
    }
  };
  const [searchText, setSearchText] = useState<string>('');
  const groupNameLength = groupData.current?.groupName?.length;

  const { pathname, state } = useLocation();
  const { accountInfo } = useAccountInfo();

  useEffect(() => {
    const pageProps = {
      pagetitle: t('groupDetail.viewName'),
      pageLocation: pathname,
    };
    sendPageView(pageProps);
    if (accountInfo) {
      ReactGA.set({ userId: accountInfo.userName });
      ReactGA.gtag('set', 'user_properties', {
        userId: accountInfo.userName,
        role: accountInfo.roleName,
        corporate_id: accountInfo.corpId,
        corporate_name: accountInfo.company,
        language: accountInfo.locale,
      });
    }
  }, [pathname, accountInfo]);

  const groupId = groupData.current?.groupId.toString();
  useEffect(() => {
    if (
      !isCallingGroupNameEditApi &&
      errorTypeEdit === 'SUCCESSFULLY_UPDATED'
    ) {
      onClickGroupDetail(groupId!);
    }
    if (
      !isCallingGroupNameEditApi &&
      errorTypeEdit !== GROUP_NAME_EDIT_API_RESULT_CODE.WARN_GROUP_NAME_EXIST
    ) {
      setEditState(false);
    }
  }, [isCallingGroupNameEditApi]);

  // Enter Key disable when button disable
  const checkKeyDown = (e: any) => {
    if (
      errors.groupName ||
      groupName?.length === undefined ||
      errorTypeEdit === GROUP_NAME_EDIT_API_RESULT_CODE.WARN_GROUP_NAME_EXIST ||
      groupName === groupData.current?.groupName
    ) {
      if (e.code === 'Enter') e.preventDefault();
    }
  };
  const [isNoDataFromDeviceTable, setIsNoDataFromDeviceTable] =
    React.useState(false);
  const noDataErrorFromDeviceTable = (val: boolean) => {
    setIsNoDataFromDeviceTable(val);
  };

  const [isErrorFromDeviceTable, setIsErrorFromDeviceTable] =
    React.useState(false);
  const errorFromDeviceTable = (val: boolean) => {
    setIsErrorFromDeviceTable(val);
  };
  const dispatch: AppDispatch = useAppDispatch();
  const { isFromGroupDetailsPage } = groupListSlice.actions;

  useEffect(() => {
    dispatch(isFromGroupDetailsPage(true));
  }, [dispatch, isFromGroupDetailsPage]);

  useEffect(() => {
    // eslint-disable-next-line
    if (
      !accountInfo.featurePermissions?.GROUPMANAGEMENT?.GROUPBASIC?.includes(
        FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPBASIC.VIEWDETAILS,
      ) &&
      !accountInfo.subcriptionChange &&
      !isCallingGroupDetailApi &&
      !isCallingGroupNameEditApi
    ) {
      navigate(PAGE_PATH_NAME.DASHBOARD);
    }
    // eslint-disable-next-line
  }, [pathname]);

  const handleDeleteGropBtn = () => {
    setDeleteDialogOpen(true);
    setAnchorEl(null);
    // GA event tag
    sendGAEvent('press_btn', 'btn_name', 'delete_group');
    sendGAEvent('press_btn', 'delete_group_id_value', groupId);
  };

  const [offset, setOffset] = useState<[number, number]>([-20, -11]);
  const smallScreen = useMediaQuery('(max-width: 599px)');
  const mediumScreen = useMediaQuery(
    '(min-width: 600px) and (max-width: 899px)',
  );
  const largeScreen = useMediaQuery('(min-width: 900px)');

  useEffect(() => {
    if (smallScreen) {
      if (accountInfo.locale === 'ja') {
        setOffset([135, -11]);
      } else {
        setOffset([20, -11]);
      }
    } else if (mediumScreen) {
      if (accountInfo.locale === 'ja') {
        setOffset([132, -11]);
      } else {
        setOffset([15, -11]);
      }
    } else if (largeScreen) {
      setOffset([-20, -11]);
    }
  }, [smallScreen, mediumScreen, largeScreen]);

  return (
    <GroupFeatureTemplate
      titleText={t('groupDetail.viewName')}
      isUserUpdate={false}
    >
      <Container className={classes.groupDetailContainer}>
        <Box
          className={`${
            classes.groupDetailContainerBox
          } ${'manage-user-block'}`}
        >
          {/* edit mode disable */}
          {!editState ? (
            <>
              {isCallingGroupDetailApi ? (
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{ fontSize: '18px', width: '306px', mr: '13px' }}
                />
              ) : (
                <Tooltip
                  placement="top"
                  title={groupData?.current?.groupName!}
                  onMouseEnter={handleShowTooltipGroupName}
                  disableHoverListener={!tooltipEnabledGroupName}
                  arrow
                  sx={{ marginTop: 0 }}
                  PopperProps={{
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [-7, -7],
                        },
                      },
                    ],
                  }}
                  componentsProps={{
                    tooltip: {
                      className: classes.tooltipStyle,
                    },
                  }}
                >
                  <Typography className={classes.groupDetailGroupName}>
                    {groupData?.current?.groupName!}
                  </Typography>
                </Tooltip>
              )}
              {/* For other Group */}
              {groupData.current?.groupCode !== 0 && (
                <Tooltip
                  title={t('common.tooltip.editName') as string}
                  arrow
                  sx={{ marginTop: 0 }}
                  PopperProps={{
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, -7],
                        },
                      },
                    ],
                  }}
                  componentsProps={{
                    tooltip: {
                      className: classes.iconTooltipStyle,
                    },
                  }}
                >
                  {isCallingGroupDetailApi ? (
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{
                        p: 0,
                        marginRight: '5px',
                        width: '35px',
                        height: '60px',
                      }}
                    />
                  ) : (
                    <Button
                      id="editButton"
                      variant="contained"
                      className={classes.groupDetailEditIcon}
                      onClick={() => {
                        setEditState(true);
                        handleMenuClose();
                        // GA event tag
                        sendGAEvent('press_btn', 'btn_name', 'edit_group_name');
                      }}
                      disabled={
                        !accountInfo.featurePermissions?.GROUPMANAGEMENT?.GROUPNAME?.includes(
                          FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPNAME.EDIT,
                        )
                      }
                    >
                      <EditPenIcon />
                    </Button>
                  )}
                </Tooltip>
              )}
            </>
          ) : (
            // for Edit Group Name
            <ClickAwayListener onClickAway={() => clearErrors()}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                style={{
                  position: 'relative',
                  width: '500px',
                  display: 'flex',
                }}
                onKeyDown={(e) => checkKeyDown(e)}
              >
                <InputBase
                  placeholder={t('groupDetail.placeholder.setGroup')}
                  id="bootstrap-input"
                  className={classes.groupInput}
                  defaultValue={groupData?.current?.groupName}
                  autoFocus
                  sx={{
                    '& .MuiInputBase-input': {
                      // eslint-disable-next-line no-nested-ternary
                      border: errors.groupName
                        ? '0.5px solid #E1341E'
                        : errorTypeEdit ===
                          GROUP_NAME_EDIT_API_RESULT_CODE.WARN_GROUP_NAME_EXIST
                        ? '0.5px solid  #E1341E'
                        : '0.5px solid  #D9D9D9',
                      '&:focus': {
                        /* eslint-disable */
                        borderColor: errors.groupName
                          ? '#E1341E'
                          : errorTypeEdit ===
                            GROUP_NAME_EDIT_API_RESULT_CODE.WARN_GROUP_NAME_EXIST
                          ? '#E1341E'
                          : '#313133',
                      },
                    },
                  }}
                  {...register('groupName', {
                    required: t('common.validationError.required'),
                    minLength: 1,
                    validate: validateGroupName,
                  })}
                  autoComplete="off"
                />

                {errors.groupName ||
                errorTypeEdit ===
                  GROUP_NAME_EDIT_API_RESULT_CODE.WARN_GROUP_NAME_EXIST ||
                groupName?.length === undefined ||
                groupName.trim() === groupData?.current?.groupName! ||
                groupName.trim().length === 0 ||
                groupName.trim().length > 256 ? (
                  <IconButton className={classes.nameEditSaveIconBtn} disabled>
                    <SaveEditIcon />
                  </IconButton>
                ) : (
                  <Tooltip
                    PopperProps={{
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, -7.5],
                          },
                        },
                      ],
                    }}
                    componentsProps={{
                      tooltip: {
                        className: classes.iconTooltipStyle,
                      },
                    }}
                    title={t('common.tooltip.save') as string}
                    arrow
                  >
                    <IconButton
                      className={classes.nameEditSaveIconBtn}
                      type="submit"
                      onClick={() => {
                        // GA event tag
                        sendGAEvent(
                          'edit_text_char',
                          'txt_name',
                          'input_group_name',
                        );
                      }}
                      disabled={isCallingGroupNameEditApi}
                    >
                      {!isCallingGroupNameEditApi ? (
                        <SaveEditIcon
                          style={
                            groupName === undefined
                              ? { opacity: '0.5' }
                              : { opacity: '1' }
                          }
                        />
                      ) : (
                        <Loader colorCode="#939598" />
                      )}
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip
                  PopperProps={{
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, -7.5],
                        },
                      },
                    ],
                  }}
                  componentsProps={{
                    tooltip: {
                      className: classes.iconTooltipStyle,
                    },
                  }}
                  title={t('common.tooltip.cancel') as string}
                  arrow
                >
                  <IconButton
                    aria-label="cancel"
                    className={classes.closeIconBtn}
                    onClick={closeEditMode}
                    disabled={isCallingGroupNameEditApi}
                  >
                    <Close />
                  </IconButton>
                </Tooltip>

                {(errorTypeEdit ===
                  GROUP_NAME_EDIT_API_RESULT_CODE.WARN_GROUP_NAME_EXIST ||
                  errors.groupName) && (
                  <Box className={classes.groupNameExistWarningCard}>
                    <p style={{ padding: '0', margin: '0' }}>
                      {errorTypeEdit ===
                        GROUP_NAME_EDIT_API_RESULT_CODE.WARN_GROUP_NAME_EXIST &&
                        t('groupDetail.error.alreadyExist') + errorMessageCodes['groupDetail.error.alreadyExist.WARN_GROUP_NAME_EXIST']}
                      {errors.groupName && errors.groupName.message}
                    </p>
                  </Box>
                )}
              </form>
            </ClickAwayListener>
          )}
        </Box>
        <Box
          className={'search-block'}
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <SearchField
            clickFilterSearch={(val) => {
              setSearchText(val);
              dispatch(addSearchText(val));

              // GA event tag
              sendGAEvent('search_kw', 'txt_search_kw', 'input_search');
              sendGAEvent('search_kw', 'input_search_value', val);
            }}
            placeholder={t('groupDetail.placeholder.searchDevice')}
            responsiveClass={
              localeLanguage === LOCALE_CODE.FR || localeLanguage === LOCALE_CODE.ES
                ? 'search-input-fr'
                : 'search-input'
            }
            defaultValue={groupDetailsStateInfo.searchText}
          />

          <CommonDialog
            open={deleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
            icon={'helllos'}
            title={t('groupDetail.label.deleteConfirmation')}
            subTitle={t('groupDetail.label.deleteCaution')}
            btnBgColor="#E1341E"
            btnBgColorHover="#CC240F"
            btnTextNegative={t('groupDetail.label.cancelBtn')}
            btnTextPositive={t('groupDetail.label.deleteBtn')}
          />

          <Box className="setting-btn-block" sx={{ display: 'flex' }}>
            {isCallingGroupDetailApi ? (
              <Skeleton
                variant="text"
                animation="wave"
                className={classes.groupBtnIconSkeleton}
              />
            ) : (
              groupData.current?.groupCode !== 0 &&
              accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPBASIC?.filter(
                (x) =>
                  x === FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPBASIC.DELETE,
              ).length === 1 && (
                <Button
                  id="menuIconButton"
                  variant="contained"
                  className={classes.menuIconButton}
                  aria-controls={menuId}
                  aria-describedby={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  disabled={
                    !accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPBASIC?.includes(
                      FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPBASIC.DELETE,
                    )
                  }
                >
                  <MenuIcon />
                </Button>
              )
            )}
            <Popper
              placement="bottom-end"
              id={menuId}
              open={isMenuOpen}
              anchorEl={anchorEl}
              popperOptions={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: offset,
                      // offset:
                      //   accountInfo.locale === 'ja' ? [-109, -11] : offset,
                    },
                  },
                ],
              }}
              className={classes.remoteNDelete}
            >
              <ClickAwayListener onClickAway={handleMenuClose}>
                <List>
                  {groupData.current?.groupCode !== 0 &&
                    accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPBASIC?.filter(
                      (x) =>
                        x ===
                        FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPBASIC.DELETE,
                    ).length === 1 && (
                      <ListItemButton
                        disableRipple
                        onClick={handleDeleteGropBtn}
                        className={classes.deleteGroupBtn}
                      >
                        <Delete />{' '}
                        <p style={{ padding: '0', margin: '0' }}>
                          {t('groupDetail.label.deleteGroup')}
                        </p>
                      </ListItemButton>
                    )}
                </List>
              </ClickAwayListener>
            </Popper>

            {(accountInfo.featurePermissions.GROUPMANAGEMENT?.REMOTECONTROLWIFI?.includes(
              FEATURE_PERMISSION.GROUPMANAGEMENT.REMOTECONTROLWIFI.VIEWDETAILS,
            ) ||
              accountInfo.featurePermissions.GROUPMANAGEMENT?.REMOTECONTROLFEATURELIMIT?.includes(
                FEATURE_PERMISSION.GROUPMANAGEMENT.REMOTECONTROLFEATURELIMIT
                  .VIEWDETAILS,
              ) ||
              accountInfo.featurePermissions.GROUPMANAGEMENT?.REMOTECONTROLDATAPROTECTION?.includes(
                FEATURE_PERMISSION.GROUPMANAGEMENT.REMOTECONTROLDATAPROTECTION
                  .VIEWDETAILS,
              )) &&
              (isCallingGroupDetailApi ? (
                <Skeleton
                  variant="text"
                  animation="wave"
                  className={classes.groupBtnSkeleton}
                />
              ) : (
                <Button
                  id="remoteSettingsButton"
                  variant="outlined"
                  className={classes.remoteSettingsBtn}
                  onClick={() => {
                    setRemoteDeviceSetupDialogOpen(true);
                    // GA tag
                    sendGAEvent('press_btn_link', 'link_name', 'remote_setup');
                  }}
                  disableRipple
                >
                  <RemoteTools className={classes.groupBtnIcon} />
                  <Typography
                    className={`${classes.groupBtnText} ${classes.groupBtnTextSm}`}
                  >
                    {t('groupDetail.label.remoteDevice')}
                  </Typography>
                </Button>
              ))}

            {isCallingGroupDetailApi ? (
              <Skeleton
                variant="text"
                animation="wave"
                className={classes.groupBtnSkeleton}
              />
            ) : (
              <Button
                id="manageGroupButton"
                variant="contained"
                className={classes.manageGroupBtn}
                onClick={handleUserBtnClick}
                disableRipple
                disabled={
                  (groupData.current?.groupCode === 0 &&
                    !accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPUSER?.includes(
                      FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPUSER.VIEWLIST,
                    )) ||
                  (groupData.current?.groupCode !== 0 &&
                    !accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPDEVICEMODAL?.includes(
                      FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPDEVICEMODAL
                        .VIEWLIST,
                    ) &&
                    !accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPUSERMODAL?.includes(
                      FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPUSERMODAL
                        .VIEWLIST,
                    ))
                }
              >
                {groupData.current?.groupCode === 0 ? (
                  <ViewUserIcon />
                ) : (
                  <EditIcon className={classes.groupBtnIcon} />
                )}
                <Typography className={classes.groupBtnText}>
                  {groupData.current?.groupCode === 0
                    ? t('groupDetail.button.viewUsers')
                    : t('groupDetail.button.manageGroup')}
                </Typography>
              </Button>
            )}
          </Box>
        </Box>
        <div>
          {accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPDEVICE?.includes(
            FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPDEVICE.VIEWLIST,
          ) && (
            <Box className={`${classes.tableDeviceCard} table-device-card-res`}>
              {/* {groupData.current && groupData.current.totalDeviceCount > 0 && ( */}
              <GroupDeviceListTableTemplate
                searchText={searchText}
                openManageGroupDialog={openManageGroupDialog}
                noDataErrorFromDeviceTable={noDataErrorFromDeviceTable}
                errorFromDeviceTable={errorFromDeviceTable}
              />
              {/* )} */}

              {(groupData.current?.totalDeviceCount === 0 ||
                (isNoDataFromDeviceTable === true && searchText) ||
                isErrorFromDeviceTable) && (
                <Typography className={classes.groupDeviceListCountText}>
                  {t('groupDetail.label.deviceList')} (0)
                </Typography>
              )}

              {isErrorFromDeviceTable && (
                <Grid container className={classes.noGroupDeviceContainer}>
                  <GroupDevice className={classes.noGroupDeviceIcon} />

                  <Typography className={classes.noGroupDevicePrimaryText}>
                    {t('common.error.noData')}
                  </Typography>
                </Grid>
              )}

              {!isErrorFromDeviceTable &&
                (groupData.current?.totalDeviceCount === 0 ||
                  (isNoDataFromDeviceTable === true && searchText)) && (
                  <Grid container className={classes.noGroupDeviceContainer}>
                    <GroupDevice className={classes.noGroupDeviceIcon} />
                    {groupData.current?.groupCode !== 0 ? (
                      <>
                        <Typography
                          className={classes.noGroupDevicePrimaryText}
                        >
                          {searchText
                            ? t('groupDetail.error.noDevice')
                            : t('groupDetail.label.startOrganizing')}
                        </Typography>
                        <Typography
                          className={classes.noGroupDeviceSecondaryText}
                        >
                          {searchText
                            ? t('common.error.tryAgain')
                            : t('groupDetail.label.addMoreGroups')}
                        </Typography>
                        {!searchText && (
                          <Button
                            id="manageGroupButton"
                            variant="contained"
                            className={classes.manageGroupBtn}
                            sx={{
                              display:
                                isNoDataFromDeviceTable === true && searchText
                                  ? 'none'
                                  : '',
                              marginTop: '14px',
                            }}
                            onClick={handleUserBtnClick}
                            disableRipple
                            disabled={
                              !accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPDEVICEMODAL?.includes(
                                FEATURE_PERMISSION.GROUPMANAGEMENT
                                  .GROUPDEVICEMODAL.VIEWLIST,
                              ) &&
                              !accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPUSERMODAL?.includes(
                                FEATURE_PERMISSION.GROUPMANAGEMENT
                                  .GROUPUSERMODAL.VIEWLIST,
                              )
                            }
                          >
                            <EditIcon className={classes.groupBtnIcon} />

                            <Typography className={classes.groupBtnText}>
                              {t('groupDetail.button.manageGroup')}
                            </Typography>
                          </Button>
                        )}
                      </>
                    ) : (
                      <>
                        <Typography
                          className={classes.searchNoDeviceTextPrimary}
                        >
                          {isNoDataFromDeviceTable === true && searchText
                            ? t('groupDetail.error.noDevice')
                            : t('groupDetail.label.noDevice')}
                        </Typography>
                        <Typography
                          className={classes.searchNoDeviceTextSecondary}
                        >
                          {isNoDataFromDeviceTable === true && searchText
                            ? t('common.error.tryAgain')
                            : t('groupDetail.label.noUnassigned')}
                        </Typography>
                      </>
                    )}
                  </Grid>
                )}
            </Box>
          )}
          {openViewUsers &&
            accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPUSER?.includes(
              FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPUSER.VIEWLIST,
            ) && (
              <UserDataDialog
                open={openViewUsers}
                onClose={() => setOpenViewUsers(false)}
              />
            )}

          {openManageGroupDialog && (
            <ManageDialog
              open={openManageGroupDialog}
              onClose={() => {
                setOpenManageGroupDialog(false);
              }}
              groupId={
                groupData.current?.groupId ? groupData.current.groupId : 0
              }
            />
          )}
          {remoteDeviceSetupDialogOpen && (
            <RemoteDeviceSetupDialog
              open={remoteDeviceSetupDialogOpen}
              onClose={() => setRemoteDeviceSetupDialogOpen(false)}
            />
          )}
        </div>
      </Container>
    </GroupFeatureTemplate>
  );
};

export default GroupDetailsPage;

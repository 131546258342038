/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Container,
  Grid,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import InputBase from '@mui/material/InputBase';
import GroupFeatureTemplate from '../Templates/GroupFeatureTemplate';
// assets
import { ReactComponent as GroupDevice } from '../../images/icon/no-device-latest.svg';
import { ReactComponent as EditIcon } from '../../images/icon/edit.svg';
import { ReactComponent as SaveIcon } from '../../images/icon/save.svg';

import SearchField from '../Modecules/SearchField';
import useGroupAdd from '../../hooks/useGroupAdd';
import Loader from '../Atoms/Loader';
// common style
import { useStyles } from '../Templates/CommonStyle';
import { GROUP_ADD_API_RESULT_CODE } from '../../apis/callGroupAddApi';
import errorMessageCodes from '../../constants/errorMessageCodes';

type Input = {
  groupName: string;
};

const GroupAdd = (): JSX.Element => {
  // common styles
  const classes = useStyles();

  const {
    onClickGroupAdd,
    isCallingApi,
    t,
    errorType,
    setErrorType,
    onClickGroupValidationCheck,
    groupCheckApiErrorType,
    setGroupCheckApiErrorType,
  } = useGroupAdd();

  const {
    register,
    handleSubmit,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<Input>({ mode: 'onChange' });

  const validateGroupName = (value: string) => {
    const trimmedValue = value.trim();
    if (trimmedValue.length > 256) {
      return t('groupDetail.validation.maxLength');
    }

    return true;
  };

  const { groupName } = watch();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (groupName) {
        onClickGroupValidationCheck(groupName);
      } else {
        setGroupCheckApiErrorType(false);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupName]);

  /*eslint-disable*/
  const IconButton = styled(Button)(() => ({
    minWidth: '38px',
    minHeight: '38px',
    backgroundColor: '#FFFFFF',
    padding: 0,
    border: '0.5px solid rgba(217, 217, 217, .5)',
  }));

  // eslint-disable-next-line no-shadow
  const onSubmit: SubmitHandler<Input> = (groupName) => {
    onClickGroupAdd(groupName.groupName.trim());
  };

  useEffect(() => {
    setErrorType(null);
  }, [groupName, setErrorType]);

  const handleClickAway = () => {
    clearErrors();
    setErrorType(null);
  };

  // Enter Key disable when button disable
  const checkKeyDown = (e: any) => {
    if (
      errors.groupName ||
      groupName?.length === undefined ||
      groupName === '' ||
      errorType === GROUP_ADD_API_RESULT_CODE.WARN_GROUP_NAME_EXIST ||
      groupCheckApiErrorType
    ) {
      if (e.code === 'Enter') e.preventDefault();
    }
  };

  return (
    <GroupFeatureTemplate
      titleText={t('groupDetail.createGroup')}
      isUserUpdate={false}
    >
      <Container
        style={{
          paddingRight: '0',
          paddingLeft: '0',
          maxWidth: '100%',
        }}
        className="group-add"
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ position: 'relative', width: '500px', display: 'flex' }}
            onKeyDown={(e) => checkKeyDown(e)}
            className="group-add-set-group-name"
          >
            <InputBase
              placeholder={t('groupDetail.placeholder.setGroup')}
              id="bootstrap-input"
              className={`${classes.groupInput} group-add-set-group-input`}
              sx={{
                '& .MuiInputBase-input': {
                  '&:hover': {
                    border: '0.5px solid #939598',
                  },
                  // eslint-disable-next-line no-nested-ternary
                  border: errors.groupName
                    ? '0.5px solid #E1341E'
                    : errorType || groupCheckApiErrorType
                    ? '0.5px solid  #E1341E'
                    : '0.5px solid  #D9D9D9',
                  '&:focus': {
                    /* eslint-disable */
                    borderColor: errors.groupName
                      ? '#E1341E'
                      : errorType || groupCheckApiErrorType
                      ? '#E1341E'
                      : '#313133',
                  },
                },
              }}
              {...register('groupName', {
                required: t('common.validationError.required'),
                minLength: 1,
                validate: validateGroupName,
              })}
              autoFocus
              autoComplete="off"
            />
            {errors.groupName ||
            groupName?.length === undefined ||
            groupName === '' ||
            groupName.trim().length === 0 ||
            groupName.trim().length > 256 ||
            errorType === GROUP_ADD_API_RESULT_CODE.WARN_GROUP_NAME_EXIST ||
            groupCheckApiErrorType ? (
              <IconButton className={classes.groupCreateSaveIcon} disabled>
                <SaveIcon />
              </IconButton>
            ) : (
              <Tooltip
                PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -6.5],
                      },
                    },
                  ],
                }}
                componentsProps={{
                  tooltip: {
                    className: classes.iconTooltipStyle,
                  },
                }}
                title={t('common.tooltip.save') as string}
                arrow
              >
                <IconButton
                  className={classes.groupCreateSaveIcon}
                  type="submit"
                  disabled={isCallingApi}
                >
                  {!isCallingApi ? (
                    <SaveIcon
                      style={
                        groupName === undefined
                          ? { opacity: '0.5' }
                          : { opacity: '1' }
                      }
                    />
                  ) : (
                    <Loader colorCode="#939598" />
                  )}
                </IconButton>
              </Tooltip>
            )}
            {(errorType === GROUP_ADD_API_RESULT_CODE.WARN_GROUP_NAME_EXIST ||
              groupCheckApiErrorType ||
              errors.groupName) && (
              <Box className={classes.groupNameExistWarningCard}>
                <p style={{ padding: '0', margin: '0' }}>
                  {(errorType ===
                    GROUP_ADD_API_RESULT_CODE.WARN_GROUP_NAME_EXIST ||
                    (!errors.groupName && groupCheckApiErrorType)) &&
                    t('groupDetail.error.alreadyExist') + errorMessageCodes['groupDetail.error.alreadyExist.WARN_GROUP_NAME_EXIST']}
                  {errors.groupName && errors.groupName.message}
                </p>
              </Box>
            )}
          </form>
        </ClickAwayListener>

        <Box className={`${classes.groupAddBtnCard} group-add-btn-card`}>
          <SearchField
            clickFilterSearch={(val) => console.log(val)}
            placeholder={t('groupDetail.label.searchDevice')}
            disable={true}
            responsiveClass="search-input"
          />
          <Button
            id="manageGroupButton"
            variant="contained"
            disabled
            className={classes.manageGroupBtn}
          >
            <EditIcon className={classes.groupBtnIcon} />
            <Typography className={classes.groupBtnText}>
              {t('groupDetail.button.manageGroup')}
            </Typography>
          </Button>
        </Box>
        <div>
          <Box component="main" className={classes.groupAddDataBox}>
            <Typography
              sx={{
                fontWeight: 'bold',
                fontSize: '18px',
                lineHeight: '26px',
                '@media screen and (max-height: 640px)': {
                  marginBottom: '10px',
                },
              }}
            >
              {t('groupDetail.label.deviceList')} (0)
            </Typography>

            <Grid
              container
              style={{ height: '100%' }}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <GroupDevice
                className={`${classes.noGroupDeviceIcon} ${classes.noGroupDeviceIconSm}`}
              />
              <Typography
                className={`${classes.noGroupDevicePrimaryText} ${classes.noGroupDevicePrimaryTextSm}`}
              >
                {t('groupDetail.label.startOrganizing')}
              </Typography>
              <Typography
                className={`${classes.noGroupDeviceSecondaryText} ${classes.noGroupDeviceSecondaryTextSm}`}
              >
                {t('groupDetail.label.addMoreGroups')}
              </Typography>
              <Button
                id="manageGroupButton"
                variant="contained"
                disabled
                className={classes.manageGroupBtn}
                sx={{
                  marginTop: '14px',
                }}
              >
                <EditIcon className={classes.groupBtnIcon} />
                <Typography
                  className={`${classes.groupBtnText} ${classes.groupBtnTextSm}`}
                >
                  {t('groupDetail.button.manageGroup')}
                </Typography>
              </Button>
            </Grid>
          </Box>
        </div>
      </Container>
      <br />
    </GroupFeatureTemplate>
  );
};

export default GroupAdd;

import { useMemo, useRef, useState } from 'react';
import { featureSetupPost } from '../utils/remote/featureSetupPost';

import {
  REMOTE_FEATURE_SETUP_API_ERROR_TYPE,
  REMOTE_FEATURE_SETUP_API_RESULT_CODE,
} from '../apis/callRemoteFeatureSetupPostApi';
import { isRemoteFeatureSetupPostErrorType } from '../types/apis/apiErrorTypeGuard';
import {
  RemoteFeatureSetupApiRequestParam,
  RemoteFeatureSetupApiResponse,
} from '../types/apis/remoteFeatureSetupApi.d';
import useSnackbar from './useSnackbar';
import useRedirectDialog from './useRedirectDialog';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import errorMessageCodes from '../constants/errorMessageCodes';

const useRemoteFeatureSetupPost = () => {
  const { displayRedirectDialog } = useRedirectDialog();

  const [
    isCallingRemoteFeatureSetupPostApi,
    setIsCallingRemoteFeatureSetupPostApi,
  ] = useState<boolean>(false);
  const featureSetupResult = useRef<string | null>();
  const [featureSetupPostErrorType, setFeatureSetupPostErrorType] =
    useState<REMOTE_FEATURE_SETUP_API_ERROR_TYPE | null>(null);
  const { displaySnackbar } = useSnackbar();
  const { t } = useSwitchLocaleLanguage();

  const onClickRemoteFeatureSetupPost = (
    requestParams: RemoteFeatureSetupApiRequestParam,
  ): void => {
    setIsCallingRemoteFeatureSetupPostApi(true);
    featureSetupPost(requestParams)
      .then((result: RemoteFeatureSetupApiResponse) => {
        switch (result.resultCode) {
          case REMOTE_FEATURE_SETUP_API_RESULT_CODE.OK: {
            // Success Message
            /*eslint-disable*/
            featureSetupResult.current = result.resultCode;
            displaySnackbar({
              message: t('remoteDevice.message.setupSuccess'),
            });
            break;
          }
          default:
            throw result.resultCode;
        }
        // LOADINGBAR hidden (erase a memory leak when erased with Finally)
        setIsCallingRemoteFeatureSetupPostApi(false);
      })
      .catch((error: REMOTE_FEATURE_SETUP_API_ERROR_TYPE | unknown) => {
        if (isRemoteFeatureSetupPostErrorType(error)) {
          setFeatureSetupPostErrorType(error);
          if (
            error === REMOTE_FEATURE_SETUP_API_RESULT_CODE.WARN_INVALID_AUTH
          ) {
            displayRedirectDialog({
              open: true,
              title: t('common.error.unavailableGroup'),
              message:
                t('common.error.unavailableGroup') +
                errorMessageCodes[
                  'common.error.unavailableGroup.WARN_INVALID_AUTH'
                ],
            });
          }
          if (
            error ===
              REMOTE_FEATURE_SETUP_API_RESULT_CODE.GROUP_ACCESS_DENIED ||
            error === REMOTE_FEATURE_SETUP_API_RESULT_CODE.INFO_NO_ACCOUNT
          ) {
            displayRedirectDialog({
              open: true,
              title: t('common.error.notAuthorized'),
              message: t('common.error.unauthorizedAccess') + REMOTE_FEATURE_SETUP_API_RESULT_CODE.GROUP_ACCESS_DENIED ? errorMessageCodes['common.error.unauthorizedAccess.GROUP_ACCESS_DENIED'] : errorMessageCodes['common.error.unauthorizedAccess.INFO_NO_ACCOUNT'],
            });
          }
          if (error === REMOTE_FEATURE_SETUP_API_RESULT_CODE.INFO_NO_GROUP) {
            displayRedirectDialog({
              open: true,
              title: t('common.error.invalidGroup'),
              message: t('common.error.unavailableGroup') + errorMessageCodes['common.error.unavailableGroup'],
            });
          } else {
            displaySnackbar({
              message:
                t('remoteDevice.message.setupFailed') +
                errorMessageCodes[
                  'remoteDevice.message.setupFailed.ERR_UNKNOWN'
                ],
              type: 'error',
            });
          }
        } else {
          setFeatureSetupPostErrorType(
            REMOTE_FEATURE_SETUP_API_RESULT_CODE.ERR_UNKNOWN,
          );
        }
        // LOADINGBAR hidden (erase a memory leak when erased with Finally)
        setIsCallingRemoteFeatureSetupPostApi(false);
      });
  };

  const errorMessageRemoteFeatureSetupInfo = useMemo((): string => {
    if (!featureSetupPostErrorType) {
      return '';
    }
    switch (featureSetupPostErrorType) {
      case REMOTE_FEATURE_SETUP_API_RESULT_CODE.INFO_NOTHING_KEY:
        return t('remoteDevice.message.noAccess');
      case REMOTE_FEATURE_SETUP_API_RESULT_CODE.WARN_NO_AUTH_LIST:
        return t('remoteDevice.message.invalidURL');
      default:
        return t('common.error.serverErr');
    }
  }, [featureSetupPostErrorType]);

  return {
    errorMessageRemoteFeatureSetupInfo,
    isCallingRemoteFeatureSetupPostApi,
    featureSetupPostErrorType,
    featureSetupResult: featureSetupResult.current,
    onClickRemoteFeatureSetupPost,
  };
};

export default useRemoteFeatureSetupPost;

import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { t } from 'i18next';
import useRedirectDialog from '../../hooks/useRedirectDialog';
import useLogout from '../../hooks/useLogout';
import { SERVER_MESSAGE } from '../../constants/constants';
import PTloader from '../Atoms/ptloader/PTloader';
import errorMessageCodes from '../../constants/errorMessageCodes';

interface State {
  message: string;
  oktaResponse?: string;
}

const LoginErrorRoutePage: FC = () => {
  const { state } = useLocation() as { state: State | null };
  const { displayRedirectDialog } = useRedirectDialog();
  const { onClickLogoff } = useLogout();
  const navigate = useNavigate();

  useEffect(() => {
    let title = '';
    let message = '';
    
    if (!state) {
      navigate('/LOGIN_ERROR.')
    } else {
      switch (state.message) {
        case SERVER_MESSAGE.USER_DEACTIVATED:
          title = t('common.error.loggedOff');
          message = t('common.error.accountHasDeactivated') + errorMessageCodes['common.error.accountHasDeactivated.USER_DEACTIVATED'];
          break;
        case 'OKTA_ERROR':
          message = state.oktaResponse || 'Unknown Okta error';
          break;
        default:
          title = state.message;
          message = 'There was an error while logging in. Please try again later.';
          break;
      }

      displayRedirectDialog({
        open: true,
        title,
        message,
        routePath: onClickLogoff,
      });
    }

  
  }, [state, displayRedirectDialog, onClickLogoff, navigate]);

  return <PTloader />;
};

export default LoginErrorRoutePage;

import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import type { GroupNameEditInput } from '../types/inputs/groupNameEdit';
import callGroupNameEditApi, {
  GROUP_NAME_EDIT_API_ERROR_TYPE,
  GROUP_NAME_EDIT_API_RESULT_CODE,
} from '../apis/callGroupNameEdit';
import useSnackbar from './useSnackbar';
import {
  GroupNameEditApiResponse,
} from '../types/apis/groupNameEditApi.d';
import { isGroupNameEditErrorType } from '../types/apis/apiErrorTypeGuard';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import useRedirectDialog from './useRedirectDialog';
import { SERVER_MESSAGE } from '../constants/constants';
import errorMessageCodes from '../constants/errorMessageCodes';

export type UseGroupNameEditValue = {
  errorTypeEdit: GROUP_NAME_EDIT_API_ERROR_TYPE | null;
  setErrorTypeEdit: (
    value: React.SetStateAction<GROUP_NAME_EDIT_API_ERROR_TYPE | null>,
  ) => void;
  errorMessageGroupEditName: string;
  isCallingGroupNameEditApi: boolean;
  onClickGroupNameEdit: (inputData: string, groupId: number) => void;
  groupNameEditSuccess: boolean;
};

const useGroupNameEdit = (): UseGroupNameEditValue => {
  const { displayRedirectDialog } = useRedirectDialog();
  // const { onClickLogoff } = useLogout();
  const [isCallingGroupNameEditApi, setIsCallingGroupNameEditApi] =
    useState<boolean>(false);
  const [errorTypeEdit, setErrorTypeEdit] =
    useState<GROUP_NAME_EDIT_API_ERROR_TYPE | null>(null);
  const [groupNameEditSuccess, setGroupNameEditSuccess] = useState(false);
  // navigate
  const navigate = useNavigate();
  const { displaySnackbar } = useSnackbar();

  const { t } = useSwitchLocaleLanguage();

  const onClickGroupNameEdit = (groupName: string, groupId: number): void => {
    const requestParam: GroupNameEditInput = {
      groupName,
      groupId,
    };

    setGroupNameEditSuccess(false);
    // loading Start
    setIsCallingGroupNameEditApi(true);
    // Error type initialization
    // メール送信
    /*eslint-disable*/
    callGroupNameEditApi(requestParam)
      .then((result: GroupNameEditApiResponse) => {
        switch (result.message) {
          case GROUP_NAME_EDIT_API_RESULT_CODE.SUCCESSFULLY_UPDATED: {
            displaySnackbar({ message: t('groupAdd.message.editSuccess') });
            // Success Message
            // // Transition to the Group List screen
            setGroupNameEditSuccess(true);
            setErrorTypeEdit(result.message);
            break;
          }
          case GROUP_NAME_EDIT_API_RESULT_CODE.WARN_GROUP_NAME_EXIST: {
            setErrorTypeEdit(result.message);
            break;
          }
          case GROUP_NAME_EDIT_API_RESULT_CODE.SUBSCRIPTION_PLAN_CHANGED:
          case SERVER_MESSAGE.USER_ROLE_CHANGED:
          case SERVER_MESSAGE.INVALID_TOKEN:
          case SERVER_MESSAGE.EXPIRED_TOKEN: {
            break;
          }
          case GROUP_NAME_EDIT_API_RESULT_CODE.INFO_NOTHING_KEY: {
            navigate('/');
            break;
          }
          case GROUP_NAME_EDIT_API_RESULT_CODE.INFO_NO_CORPORATION:
          case GROUP_NAME_EDIT_API_RESULT_CODE.INFO_NO_GROUP:
          case GROUP_NAME_EDIT_API_RESULT_CODE.WARN_INVALID_AUTH: {
            // Hide user deletion dialog
            // Set an error code in setUserDeleteERrortype for logout dialog displaylogout dialog display
            throw result.message;
          }
          default:
            throw result.message;
        }
        setIsCallingGroupNameEditApi(false);
      })
      .catch((error: GROUP_NAME_EDIT_API_ERROR_TYPE | unknown) => {
        if (isGroupNameEditErrorType(error)) {
          setErrorTypeEdit(error);
          if (error === GROUP_NAME_EDIT_API_RESULT_CODE.INFO_NO_GROUP) {
            displayRedirectDialog({
              open: true,
              title: t('common.error.invalidGroup'),
              message: t('common.error.unavailableGroup') + errorMessageCodes['common.error.unavailableGroup'],
            });
          }
          if (error === GROUP_NAME_EDIT_API_RESULT_CODE.GROUP_ACCESS_DENIED) {
            displayRedirectDialog({
              open: true,
              title: t('common.error.notAuthorized'),
              message: t('common.error.unauthorizedAccess') + errorMessageCodes['common.error.unauthorizedAccess.GROUP_ACCESS_DENIED'],
            });
          }
          if (error === GROUP_NAME_EDIT_API_RESULT_CODE.WARN_INVALID_AUTH) {
          }
          if (
            error === GROUP_NAME_EDIT_API_RESULT_CODE.SUBSCRIPTION_PLAN_CHANGED
          ) {
            displaySnackbar({
              message: t('groupNameEdit.message.unableToEdit') + errorMessageCodes['groupNameEdit.message.unableToEdit'],
              type: 'error',
            });
          }
          if (error === GROUP_NAME_EDIT_API_RESULT_CODE.ERR_UNKNOWN) {
            displaySnackbar({
              message: t('groupNameEdit.message.unableToEdit') + errorMessageCodes['groupNameEdit.message.unableToEdit'],
              type: 'error',
            });
          }
        } else {
          displaySnackbar({
            message: t('groupNameEdit.message.unableToEdit') + errorMessageCodes['groupNameEdit.message.unableToEdit'],
            type: 'error',
          });
          setErrorTypeEdit(GROUP_NAME_EDIT_API_RESULT_CODE.ERR_UNKNOWN);
        }
        // LOADINGBAR hidden (erase a memory leak when erased with Finally)
        setIsCallingGroupNameEditApi(false);
      });
  };

  const errorMessageGroupEditName = useMemo(
    (): string => {
      if (!errorTypeEdit) {
        return ''; // Hide the error component itself
      }

      // Group deletion error
      switch (errorTypeEdit) {
        case GROUP_NAME_EDIT_API_RESULT_CODE.WARN_INVALID_AUTH:
          return t('common.error.invalidAuth') + errorMessageCodes['common.error.invalidAuth']; // In the event of a login authentication error or authority fraud error, the access key fraudulent error dialog is displayed, so no error message is displayed.
        case GROUP_NAME_EDIT_API_RESULT_CODE.INFO_NO_CORPORATION:
          return t('common.error.noCorporation')+ errorMessageCodes['common.error.noCorporation'];
        case GROUP_NAME_EDIT_API_RESULT_CODE.INFO_NO_GROUP:
          return t('common.error.unavailableGroup') + errorMessageCodes['common.error.unavailableGroup'];
        default:
          return t('groupNameEdit.message.unableToEdit') + errorMessageCodes['groupNameEdit.message.unableToEdit'];
      }
    },
    [errorTypeEdit], // Each time the error type changes, the error message is returned
  );

  return {
    errorTypeEdit,
    errorMessageGroupEditName,
    isCallingGroupNameEditApi,
    onClickGroupNameEdit,
    setErrorTypeEdit,
    groupNameEditSuccess,
  };
};

export default useGroupNameEdit;

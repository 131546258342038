/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/no-array-index-key */
import React, { useRef, useCallback, useState, useEffect } from 'react';
import clsx from 'clsx';
import SimpleBarReact from 'simplebar-react';
import { VariableSizeList as VariableListWindow } from 'react-window';
import { Box, Tooltip, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import pixelWidth from 'string-pixel-width';
import { useStyles } from './DialogStyle';
import { ReactComponent as FilterIcon } from '../../images/icon/Filter_alt_light.svg';
import { ReactComponent as CheckMarkAddUser } from '../../images/icon/check_mark_add_user.svg';
import { ReactComponent as CheckMarkCross } from '../../images/icon/check_mark_cross.svg';
import { ReactComponent as GroupUserIcon } from '../../images/icon/Group_user.svg';
import { ReactComponent as CheckBox } from '../../images/icon/checkbox.svg';
import { ReactComponent as CheckBoxDisable } from '../../images/icon/checkbox_disable.svg';
import { ReactComponent as CheckedCheckBox } from '../../images/icon/checked_checkbox.svg';
import ManageGroupSearch from '../Modecules/ManageGroupSearch';
import useManageGroupUserList from '../../hooks/useManageUserList';
import { MANAGE_GROUP_USER_LIST_API_RESULT_CODE } from '../../apis/callManageGroupUserList';
import { MANAGE_UNGROUP_USER_LIST_API_RESULT_CODE } from '../../apis/callManageUnGroupUserList';
import { MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE } from '../../apis/callManageGroupUserListUpdate';
import ManageUserSkeleton from './ManageUserSkeleton';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import useAccountInfo from '../../hooks/useAccountInfo';
import { UserInfo } from '../../types/apis/manageGroupUserListApi.d';
import { ReactComponent as ActiveIcon } from '../../images/icon/active-dot.svg';
import { FEATURE_PERMISSION, regexJapanese } from '../../constants/constants';
import { ReactComponent as ErrorSign } from '../../images/icon/error-sign-manage-groups.svg';
import { ReactComponent as CloseWhiteIcon } from '../../images/icon/cross-white.svg';
import { sendGAEvent } from '../../hooks/useGAEvent';
import { LOCALE_CODE } from '../../hooks/useLocale';

type Props = {
  onClose: () => void;
  isManageUserActive: boolean;
  setAddedUserListPayload: React.Dispatch<React.SetStateAction<number[]>>;
  setRemovedUserListPayload: React.Dispatch<React.SetStateAction<number[]>>;
  isFirstManageUserRender: boolean;
};
type TooltipProps = {
  title: string;
  children: React.ReactElement;
  dataWidth: number;
  divWidth: number;
};
const handleTooltipClick = (event: React.MouseEvent<HTMLDivElement>) => {
  event.stopPropagation();
};
const StyledTooltip = React.memo<TooltipProps>((props): JSX.Element => {
  const classes = useStyles();

  return (
    <Tooltip
      placement="top"
      title={props.title}
      disableHoverListener={props.dataWidth <= props.divWidth}
      arrow
      sx={{ marginTop: 0 }}
      PopperProps={{
        onClick: handleTooltipClick,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-7, -7],
            },
          },
        ],
      }}
      componentsProps={{
        tooltip: {
          className: classes.tooltipStyle,
        },
      }}
    >
      {props.children}
    </Tooltip>
  );
});

const ManageUserDialog = React.memo<Props>((props): JSX.Element => {
  const {
    onClose,
    isManageUserActive,
    setAddedUserListPayload,
    setRemovedUserListPayload,
    isFirstManageUserRender,
  } = props;

  const classes = useStyles();
  const { accountInfo } = useAccountInfo();

  const [pageNumberForGroup, setPageNumberForGroup] = useState(1);
  const [pageNumberForUnGroup, setPageNumberForUnGroup] = useState(1);
  const [searchTextForGroup, setSearchTextForGroup] = useState<string>('');
  const [searchTextForUnGroup, setSearchTextForUnGroup] = useState<string>('');
  const [firstTimeFilterRenderUngrouped, setFirstTimeFilterRenderUngrouped] =
    useState<boolean>(true);

  const [firstTimeFilterRenderGrouped, setFirstTimeFilterRenderGrouped] =
    useState<boolean>(true);

  const {
    errorType,
    errorTypeUnGroup,
    isCallingApi,
    isCallingUnGroupUserApi,
    originalUserList,
    originalUnGroupUserList,
    totalUnGroupUser,
    totalGroupUser,
  } = useManageGroupUserList(
    pageNumberForGroup,
    searchTextForGroup,
    pageNumberForUnGroup,
    searchTextForUnGroup,
    isFirstManageUserRender,
  );

  // server error check
  const [isError, setIsError] = useState(false);
  // const [forServerErrorMsg, setForServerErrorMsg] = useState(false);

  useEffect(() => {
    if (
      (errorType === MANAGE_GROUP_USER_LIST_API_RESULT_CODE.ERR_UNKNOWN &&
        !isCallingApi) ||
      (errorTypeUnGroup ===
        MANAGE_UNGROUP_USER_LIST_API_RESULT_CODE.ERR_UNKNOWN &&
        !isCallingUnGroupUserApi)
    ) {
      setIsError(true);
      // setForServerErrorMsg(true);
    } else {
      // setForServerErrorMsg(false);
    }
  }, [errorType, errorTypeUnGroup, isCallingApi, isCallingUnGroupUserApi]);

  const [isUngroupUserSearchOpen, setUngroupUserSearchOpen] = useState(false);
  const [isGroupUserSearchOpen, setIsGroupUserSearchOpen] = useState(false);
  const [checkedUnGroupAll, setCheckedUnGroupAll] = useState(false);
  const [checkedUnGroup, setCheckedUnGroup] = useState<UserInfo[]>([]);
  const [checkedGroupAll, setCheckedGroupAll] = useState(false);
  const [checkedGroup, setCheckedGroup] = useState<UserInfo[]>([]);
  // for user add btn or remove btn trigger skeleton
  const [userTransitionLoading, setUserTransitionLoading] = useState(false);

  // original and added User list
  const [originalAndAddedUserList, setOriginalAndAddedUserList] =
    useState<UserInfo[]>(originalUserList);
  // original and removed User list
  const [ungroupedAndRemovedUserList, setUngroupedAndRemovedUserList] =
    useState<UserInfo[]>(originalUnGroupUserList);
  // this state is for double line count for grouped users
  const [doubleLineCountGroupUser, setDoubleLineCountGroupUser] = useState(0);
  // this state is for double line count for ungrouped users
  const [doubleLineCountUnGroupUser, setDoubleLineCountUnGroupUser] =
    useState(0);

  const [originalUserListDataHeight, setOriginalUserListDataHeight] =
    useState(0);
  const [
    originalUnGroupUserListDataHeight,
    setOriginalUnGroupUserListDataHeight,
  ] = useState(0);

  // comparing for grouped user sidebar
  useEffect(() => {
    setOriginalUserListDataHeight(
      (originalAndAddedUserList.length - doubleLineCountGroupUser) * 86 +
        doubleLineCountGroupUser * 105,
    );
  }, [originalAndAddedUserList, doubleLineCountGroupUser]);

  // comparing for ungrouped user sidebar
  useEffect(() => {
    setOriginalUnGroupUserListDataHeight(
      (ungroupedAndRemovedUserList.length - doubleLineCountUnGroupUser) * 86 +
        doubleLineCountUnGroupUser * 105,
    );
  }, [ungroupedAndRemovedUserList, doubleLineCountUnGroupUser]);

  const modalListHeight =
    window.innerHeight > 800 ? 476 : window.innerHeight - 376;
  const { t } = useSwitchLocaleLanguage();

  // added user list from ungrouped
  const [addedUserList, setAddedUserList] = useState<UserInfo[]>([]);

  const [removedUserList, setRemovedUserList] = useState<UserInfo[]>([]);

  // defining custom props for windows
  const windowHeight =
    window.innerHeight > 800 ? 476 : window.innerHeight - 376;

  // count the total admin from group users
  const [totalAdmin, setTotalAdmin] = useState<number>(0);
  useEffect(() => {
    const adminCnt = originalAndAddedUserList.filter(
      (user) => user.roleName === t('common.adminRole'),
    );
    setTotalAdmin(adminCnt.length);
  }, [originalAndAddedUserList, t]);

  // Ungroup Check Toggle
  const handleUnGroupToggle = (value: number) => () => {
    if (
      !accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPUSERMODAL?.includes(
        FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPUSERMODAL.ADD,
      ) &&
      removedUserList.findIndex((newUser) => newUser.id === value) === -1
    ) {
      return;
    }
    // checking if the userID is already checked
    const currentIndex = checkedUnGroup.find((user) => user.id === value);
    // finding user object by its id number
    const userObject = ungroupedAndRemovedUserList.find(
      (user) => user.id === value,
    );

    // if the user is not checked and user object is valid
    if (!currentIndex && userObject) {
      setCheckedUnGroup([userObject, ...checkedUnGroup]);
      const addedUserCnt = addedUserList.filter((user) =>
        ungroupedAndRemovedUserList.some((newUser) => newUser.id === user.id),
      );
      // handle select all (toggling checked true if all the users are selected manually)
      if (
        ungroupedAndRemovedUserList.length + 1 !== 0 &&
        checkedUnGroup.length + 1 ===
          ungroupedAndRemovedUserList.length - addedUserCnt.length
      ) {
        setCheckedUnGroupAll(true);
      }

      // GA tag
      sendGAEvent('select_check', 'btn_name', 'user');
      sendGAEvent(
        'select_check',
        'user_value',
        (checkedUnGroup.length + 1).toString(),
      );
    } else if (currentIndex) {
      setCheckedUnGroupAll(false);
      // removing the obj id from checked array
      const tempAddedUserList = checkedUnGroup.filter(
        (obj) => obj.id !== value,
      );
      setCheckedUnGroup(tempAddedUserList);
    }
  };

  const handleUnGroupSelectAllToggle = () => {
    setCheckedUnGroupAll(!checkedUnGroupAll);
    // if selectAll is in true state
    if (checkedUnGroupAll) {
      // unchecking only the users that were in search result. Previously added users are still remaining in the array
      const currentUngroupedUsers = checkedUnGroup.filter(
        (user) =>
          !ungroupedAndRemovedUserList.some(
            (newUser) => newUser.id === user.id,
          ),
      );
      setCheckedUnGroup(currentUngroupedUsers);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (
        accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPUSERMODAL?.includes(
          FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPUSERMODAL.DELETE,
        ) &&
        !accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPUSERMODAL?.includes(
          FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPUSERMODAL.ADD,
        )
      ) {
        setCheckedUnGroup([...removedUserList]);
      } else {
        const currentUngroupedUsers = ungroupedAndRemovedUserList.filter(
          (user) => !addedUserList.some((newUser) => newUser.id === user.id),
        );
        const filteredFromPreviousChecked = checkedUnGroup.filter(
          (user) =>
            !currentUngroupedUsers.some((newUser) => newUser.id === user.id),
        );
        setCheckedUnGroup([
          ...currentUngroupedUsers,
          ...filteredFromPreviousChecked,
        ]);
        // GA tag
        sendGAEvent('select_check', 'btn_name', 'all_user');
        sendGAEvent(
          'select_check',
          'all_user_count_value',
          (
            currentUngroupedUsers.length + filteredFromPreviousChecked.length
          ).toString(),
        );
      }
    }
  };

  // group Check Toggle
  const handleGroupToggle = (value: number) => () => {
    const isAdmin = originalUserList.find((user) => user.id === value);
    if (
      isAdmin?.roleName === t('common.adminRole') ||
      (!accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPUSERMODAL?.includes(
        FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPUSERMODAL.DELETE,
      ) &&
        addedUserList.findIndex((newUser) => newUser.id === value) === -1)
    ) {
      return;
    }
    const currentIndex = checkedGroup.find((user) => user.id === value);
    // checking if the state is initial state or it has already some value
    const userObject = originalAndAddedUserList.find(
      (user) => user.id === value,
    );
    if (!currentIndex && userObject) {
      setCheckedGroup([userObject, ...checkedGroup]);
      const removedUserCnt = removedUserList.filter((user) =>
        originalAndAddedUserList.some((newUser) => newUser.id === user.id),
      );
      // select all button handler
      if (
        originalAndAddedUserList.length + 1 !== 0 &&
        checkedGroup.length + 1 ===
          originalAndAddedUserList.length - totalAdmin - removedUserCnt.length
      ) {
        setCheckedGroupAll(true);
      }

      // GA tag
      sendGAEvent('select_check', 'btn_name', 'selected_user');
      sendGAEvent(
        'select_check',
        'selected_user_value',
        (checkedGroup.length + 1).toString(),
      );
    } else if (currentIndex) {
      setCheckedGroupAll(false);

      const tempAddedUserList = checkedGroup.filter((obj) => obj.id !== value);
      setCheckedGroup(tempAddedUserList);
    }
  };

  const handleGroupSelectAllToggle = () => {
    setCheckedGroupAll(!checkedGroupAll);
    if (checkedGroupAll) {
      const currentGroupedUsers = checkedGroup.filter(
        (user) =>
          !(
            originalAndAddedUserList.some(
              (newUser) => newUser.id === user.id,
            ) || user.roleName === t('common.adminRole')
          ),
      );
      setCheckedGroup(currentGroupedUsers);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (
        accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPUSERMODAL?.includes(
          FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPUSERMODAL.ADD,
        ) &&
        !accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPUSERMODAL?.includes(
          FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPUSERMODAL.DELETE,
        )
      ) {
        setCheckedGroup([...addedUserList]);
      } else {
        const currentGroupedUsers = originalAndAddedUserList.filter(
          (user) =>
            !(
              removedUserList.some((newUser) => newUser.id === user.id) ||
              user.roleName === t('common.adminRole')
            ),
        );
        const filteredFromPreviousSelected = checkedGroup.filter(
          (user) =>
            !(
              currentGroupedUsers.some((newUser) => newUser.id === user.id) ||
              user.roleName === t('common.adminRole')
            ),
        );
        setCheckedGroup([
          ...currentGroupedUsers,
          ...filteredFromPreviousSelected,
        ]);
        // GA tag
        sendGAEvent('select_check', 'btn_name', 'all_selected_user');
        sendGAEvent(
          'select_check',
          'selected_all_user_count_value',
          currentGroupedUsers.length.toString(),
        );
      }
    }
  };

  const handleSubmit = (e: string) => {
    setSearchTextForGroup(e.trim());
    setPageNumberForGroup(1);
  };
  const handleSubmitUngroup = (e: string) => {
    setSearchTextForUnGroup(e.trim());
    setPageNumberForUnGroup(1);
  };

  // handle add user to group
  const handleAddUngrouped = () => {
    // setting skeleton
    setUserTransitionLoading(true);
    const newUserList = ungroupedAndRemovedUserList.filter((user) =>
      checkedUnGroup.some((newUser) => newUser.id === user.id),
    );
    // new add users
    const newAddUserList = checkedUnGroup.filter(
      (user) => !removedUserList.some((newUser) => newUser.id === user.id),
    );
    setAddedUserList([...newAddUserList, ...addedUserList]);
    // delete from previously removed user
    const previouslyRemovedUsers = removedUserList.filter((user) =>
      newUserList.some((newUser) => newUser.id === user.id),
    );

    const modifiedRemovedUsers = removedUserList.filter(
      (user) =>
        !previouslyRemovedUsers.some((newUser) => newUser.id === user.id),
    );
    setRemovedUserList(modifiedRemovedUsers);

    // update original and removed ungrouped user list
    // const mergedArray = [...newAddUserList, ...originalUnGroupUserList];
    const mergedArray = [...newAddUserList, ...originalUserList];
    setOriginalAndAddedUserList(mergedArray);
    setCheckedUnGroup([]);
    setCheckedUnGroupAll(false);
    setCheckedGroupAll(false);

    // GA tag
    sendGAEvent('press_btn', 'btn_name', 'add_user');

    // resetting timer for loading
    const timer = setTimeout(() => {
      setUserTransitionLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  };

  // checking for search user
  const selectAllToggleForSearchGroupUsers = useCallback(() => {
    const chkGroupUserCnt = originalAndAddedUserList.filter((user) =>
      checkedGroup.some((newUser) => newUser.id === user.id),
    );

    if (
      chkGroupUserCnt.length !== 0 &&
      chkGroupUserCnt.length === originalAndAddedUserList.length - totalAdmin
    ) {
      setCheckedGroupAll(true);
    } else if (
      addedUserList.length !== 0 &&
      chkGroupUserCnt.length === addedUserList.length &&
      accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPUSERMODAL?.includes(
        FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPUSERMODAL.ADD,
      ) &&
      !accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPUSERMODAL?.includes(
        FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPUSERMODAL.DELETE,
      )
    ) {
      setCheckedGroupAll(true);
    } else {
      setCheckedGroupAll(false);
    }
  }, [
    originalAndAddedUserList,
    checkedGroup,
    totalAdmin,
    accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPUSERMODAL,
    addedUserList,
  ]);

  // merge two arrays when calling new api for searching
  useEffect(() => {
    selectAllToggleForSearchGroupUsers();
  }, [originalUserList, selectAllToggleForSearchGroupUsers]);

  // handle remove user from group
  const handleRemove = () => {
    // setting for skeleton
    setUserTransitionLoading(true);
    const newUserList = originalAndAddedUserList.filter((user) =>
      checkedGroup.some((newUser) => newUser.id === user.id),
    );

    // new remove users
    const newRemoveUserList = checkedGroup.filter(
      (user) => !addedUserList.some((newUser) => newUser.id === user.id),
    );
    setRemovedUserList([...newRemoveUserList, ...removedUserList]);

    // removed from previously added user
    const previouslyAddedUsers = addedUserList.filter((user) =>
      newUserList.some((newUser) => newUser.id === user.id),
    );

    const modifiedAddedUsers = addedUserList.filter(
      (user) => !previouslyAddedUsers.some((newUser) => newUser.id === user.id),
    );
    setAddedUserList(modifiedAddedUsers);

    // update original and added user list
    const mergedArray = [...newRemoveUserList, ...originalUnGroupUserList];
    setUngroupedAndRemovedUserList(mergedArray);
    // const mergedArray2 = [...newRemoveUserList, ...originalUnGroupUserList];

    setCheckedGroup([]);
    setCheckedGroupAll(false);
    setCheckedUnGroupAll(false);

    // GA tag
    sendGAEvent('press_btn', 'btn_name', 'release_selected_user');
    // resetting timer for loading
    const timer = setTimeout(() => {
      setUserTransitionLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  };

  // checking for search ungrouped user
  const selectAllToggleForSearchUngroup = useCallback(() => {
    const chkUngroupUserCnt = ungroupedAndRemovedUserList.filter((user) =>
      checkedUnGroup.some((newUser) => newUser.id === user.id),
    );

    if (
      ungroupedAndRemovedUserList.length !== 0 &&
      chkUngroupUserCnt.length === ungroupedAndRemovedUserList.length
    ) {
      setCheckedUnGroupAll(true);
    } else if (
      removedUserList.length !== 0 &&
      chkUngroupUserCnt.length === removedUserList.length &&
      accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPUSERMODAL?.includes(
        FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPUSERMODAL.DELETE,
      ) &&
      !accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPUSERMODAL?.includes(
        FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPUSERMODAL.ADD,
      )
    ) {
      setCheckedUnGroupAll(true);
    } else {
      setCheckedUnGroupAll(false);
    }
  }, [
    ungroupedAndRemovedUserList,
    checkedUnGroup,
    accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPUSERMODAL,
    removedUserList,
  ]);

  // merge two arrays when calling new api for searching
  useEffect(() => {
    selectAllToggleForSearchUngroup();
  }, [originalUnGroupUserList, selectAllToggleForSearchUngroup]);

  const searchUserBySearchKeyword = (array: UserInfo[], searchText: string) => {
    const lowerCaseSearchText = searchText?.toLowerCase();

    return array.filter(
      (data: UserInfo) =>
        data.roleName.toLowerCase().indexOf(lowerCaseSearchText) !== -1 ||
        data.userName.toLowerCase().indexOf(lowerCaseSearchText) !== -1 ||
        data.mailAddress.toLowerCase().indexOf(lowerCaseSearchText) !== -1,
    );
  };
  const [loadingForUnGroupSearch, setLoadingForUnGroupSearch] =
    useState<boolean>(false);

  useEffect(() => {
    // setting for skeleton
    setLoadingForUnGroupSearch(true);

    const ungroupUserListWithOutAddedUsers = originalUnGroupUserList.filter(
      (user) => !addedUserList.some((newUser) => newUser.id === user.id),
    );
    const searchResFromUngroupUserList = searchUserBySearchKeyword(
      ungroupUserListWithOutAddedUsers,
      searchTextForUnGroup,
    );
    const searchResFromRemovedUserList = searchUserBySearchKeyword(
      removedUserList,
      searchTextForUnGroup,
    );
    const mergedArray = [
      ...searchResFromRemovedUserList,
      ...searchResFromUngroupUserList,
    ];
    setUngroupedAndRemovedUserList(mergedArray);
    // resetting timer for loading
    const timer = setTimeout(() => {
      setLoadingForUnGroupSearch(false);
      // setUserTransitionLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [
    searchTextForUnGroup,
    originalUnGroupUserList,
    addedUserList,
    removedUserList,
  ]);

  // this counter is for no user rendering condition
  const [dataProcessingCounter, setDataProcessingCounter] = useState<number>(0);
  const [loadingForGroupSearch, setLoadingForGroupSearch] =
    useState<boolean>(false);

  useEffect(() => {
    // setting for skeleton
    setLoadingForGroupSearch(true);
    const userListWithOutRemovedUsers = originalUserList.filter(
      (user) => !removedUserList.some((newUser) => newUser.id === user.id),
    );

    const searchResFromUserList = searchUserBySearchKeyword(
      userListWithOutRemovedUsers,
      searchTextForGroup,
    );

    const searchResFromAddedUserList = searchUserBySearchKeyword(
      addedUserList,
      searchTextForGroup,
    );
    const mergedArray = [
      ...searchResFromAddedUserList,
      ...searchResFromUserList,
    ];
    setOriginalAndAddedUserList(mergedArray);
    setDataProcessingCounter((data) => data + 1);
    // resetting timer for loading
    const timer = setTimeout(() => {
      setLoadingForGroupSearch(false);
      // setUserTransitionLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [searchTextForGroup, originalUserList, removedUserList, addedUserList]);

  // for no user message Ungrouped
  const [noUserMessageUngrouped, setNoUserMessageUngrouped] = useState<string>(
    t('userListModal.label.noUser'),
  );
  useEffect(() => {
    setNoUserMessageUngrouped(
      searchTextForUnGroup
        ? t('userListModal.label.noUser')
        : t('userListModal.label.noUser'),
    );
  }, [isUngroupUserSearchOpen, searchTextForUnGroup, t]);

  // for no user message grouped
  const [noUserMessageGrouped, setNoUserMessageGrouped] = useState<string>(
    t('userListModal.label.noUser'),
  );
  useEffect(() => {
    setNoUserMessageGrouped(
      searchTextForGroup
        ? t('userListModal.label.noUser')
        : t('userListModal.label.noUser'),
    );
  }, [isGroupUserSearchOpen, searchTextForGroup, t]);

  const listRef = useRef(null);
  const listRef2 = useRef(null);

  // for ungrouped
  const [rowSize, setRowSize] = useState<string[]>([]);

  useEffect(() => {
    if (ungroupedAndRemovedUserList.length > 0) {
      const nameSize = ungroupedAndRemovedUserList.map((user) => user.userName);

      // this array is for checking double line device name array
      const nameWidth = nameSize.filter((user) => {
        const widthCheck = pixelWidth(user, { size: 14 });
        const isJapaneseText = regexJapanese.exec(user);
        const setWidth = isJapaneseText ? 102.5 : 199.95;
        if (user && widthCheck > setWidth) {
          return true;
        }

        return false;
      });

      setDoubleLineCountUnGroupUser(nameWidth.length);

      setRowSize(nameSize);
    }
  }, [ungroupedAndRemovedUserList]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
  const getItemSize = (index: number) => {
    const isJapaneseText = regexJapanese.exec(rowSize[index]);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const width = pixelWidth(rowSize[index], { size: 14 });

    const setWidth = isJapaneseText ? 102.5 : 199.95;

    if (rowSize[index] && width > setWidth) {
      return 104;
    }

    return 85;
  };

  // for grouped
  const [rowSize2, setRowSize2] = useState<string[]>([]);

  useEffect(() => {
    if (originalAndAddedUserList.length > 0) {
      const nameSize2 = originalAndAddedUserList.map((user) => user.userName);

      // this array is for checking double line device name array
      const nameWidth = nameSize2.filter((user) => {
        const widthCheck = pixelWidth(user, { size: 14 });
        const isJapaneseText = regexJapanese.exec(user);
        const setWidth = isJapaneseText ? 102.5 : 199.95;
        if (user && widthCheck > setWidth) {
          return true;
        }

        return false;
      });

      setDoubleLineCountGroupUser(nameWidth.length);

      setRowSize2(nameSize2);
    }
  }, [originalAndAddedUserList]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
  const getItemSize2 = (index: number) => {
    const isJapaneseText = regexJapanese.exec(rowSize2[index]);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const width2 = pixelWidth(rowSize2[index], { size: 14 });

    const setWidth = isJapaneseText ? 102.5 : 199.95;
    if (rowSize2[index] && width2 > setWidth) {
      return 104;
    }

    return 85;
  };

  // set the payload
  useEffect(() => {
    // converting the array of objects into array of userID for api payload request
    const addedUserListPayload = addedUserList.map((user) => user.id);
    const removedUserListPayload = removedUserList.map((user) => user.id);

    setAddedUserListPayload(addedUserListPayload);
    setRemovedUserListPayload(removedUserListPayload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedUserList, removedUserList]);
  // end set the payload

  return (
    <Box sx={{ display: isManageUserActive ? '' : 'none' }}>
      <Box sx={{ position: 'relative' }}>
        {(errorType === MANAGE_GROUP_USER_LIST_API_RESULT_CODE.INFO_NO_GROUP ||
          errorType ===
            MANAGE_GROUP_USER_LIST_API_RESULT_CODE.GROUP_ACCESS_DENIED ||
          errorTypeUnGroup ===
            MANAGE_GROUP_USER_LIST_UPDATE_API_RESULT_CODE.INFO_NO_GROUP ||
          errorType ===
            MANAGE_GROUP_USER_LIST_API_RESULT_CODE.WARN_INVALID_AUTH) &&
          onClose()}
        {isError && (
          <Box className={classes.manageGroupErrorCard}>
            <Box className={classes.manageGroupErrorCardBox}>
              <ErrorSign />
              <Typography className={classes.manageGroupErrorCardBoxText}>
                {t('common.error.serverErr')}
              </Typography>
            </Box>

            <IconButton
              sx={{ m: '0', height: '28px' }}
              onClick={() => setIsError(false)}
            >
              <CloseWhiteIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      <Box
        className={`${classes.manageGroupDividerBox} manage-group-device-all-select-device-list`}
      >
        {/* Start Ungroup Card */}
        <Box className="manage-group-device-left" sx={{ width: '50%' }}>
          {!isUngroupUserSearchOpen && (
            <Box
              className={`${classes.manageGroupCountBlock} manage-group-device-count-block`}
            >
              <Typography className={classes.manageGroupCountBlockText}>
                {t('userListModal.label.availableUsers')} (
                {Math.max(
                  0,
                  totalUnGroupUser -
                    addedUserList.length +
                    removedUserList.length,
                )}
                )
              </Typography>
              <IconButton
                onClick={() => {
                  setUngroupUserSearchOpen(true);
                }}
                disableRipple
                className={classes.manageGroupCountBlockBtn}
                aria-label="search"
              >
                <FilterIcon />
              </IconButton>
            </Box>
          )}
          {isUngroupUserSearchOpen && (
            <ManageGroupSearch
              placeholder={t('userListModal.placeholders.findUser')}
              clickIcon={(val) => {
                setUngroupUserSearchOpen(val);
                setFirstTimeFilterRenderUngrouped(true);
                if (searchTextForUnGroup) {
                  setPageNumberForUnGroup(1);
                }
                setSearchTextForUnGroup('');
              }}
              clickFilterSearch={(val) => {
                // setSearchTextForUnGroup(val);
                if (!firstTimeFilterRenderUngrouped) {
                  handleSubmitUngroup(val);
                }
                setFirstTimeFilterRenderUngrouped(false);
              }}
            />
          )}
          <Box
            sx={{
              border:
                ungroupedAndRemovedUserList.length === 0
                  ? '1px solid #93959880 !important'
                  : '1px solid #C5C5C5 !important',
              '@media screen and (min-width:900px)': {
                padding:
                  accountInfo.locale === LOCALE_CODE.FR ||
                  accountInfo.locale === LOCALE_CODE.DE || accountInfo.locale === LOCALE_CODE.ES
                    ? '6px 16px !important'
                    : '12px 16px',
              },
            }}
            className={`${classes.allSelectCard} manage-group-device-all-select-card `}
          >
            {/* Start UnGroup Select all card */}
            <ListItem
              secondaryAction={
                <IconButton
                  aria-label="Add user"
                  disabled={
                    checkedUnGroup.length <= 0 // || checkedUnGroup[0] === -1
                  }
                  className={classes.allSelectCardAddBtn}
                  disableRipple
                  onClick={handleAddUngrouped}
                >
                  <CheckMarkAddUser />
                  <Typography className={classes.allSelectCardBtnText}>
                    {t('userListModal.label.addUser')}
                  </Typography>
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton
                disabled={
                  accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPUSERMODAL.includes(
                    FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPUSERMODAL.ADD,
                  )
                    ? ungroupedAndRemovedUserList.length === 0
                    : removedUserList.length === 0 ||
                      ungroupedAndRemovedUserList.length === 0
                }
                className={classes.allSelectCardBtn}
                sx={{
                  marginRight:
                    accountInfo.locale === LOCALE_CODE.JA
                      ? '134px'
                      : accountInfo.locale === LOCALE_CODE.FR
                      ? '190px'
                      : accountInfo.locale === LOCALE_CODE.DE
                      ? '216px'
                      : accountInfo.locale === LOCALE_CODE.ES
                      ? '190px'
                      : '204px',
                }}
                onClick={handleUnGroupSelectAllToggle}
                disableRipple
              >
                <Checkbox
                  checked={checkedUnGroupAll}
                  icon={<CheckBox className={classes.checkBoxIconStyle} />}
                  checkedIcon={<CheckedCheckBox />}
                  sx={{
                    p: '0px',
                    mr: '8px',
                    '& svg': {
                      '& g': {
                        stroke: !checkedUnGroupAll
                          ? ungroupedAndRemovedUserList.length === 0
                            ? '#C5C5C580'
                            : '#C5C5C5'
                          : '#009de1',
                      },
                    },
                  }}
                />
                <ListItemText
                  primary={
                    <Typography className={classes.allSelectCardText}>
                      {t('userListModal.label.selectUser')}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          </Box>
          {/* end UnGroup Select all card */}
          {/* Start UnGroup User 0 card */}

          {/* //  End UnGroup User 0 card */}
          {/* // Ungroup User List */}
          <List sx={{ p: '0px', mt: '6px' }}>
            {/* showing ungrouped UserDATA */}
            {!(
              isCallingUnGroupUserApi ||
              userTransitionLoading ||
              loadingForUnGroupSearch
            ) &&
              ungroupedAndRemovedUserList.length !== 0 && (
                <Box style={{ position: 'relative' }}>
                  <SimpleBarReact
                    style={{ height: modalListHeight }}
                    autoHide={false}
                    className="manage-group-device-item-box"
                  >
                    {({ scrollableNodeRef, contentNodeRef }) => (
                      <VariableListWindow
                        className={`${classes.listWindowContainer} manage-group-device-list-container`}
                        itemCount={ungroupedAndRemovedUserList.length}
                        itemSize={getItemSize}
                        height={modalListHeight}
                        width={350}
                        outerRef={scrollableNodeRef}
                        innerRef={contentNodeRef}
                        ref={listRef}
                      >
                        {({ index, style }) => {
                          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                          const user = ungroupedAndRemovedUserList[index];
                          /*eslint-disable*/
                          const nameWord = user.userName
                            .trim()
                            .charAt(0)
                            .toUpperCase();

                          const foundAdded = addedUserList.findIndex(
                            (obj) => obj.id === user.id,
                          );
                          if (foundAdded !== -1) {
                            return null;
                          }
                          const userNameWidth = pixelWidth(user.userName, {
                            font: 'open sans',
                            size: 14,
                          });

                          const isJapanese = regexJapanese.exec(user.userName);

                          const userEmailWidth = pixelWidth(user.mailAddress, {
                            size: 13,
                          });

                          return (
                            <Box
                              key={index}
                              style={style}
                              sx={{
                                minHeight: '84px',
                                '&:not(:last-child)': {
                                  marginBottom: '8px',
                                },
                              }}
                            >
                              <ListItemButton
                                sx={{
                                  minHeight: '76px',
                                }}
                                className={`${
                                  checkedUnGroup.findIndex(
                                    (obj) => obj.id === user.id,
                                  ) !== -1
                                    ? clsx(
                                        classes.listItemButton,
                                        classes.listItemButtonActive,
                                      )
                                    : classes.listItemButton
                                } manage-group-device-item-list`}
                                onClick={handleUnGroupToggle(user.id)}
                                disableRipple
                                disabled={
                                  !accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPUSERMODAL?.includes(
                                    FEATURE_PERMISSION.GROUPMANAGEMENT
                                      .GROUPUSERMODAL.ADD,
                                  ) &&
                                  removedUserList.findIndex(
                                    (newUser) => newUser.id === user.id,
                                  ) === -1
                                }
                              >
                                <ListItem
                                  sx={{
                                    p: '3px 16px',
                                  }}
                                >
                                  <Checkbox
                                    checked={
                                      checkedUnGroup.findIndex(
                                        (obj) => obj.id === user.id,
                                      ) !== -1
                                    }
                                    tabIndex={-1}
                                    icon={
                                      !accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPUSERMODAL?.includes(
                                        FEATURE_PERMISSION.GROUPMANAGEMENT
                                          .GROUPUSERMODAL.ADD,
                                      ) &&
                                      removedUserList.findIndex(
                                        (newUser) => newUser.id === user.id,
                                      ) === -1 ? (
                                        <CheckBoxDisable
                                          className={classes.checkBoxIconStyle}
                                        />
                                      ) : (
                                        <CheckBox
                                          className={classes.checkBoxIconStyle}
                                        />
                                      )
                                    }
                                    checkedIcon={<CheckedCheckBox />}
                                    className={classes.checkBoxStyle}
                                  />
                                  <ListItemAvatar
                                    sx={{
                                      minWidth: '50px',
                                    }}
                                  >
                                    {ungroupedAndRemovedUserList[index]
                                      .imageUrl ? (
                                      <img
                                        alt="pic"
                                        src={
                                          ungroupedAndRemovedUserList[index]
                                            .imageUrl
                                        }
                                        className={classes.listImageStyle}
                                      />
                                    ) : (
                                      <Avatar
                                        className={classes.ListAvatar}
                                        sx={{
                                          bgcolor: user.avatarColor,
                                        }}
                                      >
                                        {nameWord}
                                      </Avatar>
                                    )}
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={
                                      <Box className={classes.listItemTextBox}>
                                        <StyledTooltip
                                          title={user.userName}
                                          dataWidth={userNameWidth}
                                          divWidth={
                                            isJapanese ? 205 : 199.95 * 2
                                          }
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: '14px',
                                              lineHeight: '19px',
                                              color: '#313133',
                                              width: '86.19%',
                                            }}
                                            className={
                                              classes.textBreakTwoLines
                                            }
                                          >
                                            {user.userName}
                                          </Typography>
                                        </StyledTooltip>
                                        <div
                                          style={{
                                            marginRight: '-6px',
                                            height: '19px',
                                            display:
                                              removedUserList.findIndex(
                                                (newUser) =>
                                                  newUser.id === user.id,
                                              ) === -1
                                                ? 'none'
                                                : 'flex',
                                          }}
                                        >
                                          <ActiveIcon />
                                        </div>
                                      </Box>
                                    }
                                    secondary={
                                      <Box
                                        sx={{
                                          dixplay: 'flex',
                                          flexDirection: 'coloum',
                                        }}
                                      >
                                        <StyledTooltip
                                          title={user.mailAddress}
                                          dataWidth={userEmailWidth}
                                          divWidth={232}
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: '13px',
                                              lineHeight: '19px',
                                              color: '#939598',
                                              fontWeight: 'light',
                                            }}
                                            className={`${
                                              userEmailWidth > 232
                                                ? classes.textEllipse
                                                : classes.textOverflow
                                            } manage-group-device-item-text`}
                                          >
                                            {user.mailAddress}
                                          </Typography>
                                        </StyledTooltip>
                                        <Typography
                                          sx={{
                                            fontSize: '13px',
                                            lineHeight: '19px',
                                            color: '#939598',
                                            fontWeight: 'light',
                                          }}
                                        >
                                          {user.roleName}
                                        </Typography>
                                      </Box>
                                    }
                                  />
                                </ListItem>
                              </ListItemButton>
                            </Box>
                          );
                        }}
                      </VariableListWindow>
                    )}
                  </SimpleBarReact>
                  {!isCallingUnGroupUserApi &&
                    originalUnGroupUserListDataHeight < windowHeight &&
                    !(
                      ungroupedAndRemovedUserList.length === 0 &&
                      dataProcessingCounter >= 2
                    ) && (
                      <Divider
                        orientation="vertical"
                        className={`${classes.divider} manage-group-device-no-divider`}
                        style={{ position: 'absolute', top: 0, right: 0 }}
                      />
                    )}
                </Box>
              )}
            {/* no users */}
            {!(
              isCallingUnGroupUserApi ||
              userTransitionLoading ||
              loadingForUnGroupSearch
            ) &&
              ungroupedAndRemovedUserList.length === 0 && (
                <Box
                  className="manage-group-device-no-device"
                  display="flex"
                  style={{ overflow: 'hidden' }}
                >
                  <Box
                    className={`${classes.NoDataBoxWithDivider} manage-group-device-data-not-found-block`}
                  >
                    <Box
                      className={`${classes.NoDataBox} manage-group-device-data-not-found-icon`}
                    >
                      <GroupUserIcon />
                      <Typography className={classes.noDataMessage}>
                        {noUserMessageUngrouped}
                      </Typography>
                    </Box>
                    <Divider
                      orientation="vertical"
                      flexItem
                      className={`${classes.divider} manage-group-device-no-divider`}
                    />
                  </Box>
                </Box>
              )}
            {/* skeleton loading */}
            {(isCallingUnGroupUserApi ||
              userTransitionLoading ||
              loadingForUnGroupSearch) && (
              <Box
                className={`${classes.NoDataBoxWithDivider} manage-group-device-data-not-found-block`}
              >
                <Box
                  className={`${classes.NoDataSkeleletonBox} manage-group-device-data-not-found-icon`}
                >
                  <ManageUserSkeleton />
                </Box>
              </Box>
            )}
          </List>
        </Box>

        {/* Start group Card */}
        <Box
          className="manage-group-device-right"
          sx={{ width: '50%', mr: '-4px' }}
        >
          {/* group Card Search */}
          {!isGroupUserSearchOpen && (
            <Box
              className={`${classes.manageGroupCountBlock} manage-group-device-count-block`}
            >
              <Typography className={classes.manageGroupCountBlockText}>
                {t('userListModal.label.groupUsers')} (
                {Math.max(
                  0,
                  totalGroupUser -
                    removedUserList.length +
                    addedUserList.length,
                )}
                )
              </Typography>
              <IconButton
                onClick={() => {
                  // setSearchTextForGroup('*');
                  setIsGroupUserSearchOpen(true);

                  // GA tag
                  sendGAEvent('press_btn', 'btn_name', 'filter_selected_user');
                }}
                disableRipple
                className={classes.manageGroupCountBlockBtn}
                aria-label="search"
              >
                <FilterIcon />
              </IconButton>
            </Box>
          )}
          {isGroupUserSearchOpen && (
            <ManageGroupSearch
              placeholder={t('userListModal.placeholders.findUser')}
              clickIcon={(val) => {
                setIsGroupUserSearchOpen(val);
                setFirstTimeFilterRenderGrouped(true);
                if (searchTextForGroup) {
                  setPageNumberForGroup(1);
                }
                setSearchTextForGroup('');
              }}
              clickFilterSearch={(val) => {
                // console.log(val);
                // setSearchTextForGroup(val);
                if (!firstTimeFilterRenderGrouped) {
                  handleSubmit(val);
                }
                setFirstTimeFilterRenderGrouped(false);

                // GA Tag
                sendGAEvent(
                  'filter_selected_user_txt',
                  'filter_txt',
                  'input_selected_user',
                );
                sendGAEvent(
                  'filter_selected_user_txt',
                  'input_selected_user_value',
                  val,
                );
              }}
            />
          )}
          {/* group all select card */}
          <Box
            sx={{
              border:
                originalAndAddedUserList.length - totalAdmin === 0
                  ? '1px solid #93959880 !important'
                  : '1px solid #C5C5C5 !important',
              '@media screen and (min-width:900px)': {
                padding:
                  accountInfo.locale === LOCALE_CODE.FR ||
                  accountInfo.locale === LOCALE_CODE.DE || accountInfo.locale === LOCALE_CODE.ES
                    ? '6px 16px !important'
                    : '12px 16px',
              },
            }}
            className={`${classes.allSelectCard} manage-group-device-all-select-card`}
          >
            <ListItem
              secondaryAction={
                <IconButton
                  aria-label="Remove user"
                  disabled={checkedGroup.length <= 0}
                  className={classes.allSelectCardBtnRemove}
                  disableRipple
                  onClick={handleRemove}
                >
                  <CheckMarkCross />
                  <Typography className={classes.allSelectCardBtnText}>
                    {t('userListModal.label.remove')}
                  </Typography>
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton
                // disabled={originalAndAddedUserList.length - totalAdmin === 0}
                disabled={
                  accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPUSERMODAL.includes(
                    FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPUSERMODAL.DELETE,
                  )
                    ? originalAndAddedUserList.length - totalAdmin === 0
                    : addedUserList.length === 0 ||
                      originalAndAddedUserList.length - totalAdmin === 0
                }
                className={classes.allSelectCardBtn}
                sx={{
                  marginRight:
                    accountInfo.locale === LOCALE_CODE.JA
                      ? '134px'
                      : accountInfo.locale === LOCALE_CODE.FR
                      ? '190px'
                      : accountInfo.locale === LOCALE_CODE.DE
                      ? '216px'
                      : accountInfo.locale === LOCALE_CODE.ES
                      ? '134px'
                      : '204px',
                }}
                onClick={handleGroupSelectAllToggle}
                disableRipple
              >
                <Checkbox
                  checked={checkedGroupAll}
                  icon={<CheckBox className={classes.checkBoxIconStyle} />}
                  checkedIcon={<CheckedCheckBox />}
                  sx={{
                    p: '0px',
                    mr: '8px',
                    '& svg': {
                      '& g': {
                        stroke: !checkedGroupAll
                          ? originalAndAddedUserList.length - totalAdmin === 0
                            ? '#C5C5C580'
                            : '#C5C5C5'
                          : '#009de1',
                      },
                    },
                  }}
                />
                <ListItemText
                  primary={
                    <Typography className={classes.allSelectCardText}>
                      {t('userListModal.label.selectUser')}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          </Box>
          {/* group User List */}

          <List sx={{ p: '0px', mt: '6px' }}>
            {!(
              isCallingApi ||
              userTransitionLoading ||
              loadingForGroupSearch
            ) &&
              originalAndAddedUserList.length !== 0 && (
                <Box style={{ position: 'relative' }}>
                  <SimpleBarReact
                    style={{ height: modalListHeight }}
                    autoHide={false}
                    className="manage-group-device-item-box"
                  >
                    {({ scrollableNodeRef, contentNodeRef }) => (
                      <VariableListWindow
                        className={`${classes.listWindowContainer} manage-group-device-list-container`}
                        itemCount={originalAndAddedUserList.length}
                        itemSize={getItemSize2}
                        height={modalListHeight}
                        width={350}
                        outerRef={scrollableNodeRef}
                        innerRef={contentNodeRef}
                        ref={listRef2}
                      >
                        {({ index, style }) => {
                          const user = originalAndAddedUserList[index];
                          /*eslint-disable*/

                          const nameWord = user.userName
                            .trim()
                            .charAt(0)
                            .toUpperCase();

                          const foundRemoved = removedUserList.findIndex(
                            (obj) => obj.id === user.id,
                          );
                          if (foundRemoved !== -1) {
                            return null;
                          }
                          const userNameWidth = pixelWidth(user.userName, {
                            font: 'open sans',
                            size: 14,
                          });

                          const isJapanese = regexJapanese.exec(user.userName);

                          const userEmailWidth = pixelWidth(user.mailAddress, {
                            size: 13,
                          });

                          return (
                            <Box
                              key={index}
                              style={style}
                              sx={{
                                minHeight: '84px',
                                '&:not(:last-child)': {
                                  marginBottom: '8px',
                                },
                              }}
                            >
                              <ListItemButton
                                sx={{
                                  minHeight: '76px',
                                  border:
                                    user.roleName === t('common.adminRole')
                                      ? '1px solid #93959880 !important'
                                      : checkedGroup.findIndex(
                                          (obj) => obj.id === user.id,
                                        ) !== -1
                                      ? '1px solid #4DC9FF !important'
                                      : '1px solid #C5C5C5 !important',
                                }}
                                className={`${
                                  checkedGroup.findIndex(
                                    (obj) => obj.id === user.id,
                                  ) !== -1 &&
                                  user.roleName !== t('common.adminRole')
                                    ? clsx(
                                        classes.listItemButton,
                                        classes.listItemButtonActive,
                                      )
                                    : classes.listItemButton
                                } manage-group-device-item-list`}
                                onClick={handleGroupToggle(user.id)}
                                disableRipple
                                disabled={
                                  accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPUSERMODAL?.includes(
                                    FEATURE_PERMISSION.GROUPMANAGEMENT
                                      .GROUPUSERMODAL.DELETE,
                                  )
                                    ? user.roleName === t('common.adminRole')
                                    : addedUserList.findIndex(
                                        (newUser) => newUser.id === user.id,
                                      ) === -1 ||
                                      user.roleName === t('common.adminRole')
                                }
                              >
                                <ListItem
                                  sx={{
                                    p: '3px 16px',
                                  }}
                                >
                                  <Checkbox
                                    checked={
                                      checkedGroupAll
                                        ? user.roleName ===
                                          t('common.adminRole')
                                          ? false
                                          : true
                                        : checkedGroup.findIndex(
                                            (obj) => obj.id === user.id,
                                          ) !== -1
                                    }
                                    tabIndex={-1}
                                    icon={
                                      user.roleName === t('common.adminRole') ||
                                      (!accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPUSERMODAL?.includes(
                                        FEATURE_PERMISSION.GROUPMANAGEMENT
                                          .GROUPUSERMODAL.DELETE,
                                      ) &&
                                        addedUserList.findIndex(
                                          (newUser) => newUser.id === user.id,
                                        ) === -1) ? (
                                        <CheckBoxDisable
                                          className={classes.checkBoxIconStyle}
                                        />
                                      ) : (
                                        <CheckBox
                                          className={classes.checkBoxIconStyle}
                                        />
                                      )
                                    }
                                    checkedIcon={<CheckedCheckBox />}
                                    className={classes.checkBoxStyle}
                                  />
                                  <ListItemAvatar
                                    sx={{
                                      minWidth: '50px',
                                    }}
                                  >
                                    {originalAndAddedUserList[index]
                                      .imageUrl ? (
                                      <img
                                        alt="pic"
                                        src={
                                          originalAndAddedUserList[index]
                                            .imageUrl
                                        }
                                        className={classes.listImageStyle}
                                      />
                                    ) : (
                                      <Avatar
                                        className={classes.ListAvatar}
                                        sx={{
                                          bgcolor: user.avatarColor,
                                        }}
                                      >
                                        {nameWord}
                                      </Avatar>
                                    )}
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={
                                      <Box className={classes.listItemTextBox}>
                                        <StyledTooltip
                                          title={user.userName}
                                          dataWidth={userNameWidth}
                                          divWidth={
                                            // isJapanese ? 247 : 199.95 * 2
                                            isJapanese ? 205 : 199.95 * 2
                                          }
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: '14px',
                                              lineHeight: '19px',
                                              color: '#313133',
                                              width: '86.19%',
                                            }}
                                            className={
                                              classes.textBreakTwoLines
                                            }
                                          >
                                            {user.userName}
                                          </Typography>
                                        </StyledTooltip>
                                        <div
                                          style={{
                                            marginRight: '-6px',
                                            height: '19px',
                                            display:
                                              addedUserList.findIndex(
                                                (newUser) =>
                                                  newUser.id === user.id,
                                              ) === -1
                                                ? 'none'
                                                : 'flex',
                                          }}
                                        >
                                          <ActiveIcon />
                                        </div>
                                      </Box>
                                    }
                                    secondary={
                                      <Box
                                        sx={{
                                          dixplay: 'flex',
                                          flexDirection: 'coloum',
                                        }}
                                      >
                                        <StyledTooltip
                                          title={user.mailAddress}
                                          dataWidth={userEmailWidth}
                                          divWidth={232}
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: '13px',
                                              lineHeight: '19px',
                                              color: '#939598',
                                              fontWeight: 'light',
                                            }}
                                            className={`${
                                              userEmailWidth > 232
                                                ? classes.textEllipse
                                                : classes.textOverflow
                                            } manage-group-device-item-text`}
                                          >
                                            {user.mailAddress}
                                          </Typography>
                                        </StyledTooltip>
                                        <Typography
                                          sx={{
                                            fontSize: '13px',
                                            lineHeight: '19px',
                                            color: '#939598',
                                            fontWeight: 'light',
                                          }}
                                        >
                                          {user.roleName}
                                        </Typography>
                                      </Box>
                                    }
                                  />
                                </ListItem>
                              </ListItemButton>
                            </Box>
                          );
                        }}
                      </VariableListWindow>
                    )}
                  </SimpleBarReact>
                  {!isCallingApi &&
                    originalUserListDataHeight < windowHeight &&
                    !(
                      originalAndAddedUserList.length === 0 &&
                      dataProcessingCounter >= 2
                    ) && (
                      <Divider
                        orientation="vertical"
                        className={`${classes.divider} manage-group-device-no-divider`}
                        style={{ position: 'absolute', top: 0, right: 0 }}
                      />
                    )}
                </Box>
              )}
            {/* no devices */}
            {!(
              isCallingApi ||
              userTransitionLoading ||
              loadingForGroupSearch
            ) &&
              originalAndAddedUserList.length === 0 &&
              (dataProcessingCounter >= 2 ||
                errorType ===
                  MANAGE_GROUP_USER_LIST_API_RESULT_CODE.NO_INTERNET) && (
                <Box
                  className="manage-group-device-no-device"
                  display="flex"
                  style={{ overflow: 'hidden' }}
                >
                  <Box
                    className={`${classes.NoDataBoxWithDivider} manage-group-device-data-not-found-block`}
                  >
                    <Box
                      className={`${classes.NoDataBox} manage-group-device-data-not-found-icon`}
                    >
                      <GroupUserIcon />
                      <Typography className={classes.noDataMessage}>
                        {noUserMessageGrouped}
                      </Typography>
                    </Box>
                    <Divider
                      orientation="vertical"
                      flexItem
                      className={`${classes.divider} manage-group-device-no-divider`}
                    />
                  </Box>
                </Box>
              )}
            {/* skeleton loading */}
            {(isCallingApi ||
              userTransitionLoading ||
              loadingForGroupSearch) && (
              <Box
                className={`${classes.NoDataBoxWithDivider} manage-group-device-data-not-found-block`}
              >
                <Box
                  className={`${classes.NoDataSkeleletonBox} manage-group-device-data-not-found-icon`}
                >
                  <ManageUserSkeleton />
                </Box>
              </Box>
            )}
          </List>
        </Box>
        {/* end Ungroup Card */}
      </Box>
    </Box>
  );
});

export default ManageUserDialog;

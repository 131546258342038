import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import { ALL_ERROR_TYPE, SERVER_MESSAGE } from '../constants/constants';
import callUserDeactivateApi from '../apis/callUserDeactivateApi'; // USER_DELETE_ERROR_TYPE, // USER_DELETE_API_RESULT_CODE,
import type { UserDeactivateParameter } from '../types/apis/userDeleteApi';

import { isErrorType } from '../types/apis/apiErrorTypeGuard';
import useSnackbar from './useSnackbar';
import { sendGAEvent } from './useGAEvent';
import errorMessageCodes from '../constants/errorMessageCodes';

// エラーに関する情報
export type UserDeleteError = {
  userDeleteErrorType: ALL_ERROR_TYPE | null;
  errorMessage: string;
};

/**
 * 本カスタムフックからの返却値
 */
export type UseUserDeleteValue = {
  // APIコール中か否か
  isCallingUserDeleteApi: boolean;
  // [ユーザー情報編集]画面 [ユーザー削除]ボタン押下時処理
  onClickOpenUserDeleteDialog: () => void;
  // [キャンセル]ボタン押下時処理
  onClickCancelButton: () => void;
  // [削除]ボタン押下時処理
  onClickDeactivateUserButton: (
    mailAddress: string,
    activeStatus: boolean,
  ) => void;
  // エラーに関する情報
  userDeleteError: UserDeleteError;
  // ユーザー削除ダイアログを表示するかどうか
  isOpenUserDeleteModal: boolean;
  // User Status Change
  userStatusChange: boolean;
};

/**
 * ユーザー詳細(ユーザー情報編集)画面 hooks
 *
 * @returns
 */
const useUserDeactivate = (): UseUserDeleteValue => {
  const [userStatusChange, setUserStatusChange] = useState(false);

  // ユーザ更新エラータイプ
  const [userDeleteErrorType, setUserDeleteErrorType] =
    useState<ALL_ERROR_TYPE | null>(null);

  // APIコール中か否か
  const [isCallingApi, setIsCallingApi] = useState<boolean>(false);

  // [ユーザー削除]ダイアログを表示するかどうか
  const [isOpenUserDeleteModal, setIsOpenUserDeleteModal] =
    useState<boolean>(false);

  // navigate(画面遷移)
  const navigate = useNavigate();

  // 言語切り替えhooks
  const { t } = useSwitchLocaleLanguage();

  const { displaySnackbar } = useSnackbar();
  /**
   * [ユーザー情報編集]画面 [ユーザー削除]ボタン押下時処理
   */
  const onClickOpenUserDeleteDialog = () => {
    setIsOpenUserDeleteModal(true);
  };

  /**
   * [キャンセル]ボタン押下時処理
   */
  const onClickCancelButton = () => {
    // ユーザ更新エラータイプ初期化
    setUserDeleteErrorType(null);
    // ダイアログを閉じる
    setIsOpenUserDeleteModal(false);
  };

  /**
   * [削除]ボタン押下時処理
   */
  const onClickDeactivateUserButton = (
    mailAddress: string,
    activeStatus: boolean,
  ) => {
    // ユーザ更新エラータイプ初期化
    setUserDeleteErrorType(null);
    // API処理中
    setIsCallingApi(true);
    setUserStatusChange(false);
    const requestParam: UserDeactivateParameter = {
      mailAddress,
      activeStatus,
    };
    // ユーザー削除API呼び出し
    void callUserDeactivateApi(requestParam)
      .then((apiResponse) => {
        switch (apiResponse.message) {
          case SERVER_MESSAGE.UPDATE_OK: {
            setUserStatusChange(true);
            setIsOpenUserDeleteModal(false);
            displaySnackbar({
              message: activeStatus
                ? t('userDeleteDialog.message.activateSuccessful')
                : t('userDeleteDialog.message.deactivateSuccessful'),
            });
            break;
          }
          case SERVER_MESSAGE.ACCESS_PERMISSION_DENIED: {
            displaySnackbar({
              message: t('userDeleteDialog.message.deleteNotAllowed'),
              type: 'error',
            });
            navigate(-1);
            break;
          }
          case SERVER_MESSAGE.DELETE_NOT_ALLOWED: {
            displaySnackbar({
              message: t('userDeleteDialog.message.deleteNotAllowed'),
              type: 'error',
            });
            break;
          }
          case SERVER_MESSAGE.DELETE_NOT_ALLOWED_MUST_NEED_OTHER_ADMIN: {
            displaySnackbar({
              message: t('userDeleteDialog.message.mustNeedOneAdmin') + errorMessageCodes['userDeleteDialog.message.mustNeedOneAdmin.ROLE_UPDATE_NOT_ALLOWED_MUST_NEED_OTHER_ADMIN'],
              type: 'error',
            });
            break;
          }
          case SERVER_MESSAGE.DEACTIVATE_NOT_ALLOWED: {
            displaySnackbar({
              message: t('userDeleteDialog.message.corporateContactEmail') + errorMessageCodes['userDeactivate.message.corporateContactEmail.DEACTIVATE_NOT_ALLOWED'],
              type: 'error',
            });
            break;
          }
          case SERVER_MESSAGE.INFO_NO_USER: {
            displaySnackbar({
              message: t('userDeleteDialog.message.userNotAvailable') + errorMessageCodes['userDeleteDialog.message.userNotAvailable.INFO_NO_USER'],
              type: 'error',
            });
            break;
          }
          case SERVER_MESSAGE.INVALID_TOKEN:
          case SERVER_MESSAGE.EXPIRED_TOKEN:
          case SERVER_MESSAGE.WARN_INVALID_AUTH: {
            // ユーザー削除ダイアログを非表示
            setIsOpenUserDeleteModal(false);

            // ログアウトダイアログ表示のためsetUserDeleteErrorTypeにエラーコードをセット
            throw apiResponse.message;
          }
          default: {
            displaySnackbar({
              message: t('common.error.serverErr'),
              type: 'error',
            });
            throw apiResponse.message;
          }
        }
        // loadingBar非表示(finallyで消すとメモリーリークが発生するのでこのタイミングで消す)
        setIsCallingApi(false);
      })
      .catch((error: ALL_ERROR_TYPE | unknown) => {
        if (isErrorType(error)) {
          setUserDeleteErrorType(error);
        } else {
          setUserDeleteErrorType(SERVER_MESSAGE.ERR_UNKNOWN);
        }
        // loadingBar非表示(finallyで消すとメモリーリークが発生するのでこのタイミングで消す)
        setIsCallingApi(false);
      });

    // GA tag
    sendGAEvent(
      'press_btn',
      'btn_name',
      activeStatus ? 'activate' : 'deactivate',
    );
  };

  /**
   * User detailed error message
   */
  const errorMessage = useMemo(
    (): string => {
      if (!userDeleteErrorType) {
        return ''; // エラーコンポーネント自体非表示にする
      }

      // ユーザ削除エラー
      switch (userDeleteErrorType) {
        case SERVER_MESSAGE.INVALID_TOKEN:
        case SERVER_MESSAGE.DELETE_NOT_ALLOWED:
        case SERVER_MESSAGE.EXPIRED_TOKEN:
        case SERVER_MESSAGE.WARN_INVALID_AUTH:
          return ''; // ログイン認証エラー、権限不正エラー時はアクセスキー不正エラーダイアログを表示するのでエラーメッセージは表示しない
        case SERVER_MESSAGE.USER_NOT_FOUND:
          return t('userDeleteDialog.userDeleteApiError.infoNoUser');
        default:
          return t('userDeleteDialog.userDeleteApiError.other');
      }
    },
    [userDeleteErrorType, t], // エラータイプが変わる度にエラーメッセージが返却される
  );

  return {
    isCallingUserDeleteApi: isCallingApi,
    onClickOpenUserDeleteDialog,
    onClickCancelButton,
    onClickDeactivateUserButton,
    userDeleteError: {
      userDeleteErrorType,
      errorMessage,
    },
    isOpenUserDeleteModal,
    userStatusChange,
  };
};

export default useUserDeactivate;

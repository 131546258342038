import { useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import useAccessKey from './useAccessKey';
import useAccountInfo from './useAccountInfo';
import callGroupDeleteApi, {
  GROUP_DELETE_API_RESULT_CODE,
  GROUP_DELETE_ERROR_TYPE,
} from '../apis/callGroupDeleteApi';
import { PAGE_PATH_NAME, SERVER_MESSAGE } from '../constants/constants';
import { transToGroups } from '../utils/group/groupDetail';

import type {
  GroupDeleteApiParameter,
  //   GroupDeleteApiResponse,
} from '../types/apis/groupDeleteApi';
// import {} from '../types/Group/groupDelete.d';
import { isGroupDeleteErrorType } from '../types/apis/apiErrorTypeGuard';
import useSnackbar from './useSnackbar';
import useRedirectDialog from './useRedirectDialog';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import errorMessageCodes from '../constants/errorMessageCodes';

type IDParams = {
  groupId: string;
};

// export type UseGroupDeleteValue = {
//     // APIコール中か否か
//     isCallingGroupDeleteApi: boolean;
//     // [User Information Edit] Screen [User Delete] button pressing processing
//     onClickOpenUserDeleteDialog: () => void;
//     // [キャンセル]ボタン押下時処理
//     onClickCancelButton: () => void;
//     // [削除]ボタン押下時処理
//     onClickDeleteUserButton: (mailAddress: string) => void;
//     // エラーに関する情報
//     userDeleteError: UserDeleteError;
//     // ユーザー削除ダイアログを表示するかどうか
//     isOpenUserDeleteModal: boolean;
//   };

/**
 * ユーザー詳細(ユーザー情報編集)画面 hooks
 *
 * @returns
 */
const useGroupDelete = () => {
  // アクセスキー
  const [deleteAPIMsg, setDeleteAPIMsg] = useState('');
  // const { accessKey } = useAccessKey();
  const { accountInfo } = useAccountInfo();
  const { displaySnackbar } = useSnackbar();
  const { displayRedirectDialog } = useRedirectDialog();
  // const { onClickLogoff } = useLogout();

  // ユーザ更新エラータイプ
  const [groupDeleteErrorType, setGroupDeleteErrorType] =
    useState<GROUP_DELETE_ERROR_TYPE | null>(null);
  const { groupId } = useParams<IDParams>();
  // APIコール中か否か
  const [isCallingGroupDeleteApi, setIsCallingGroupDeleteApi] =
    useState<boolean>(false);

  // [ユーザー削除]ダイアログを表示するかどうか
  const [isOpenGroupDeleteModal, setIsOpenGroupDeleteModal] =
    useState<boolean>(false);

  // navigate(画面遷移)
  const navigate = useNavigate();

  const { t } = useSwitchLocaleLanguage();

  // 言語切り替えhooks

  /**
   * [ユーザー情報編集]画面 [ユーザー削除]ボタン押下時処理
   */
  const onClickOpenGroupDeleteDialog = () => {
    setIsOpenGroupDeleteModal(true);
  };

  /**
   * [キャンセル]ボタン押下時処理
   */
  const onClickCancelButton = () => {
    // ユーザ更新エラータイプ初期化
    setGroupDeleteErrorType(null);
    // ダイアログを閉じる
    setIsOpenGroupDeleteModal(false);
  };

  /**
   * [削除]ボタン押下時処理
   */
  const onClickDeleteGroupButton = () => {
    // ユーザ更新エラータイプ初期化
    setGroupDeleteErrorType(null);
    // API処理中
    setIsCallingGroupDeleteApi(true);

    const requestParam: GroupDeleteApiParameter = {
      groupId: +groupId!,
      corpId: accountInfo.corpId,
    };

    // Group deletion API call
    void callGroupDeleteApi(requestParam)
      .then((apiResponse) => {
        switch (apiResponse.message) {
          case GROUP_DELETE_API_RESULT_CODE.SUCCESSFULLY_DELETED: {
            displaySnackbar({
              message: t('groupDelete.message.deleteSuccess'),
            });
            setDeleteAPIMsg(GROUP_DELETE_API_RESULT_CODE.SUCCESSFULLY_DELETED);
            navigate(PAGE_PATH_NAME.GROUPS);
            break;
          }
          case GROUP_DELETE_API_RESULT_CODE.INFO_NO_GROUP: {
            displayRedirectDialog({
              open: true,
              title: t('common.error.invalidGroup'),
              message: t('common.error.unavailableGroup') + errorMessageCodes['common.error.unavailableGroup'],
            });
            break;
          }
          case GROUP_DELETE_API_RESULT_CODE.GROUP_ACCESS_DENIED: {
            displayRedirectDialog({
              open: true,
              title: t('common.error.notAuthorized'),
              message: t('common.error.unauthorizedAccess')+ errorMessageCodes['common.error.unauthorizedAccess.GROUP_ACCESS_DENIED'],
            });
            break;
          }
          case GROUP_DELETE_API_RESULT_CODE.INFO_NO_CORPORATION:
          case GROUP_DELETE_API_RESULT_CODE.WARN_INVALID_AUTH:
          case SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED:
          case SERVER_MESSAGE.USER_ROLE_CHANGED:
          case SERVER_MESSAGE.INVALID_TOKEN:
          case SERVER_MESSAGE.EXPIRED_TOKEN: {
            break;
          }
          case GROUP_DELETE_API_RESULT_CODE.INFO_NOTHING_KEY: {
            navigate('/');
            break;
          }
          default:
            throw apiResponse.message;
        }
        // LOADINGBAR hidden (erase a memory leak when erased with Finally)
        setIsCallingGroupDeleteApi(false);
      })
      .catch((error: GROUP_DELETE_ERROR_TYPE | unknown) => {
        if (isGroupDeleteErrorType(error)) {
          setGroupDeleteErrorType(error);
        } else {
          setGroupDeleteErrorType(GROUP_DELETE_API_RESULT_CODE.ERR_UNKNOWN);
        }
        // LOADINGBAR hidden (erase a memory leak when erased with Finally)
        setIsCallingGroupDeleteApi(false);
        displaySnackbar({
          message: t('groupDelete.message.unableToDelete') + errorMessageCodes['groupDelete.message.unableToDelete'],
          type: 'error',
        });
      });
  };

  /**
   * ユーザ詳細エラーメッセージ
   */
  const errorMessage = useMemo(
    (): string => {
      if (!groupDeleteErrorType) {
        return ''; // Hide the error component itself
      }

      // Group deletion error
      switch (groupDeleteErrorType) {
        case GROUP_DELETE_API_RESULT_CODE.WARN_INVALID_AUTH:
          return ''; // In the event of a login authentication error or authority fraud error, the access key fraudulent error dialog is displayed, so no error message is displayed.
        case GROUP_DELETE_API_RESULT_CODE.INFO_NO_CORPORATION:
          return t('common.error.noCorporation')+ errorMessageCodes['common.error.noCorporation'];
        case GROUP_DELETE_API_RESULT_CODE.INFO_NO_GROUP:
          return t('common.error.unavailableGroup');
        default:
          return t('groupDelete.message.unableToDelete') + errorMessageCodes['groupDelete.message.unableToDelete'];
      }
    },
    [groupDeleteErrorType, t], // Each time the error type changes, the error message is returned
  );

  const onClickGroupList = () => {
    transToGroups(navigate);
  };

  return {
    isCallingGroupDeleteApi,
    onClickOpenGroupDeleteDialog,
    onClickCancelButton,
    onClickDeleteGroupButton,
    groupDeleteError: {
      groupDeleteErrorType,
      errorMessage,
    },
    deleteAPIMsg,
    isOpenGroupDeleteModal,
    onClickGroupList,
  };
};

export default useGroupDelete;

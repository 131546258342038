import { useEffect, useState, useRef } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SimpleBarReact from 'simplebar-react';
import { Button, Box, Container, Typography } from '@mui/material';
import useGroupList from '../../hooks/useGroupList';
// import Filter from '../Modecules/Filter';
import TableTemplateContainer from '../Templates/TableTemplateContainer';
import GroupFeatureTemplate from '../Templates/GroupFeatureTemplate';
import styles from './GroupList.module.scss';
import { ReactComponent as CreateGroupIcon } from '../../images/icon/create-group.svg';
import { ReactComponent as PlusIcon } from '../../images/icon/plus_icon.svg';
import NoGroupIconCreate from '../../images/icon/no-group-create.svg';
import { ReactComponent as GroupIcon } from '../../images/icon/Group_user.svg';
import useAccountInfo from '../../hooks/useAccountInfo';
import {
  FEATURE_PERMISSION,
  ROLE_CODE,
  SERVER_MESSAGE,
  TABLE_ROW_NUMBER_PER_PAGE,
} from '../../constants/constants';
import SearchField from '../Modecules/SearchField';
import { GROUP_LIST_API_RESULT_CODE } from '../../apis/callGroupListApi';
import { sendGAEvent, sendPageView } from '../../hooks/useGAEvent';
import { useStyles } from '../Templates/CommonStyle';
import {
  groupListSlice,
  GroupListState,
} from '../../state/slice/groupListSlice';
import { AppDispatch, RootState, useAppDispatch } from '../../state/store';

const GroupListPage = (): JSX.Element => {
  // common style
  const classes = useStyles();
  const groupListStateInfo = useSelector<RootState, GroupListState>(
    (state) => state.groupListState,
  );

  const [searchText, setSearchText] = useState<string>(
    groupListStateInfo.fromGroupDetailsPage === true &&
      groupListStateInfo.searchText
      ? encodeURIComponent(groupListStateInfo.searchText)
      : '',
  );

  const location = useLocation();
  const locationKey = location.key;
  const {
    currentPageDataRows,
    headerColumns,
    t,
    sortValue,
    // filterValue,
    paginationValue,
    onClickAddGroupPage,
    errorType,
    groupCode,
    isCallingApi,
  } = useGroupList({ searchText, locationKey });
  const { accountInfo } = useAccountInfo();
  // declar Ref for the table body simple scroll bar
  const simpleBarRef: React.LegacyRef<SimpleBarReact> = useRef(null);

  useEffect(() => {
    const pageProps = {
      pagetitle: t('groupList.label.title'),
      pageLocation: location.pathname,
    };
    sendPageView(pageProps);
    if (accountInfo) {
      ReactGA.set({ userId: accountInfo.userName });
      ReactGA.gtag('set', 'user_properties', {
        userId: accountInfo.userName,
        role: accountInfo.roleName,
        corporate_id: accountInfo.corpId,
        corporate_name: accountInfo.company,
        language: accountInfo.locale,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, accountInfo]);

  // resetting searchText when locationKey changes
  useEffect(() => {
    const tableBody = document.getElementsByClassName(
      'table-body-simplebar',
    )[0];
    if (tableBody) {
      const scrollContainerClass = tableBody.querySelector(
        '.simplebar-content-wrapper',
      );
      if (scrollContainerClass) {
        scrollContainerClass.scrollTop = 0;
      }
    }
  }, [locationKey]);


  const handleNoGroupNewGroup = () => {
    sendGAEvent('press_btn', 'btn_name', 'no_group_new_group');
    onClickAddGroupPage();
  };

  // this condition is to prevent blink of table when no data is available after searching
  const [firstTimeRendering, setFirstTimeRendering] = useState<boolean>(true);

  useEffect(() => {
    if (errorType === GROUP_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED) {
      setFirstTimeRendering(false);
    }
  }, [errorType]);

  const dispatch: AppDispatch = useAppDispatch();
  const { addSearchText } = groupListSlice.actions;

  useEffect(() => {
    let popStateActive = false;
    window.onpopstate = () => {
      if (
        groupListStateInfo.fromGroupDetailsPage === true &&
        groupListStateInfo.searchText
      ) {
        popStateActive = true;
      }
    };

    if (popStateActive === false) {
      dispatch(addSearchText(decodeURIComponent(searchText)));
    }
  }, [searchText, addSearchText, groupListStateInfo, dispatch]);

  return (
    <GroupFeatureTemplate
      titleText={t('groupList.viewName')}
      isUserUpdate={false}
    >
      <Container
        style={{
          paddingRight: '0',
          paddingLeft: '0',
          maxWidth: '100%',
        }}
      >
        <div
          className={`${styles['group-list-flex-box']} group-list-responsive-flex`}
        >
          <SearchField
            locationKey={locationKey}
            clickFilterSearch={(val) => {
              setSearchText(val);
              // GA event tag
              sendGAEvent('press_btn', 'txt_search_kw', searchText);
            }}
            placeholder={t('groupList.placeholder.filterPlaceholder')}
            defaultValue={
              groupListStateInfo.fromGroupDetailsPage === true &&
              groupListStateInfo.searchText
                ? groupListStateInfo.searchText
                : ''
            }
            responsiveClass="search-input-list"
            // autoFocusValue
          />
          {/* eslint-disable */}
          {accountInfo.featurePermissions?.GROUPMANAGEMENT?.GROUPBASIC?.filter(
            (x) => x === FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPBASIC.ADD,
          ).length === 1 && (
            <Button
              id="addGroupButton"
              variant="contained"
              sx={{
                // width: '141px',
                padding: '9px 15px',
                lineHeight: '14px',
                textTransform: 'none',
                color: 'white',
                background: '#009DE1',
                boxShadow: 'none',
                borderRadius: '6px',
                '&:hover': {
                  backgroundColor: '#007EB4',
                },
              }}
              onClick={() => onClickAddGroupPage()}
            >
              <CreateGroupIcon style={{ marginRight: '5px' }} />
              {t('groupList.button.createGroup')}
            </Button>
          )}
        </div>
        {accountInfo.featurePermissions?.GROUPMANAGEMENT?.GROUPBASIC?.filter(
          (x) => x === FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPBASIC.VIEWLIST,
        ).length === 1 && (
          <div>
            <Box
              className={classes.tableCard}
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              {!isCallingApi &&
                (paginationValue.totalData === 0 ||
                  errorType === GROUP_LIST_API_RESULT_CODE.INFO_NO_DATA) && (
                  <div>
                    <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>
                      {t('groupList.label.title')} (0)
                    </Typography>
                  </div>
                )}
              {/* no group found */}
              {!isCallingApi &&
                (paginationValue.totalData === 0 ||
                  errorType === GROUP_LIST_API_RESULT_CODE.INFO_NO_DATA) &&
                errorType !== SERVER_MESSAGE.NO_INTERNET && (
                  <div className={classes.tableCardInner}>
                    {(accountInfo.roleCode === ROLE_CODE.ADMIN ||
                      accountInfo.roleCode === ROLE_CODE.USER) && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <GroupIcon style={{ width: '67px', height: '40px' }} />
                        <Typography
                          variant="h4"
                          style={{
                            fontWeight: 'medium',
                            lineHeight: '29px',
                            fontSize: '20px',
                            marginTop: '13.4px',
                            marginBottom: '2px',
                          }}
                        >
                          {t('groupList.errors.noGroup')}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '14px',
                            lineHeight: '20px',
                            color: '#939598',
                          }}
                        >
                          {t('common.error.tryAgain')}
                        </Typography>
                      </div>
                    )}
                  </div>
                )}
              {!isCallingApi &&
                (paginationValue.totalData === 0 &&
                  errorType == SERVER_MESSAGE.NO_INTERNET) && (
                  <div className={classes.tableCardInner}>
                    {(accountInfo.roleCode === ROLE_CODE.ADMIN ||
                      accountInfo.roleCode === ROLE_CODE.USER) && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <GroupIcon style={{ width: '67px', height: '40px' }} />
                        <Typography
                          sx={{
                            fontSize: '20px',
                            fontWeight: '500',
                            lineHeight: '20px',
                            marginTop: '13.4px',
                            color: '#313133',
                          }}
                        >
                          {t('common.error.noData')}
                        </Typography>
                      </div>
                    )}
                  </div>
                )}
              {errorType === GROUP_LIST_API_RESULT_CODE.ERR_UNKNOWN && (
                <p>{t('common.error.serverErr')}</p>
              )}

              {errorType !== GROUP_LIST_API_RESULT_CODE.ERR_UNKNOWN &&
                errorType !== SERVER_MESSAGE.NO_INTERNET &&
                paginationValue.totalPages > 0 &&
                (firstTimeRendering ||
                  errorType ===
                    GROUP_LIST_API_RESULT_CODE.SUCCESSFULLY_FETCHED) && (
                  <TableTemplateContainer
                    simpleBarRef={simpleBarRef}
                    headerColumns={headerColumns}
                    dataRows={currentPageDataRows}
                    totalDataRowsLabel="Group"
                    totalDataRows={paginationValue.totalData}
                    clickSortButton={sortValue.onClickSortButton}
                    sortConfig={sortValue.sortConfig}
                    currentPage={paginationValue.currentPage}
                    totalPages={paginationValue.totalPages}
                    clickPrevPageButton={paginationValue.onClickPrevPage}
                    clickNextPageButton={paginationValue.onClickNextPage}
                    clickFirstPageButton={paginationValue.onClickFirstPage}
                    clickLastPageButton={paginationValue.onClickLastPage}
                    rowNumberPerPages={TABLE_ROW_NUMBER_PER_PAGE}
                    currentRowNumberPerPage={
                      paginationValue.currentRowNumberPerPage
                    }
                    changeRowNumber={paginationValue.onChangeRowNumber}
                    containFirstAndLastButton
                    pageDataIndexFrom={paginationValue.pageDataIndexFrom}
                    pageDataIndexTo={paginationValue.pageDataIndexTo}
                    tableSrcPage="Group"
                    isCallingApi={isCallingApi}
                  />
                )}
              {!isCallingApi &&
                currentPageDataRows.length === 1 &&
                paginationValue.currentPage === 1 &&
                searchText === '' &&
                groupCode === 0 &&
                errorType !== GROUP_LIST_API_RESULT_CODE.INFO_NO_DATA && (
                  <div className={classes.tableCardInnerContent}>
                    {accountInfo.featurePermissions?.GROUPMANAGEMENT?.GROUPBASIC?.includes(
                      FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPBASIC.ADD,
                    ) && (
                      <div>
                        <img
                          src={NoGroupIconCreate}
                          alt="create icon"
                          style={{
                            width: '49px',
                            height: '50px',
                            marginBottom: '19.32px',
                          }}
                        />
                        <h4
                          style={{
                            fontWeight: 'bold',
                            marginBottom: '18px',
                            lineHeight: '46px',
                            fontSize: '24px',
                          }}
                        >
                          {t('groupList.label.createDevice')}
                        </h4>
                        {accountInfo.featurePermissions?.GROUPMANAGEMENT?.GROUPBASIC?.filter(
                          (x) =>
                            x ===
                            FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPBASIC.ADD,
                        ).length === 1 && (
                          <Button
                            id="addGroupButton"
                            variant="contained"
                            style={{
                              textTransform: 'none',
                              color: 'white',
                              background: '#009DE1',
                              paddingLeft: '15px',
                              paddingRight: '15px',
                              minHeight: '38px',
                              fontWeight: 'normal',
                              borderRadius: '6px',
                            }}
                            onClick={handleNoGroupNewGroup}
                          >
                            <PlusIcon style={{ marginRight: '5px' }} />
                            {t('groupList.button.createGroup')}
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                )}
            </Box>
          </div>
        )}
      </Container>
    </GroupFeatureTemplate>
  );
};

export default GroupListPage;

import { useMemo, useState } from 'react';
import callManageGroupDeviceListUpdate from '../apis/callManageGroupUpdateApi';
import { ALL_ERROR_TYPE, SERVER_MESSAGE } from '../constants/constants';
// import { isErrorType } from '../types/apis/apiErrorTypeGuard';
import useSnackbar from './useSnackbar';
import useRedirectDialog from './useRedirectDialog';
import { isErrorType } from '../types/apis/apiErrorTypeGuard';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import errorMessageCodes from '../constants/errorMessageCodes';

const useManageGroupDeviceUserUpdate = () => {
  const [isCallingManageUpdateApi, setIsCallingManageUpdateApi] =
    useState<boolean>(false);
  const [groupUpdateError, setGroupUpdateError] = useState('');
  const [isGroupUpdate, setIsGroupUpdate] = useState<boolean>(false);
  const { displaySnackbar } = useSnackbar();
  const { displayRedirectDialog } = useRedirectDialog();

  const { t } = useSwitchLocaleLanguage();

  const onClickManageGroupUpdate = (
    groupId: number,
    userAdd: number[],
    userRemove: number[],
    deviceAdd: number[],
    deviceRemove: number[],
  ) => {
    setIsCallingManageUpdateApi(true);
    void callManageGroupDeviceListUpdate(
      groupId,
      userAdd,
      userRemove,
      deviceAdd,
      deviceRemove,
    )
      .then((result) => {
        if (!result.message) {
          setGroupUpdateError('TIME_OUT');
          throw result.message;
        }
        switch (result.message) {
          case SERVER_MESSAGE.UPDATE_OK: {
            setIsGroupUpdate(true);
            displaySnackbar({
              message: t('manageGroupApiRes.success'),
            });
            break;
          }
          case SERVER_MESSAGE.INFO_NO_GROUP: {
            displayRedirectDialog({
              open: true,
              title: t('common.error.invalidGroup'),
              message: t('common.error.unavailableGroup') + errorMessageCodes['common.error.unavailableGroup'],
            });
            break;
          }
          case SERVER_MESSAGE.STACK_OVERFLOW_ERROR: {
            setGroupUpdateError('STACK_OVERFLOW_ERROR');
            throw result.message;
          }
          case SERVER_MESSAGE.REQUEST_TIMEOUT_ERROR: {
            setGroupUpdateError('REQUEST_TIMEOUT_ERROR');
            throw result.message;
          }
          case SERVER_MESSAGE.GROUP_ACCESS_DENIED: {
            displayRedirectDialog({
              open: true,
              title: t('common.error.notAuthorized'),
              message: t('common.error.unauthorizedAccess') + errorMessageCodes['common.error.unauthorizedAccess.GROUP_ACCESS_DENIED'],
            });
            break;
          }
          case SERVER_MESSAGE.USER_CONSENT_REQUIRED: {
            break;
          }
          case SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED: {
            break;
          }
          case SERVER_MESSAGE.USER_ROLE_CHANGED: {
            break;
          }
          case SERVER_MESSAGE.NO_INTERNET: {
            break;
          }

          default: {
            setGroupUpdateError('Server Error');
            throw result.message;
          }
        }
      })
      .catch((error: ALL_ERROR_TYPE | unknown) => {
        if (isErrorType(error)) {
          setGroupUpdateError(error);
          setIsCallingManageUpdateApi(false);
        }
      })
      .finally(() => {
        setIsCallingManageUpdateApi(false);
      });
  };

  const errorMessage = useMemo((): string => {
    if (!groupUpdateError) {
      return '';
    }

    // ユーザ削除エラー
    switch (groupUpdateError) {
      case SERVER_MESSAGE.INVALID_TOKEN:
      case SERVER_MESSAGE.DELETE_NOT_ALLOWED:
      case SERVER_MESSAGE.EXPIRED_TOKEN:
      case SERVER_MESSAGE.WARN_INVALID_AUTH:
        return '';
      case SERVER_MESSAGE.ERR_UPDATE_DENIED:
        return t('manageGroupApiRes.updateDenied')+ errorMessageCodes['manageGroupApiRes.updateDenied.ERR_UPDATE_DENIED'];
      case 'TIME_OUT':
      case SERVER_MESSAGE.REQUEST_TIMEOUT_ERROR:
        return t('manageGroupApiRes.timeOut') + errorMessageCodes['manageGroupApiRes.timeOut.REQUEST_TIMEOUT_ERROR'];
      case SERVER_MESSAGE.STACK_OVERFLOW_ERROR:
        return t('manageGroupApiRes.timeOut') + errorMessageCodes['manageGroupApiRes.timeOut.STACK_OVERFLOW_ERROR'];
      case SERVER_MESSAGE.USER_NOT_FOUND:
        return t('userDetail.userUpdateApiError.infoNoUser') + String(errorMessageCodes['userDetail.userUpdateApiError.infoNoUser']);
      default:
        return t('common.error.serverErr');
    }
  }, [groupUpdateError, t]);

  return {
    isCallingManageUpdateApi,
    groupUpdateError,
    onClickManageGroupUpdate,
    errorMessage,
    isGroupUpdate,
  };
};

export default useManageGroupDeviceUserUpdate;

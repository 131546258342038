import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { factoryReset } from '../utils/remote/factoryReset';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';

import {
  FACTORY_RESET_API_ERROR_TYPE,
  FACTORY_RESET_API_RESULT_CODE,
} from '../apis/callRemoteFeatureFactoryReset';
import { isFactoryResetErrorType } from '../types/apis/apiErrorTypeGuard';
import useSnackbar from './useSnackbar';
import { DEVICE_DETAIL_UPDATE_STATE } from '../types/devicedetail/deviceDetail.d';
import useDeviceDetailUpdateState from './useDeviceDetailUpdateState';
import { PAGE_PATH_NAME } from '../constants/constants';
import errorMessageCodes from '../constants/errorMessageCodes';

const useFactoryReset = () => {
  const { displaySnackbar } = useSnackbar();
  const { t } = useSwitchLocaleLanguage();

  const [isCallingFactoryResetApi, setIsCallingFactoryResetApi] =
    useState<boolean>(false);
  const [factoryResetErrorType, setFactoryResetErrorType] =
    useState<FACTORY_RESET_API_ERROR_TYPE | null>(null);

  const { saveUpdateState } = useDeviceDetailUpdateState();

  const navigate = useNavigate();

  const onClickFactoryReset = (imei: number): void => {
    setIsCallingFactoryResetApi(true);
    factoryReset(imei)
      .then((result) => {
        switch (result.resultCode) {
          case FACTORY_RESET_API_RESULT_CODE.OK: {
            // Success Message
            setFactoryResetErrorType(result.resultCode);
            displaySnackbar({
              message: t('lostMode.message.factoryInit'),
              type: 'success',
              timeout: 3001,
            });
            saveUpdateState(DEVICE_DETAIL_UPDATE_STATE.SUCCESS);
            break;
          }
          case FACTORY_RESET_API_RESULT_CODE.DEVICE_RESTORE_INITIATED: {
            // Success Message
            setFactoryResetErrorType(result.resultCode);
            displaySnackbar({
              message:
                t('lostMode.message.alreadyFactoryRestored') +
                errorMessageCodes[
                  'lostMode.message.alreadyFactoryRestored.DEVICE_RESTORE_INITIATED'
                ],
              type: 'success',
              timeout: 3002,
            });
            break;
          }
          case FACTORY_RESET_API_RESULT_CODE.ACCESS_PERMISSION_DENIED: {
            // Permission Denied
            setFactoryResetErrorType(result.resultCode);
            displaySnackbar({
              message: t('deviceInfo.devicePermissionApiError'),
              type: 'error',
            });
            navigate(PAGE_PATH_NAME.DEVICE_LIST);
            break;
          }
          default:
            displaySnackbar({
              message: t('lostMode.message.failedFactoryRestored'),
              type: 'error',
              timeout: 3002,
            });
            setFactoryResetErrorType(result.resultCode);
            throw result.resultCode;
        }
        // LOADINGBAR hidden (erase a memory leak when erased with Finally)
        setIsCallingFactoryResetApi(false);
      })
      .catch((error: FACTORY_RESET_API_ERROR_TYPE | unknown) => {
        if (isFactoryResetErrorType(error)) {
          setFactoryResetErrorType(error);
        } else {
          setFactoryResetErrorType(FACTORY_RESET_API_RESULT_CODE.ERR_UNKNOWN);
        }
        // LOADINGBAR hidden (erase a memory leak when erased with Finally)
        setIsCallingFactoryResetApi(false);
      });
  };

  return {
    isCallingFactoryResetApi,
    factoryResetErrorType,
    onClickFactoryReset,
  };
};

export default useFactoryReset;

const errorMessageCodes = {
  // Common Errors
  'common.error.somethingWentWrong': ' (ERR-01-105)', // Something went wrong, please try later.
  'common.error.serverErr': ' (ERR-01-100)', // General Server Error
  'common.error.serverErr.INTERNAL_SERVER_ERROR': ' (ERR-01-101)', // Server Error (Internal Issue)
  'common.error.serverErr.ERR_UNKNOWN': ' (ERR-01-100)', // Unknown Server Error
  'common.error.sessionExpired.INVALID_AUTH0_TOKEN': ' (ERR-01-102)', // Your session has expired.
  'common.error.sessionExpired.EXPIRED_TOKEN': ' (ERR-01-103)', // Your session has expired.
  'common.error.accountHasDeactivated.USER_DEACTIVATED': ' (ERR-01-104)', // Your account has been deactivated. Contact admin.

  // Group and Access Errors
  "common.error.unauthorizedAccess": " (ERR-02-100)", // You are not authorized to access this group.
  "common.error.unauthorizedAccess.GROUP_ACCESS_DENIED": " (ERR-02-101)", // You are not authorized to access this group.
  "groupDetail.error.alreadyExist.WARN_GROUP_NAME_EXIST": " (ERR-02-102)", // Group name already exists.
  "manageDevices.message.updateFailed": " (ERR-02-103)", // Failed to update selected device(s).
  "manageGroupApiRes.updateDenied": " (ERR-02-104)", // Selected items are not available for update.
  "userListModal.message.updateFailed": " (ERR-02-105)", // Failed to update group user(s).
  "manageDevices.message.failedUpdateDevice": " (ERR-02-106)", // Device update failed.
  "manageDevices.message.nUserExist": " (ERR-02-107)", // No user exists in this group.
  "userDetail.userUpdateApiError.infoNoUser": " (ERR-02-108)", // User not found. May have been deleted.
  "common.error.unavailableGroup": " (ERR-02-109)", // The group you are looking for is currently unavailable.
  "groupList.errors.noGroup": " (ERR-02-110)", // No Group Found
  "common.error.noCorporation": " (ERR-02-111)", // No Corporation Exist!
  "common.error.unauthorizedAccess.INFO_NO_ACCOUNT": " (ERR-02-112)", // You are not authorized to access this group.
  "manageGroupApiRes.timeOut.STACK_OVERFLOW_ERROR": " (ERR-02-113)", // Please reduce the remote settings Wi-Fi or Number of Devices.
  "manageGroupApiRes.timeOut.REQUEST_TIMEOUT_ERROR": " (ERR-02-114)", // Please reduce the remote settings Wi-Fi or Number of Devices.
  "manageGroupApiRes.updateDenied.ERR_UPDATE_DENIED": " (ERR-02-115)", // Selected items are nor available for update.
  "manageDevices.message.updateFailed_ERR_UPDATE_DENIED": " (ERR-02-116)", // Failed to update selected group device(s).
  "userListModal.message.updateFailed.ERR_UPDATE_DENIED": " (ERR-02-117)", // Failed to update selected group user(s).
  "manageDevices.message.failedUpdateDevice.ERR_UPDATE_DENIED": " (ERR-02-118)", // Device update failed.
  "common.error.unavailableGroup.WARN_INPUT_PARAM": " (ERR-02-119)", // The group you are looking for is currently unavailable.
  "common.error.unauthorizedAccess.ACCESS_PERMISSION_DENIED": " (ERR-02-120)", // You are not authorized to access this group.
  "groupDetail.error.unauthorizedUser": " (ERR-02-121)", // Unauthorized for this action.
  "common.error.invalidAuth": " (ERR-02-122)", // Invalid auth.
  "groupNameEdit.message.unableToEdit": " (ERR-02-123)", // Unable to edit group. Retry.
  "groupDelete.message.unableToDelete": " (ERR-02-124)", // Unable to delete group. Retry.
  "common.error.unavailableGroup.INFO_NO_USER": " (ERR-02-125)", // The group you are looking for is currently unavailable. 
  "groupDetail.error.unauthorizedUser.WARN_INVALID_ACCESS_KEY": " (ERR-02-126)", // You are Not Authorized for access.
  "groupDetail.error.invalidURL": " (ERR-02-127)", // URL is invalid. 

  // User Service Errors
  'userAdd.errors.alreadyRegistered.USER_ALREADY_REGISTERED': ' (ERR-03-100)', // User already registered.
  'userDetail.message.notAllowed.AUTH_NOT_ALLOWED': ' (ERR-03-102)', // User role not allowed.
  'userDeleteDialog.message.deleteNotAllowed.DELETE_NOT_ALLOWED': ' (ERR-03-103)', // Delete not allowed.
  'userDeleteDialog.message.mustNeedOneAdmin.ROLE_UPDATE_NOT_ALLOWED_MUST_NEED_OTHER_ADMIN': ' (ERR-03-104)', // At least one admin required.
  'userDetail.message.corporateContactEmail.ROLE_UPDATE_NOT_ALLOWED_CORPORATE_CONTACT_EMAIL_EXIST':' (ERR-03-105)', // Change corporate contact email first.
  'userDeactivate.message.mustNeedOneAdmin.DELETE_NOT_ALLOWED_MUST_NEED_OTHER_ADMIN': ' (ERR-03-106)',
  'corporateProfile.message.adminEmail.USER_IS_NOT_ADMIN': ' (ERR-03-107)', // Admin email required.
  'userDeleteDialog.message.corporateContactEmail.DEACTIVATE_NOT_ALLOWED': ' (ERR-03-108)', // Corporate contact email need to be changed before the role changing action.
  'userInvite.message.notFound.USER_NOT_FOUND_IN_OKTA': ' (ERR-03-109)', // User not found in System. Please re-invite user.
  'userInvite.message.alreadyRegistered.SUCCESSFULLY_CREATED': ' (ERR-03-110)', // User registration mail sent successfully. 
  'userDeleteDialog.message.userNotAvailable.INFO_NO_USER': ' (ERR-03-111)', // User already registered.
  'userInvite.message.unknown.ERR_UNKNOWN': ' (ERR-03-113)', // Unable to send request. 
  'emailSendCard.accountCreateSendMail.apiErrors.wrongInput.WARN_INPUT_PARAM': ' (ERR-03-114)', // Wrong input field.
  'userAdd.userPreAddApiError.warnInputParam.WARN_INPUT_PARAM': ' (ERR-03-115)', // Please enter the correct email address and/or username.
  'userAdd.errors.alreadyInvited.INFO_ALREADY_INVITED': ' (ERR-03-116)', // User already invited.
  'userList.userListApiError.ERR_UNKNOWN': ' (ERR-03-117)', // Failed to obtain user list.
  'userAdd.authListApiError.ERR_UNKNOWN': ' (ERR-03-118)', // No user data found.
  'userDetail.userInfoApiError.ERR_UNKNOWN': ' (ERR-03-119)', // Failed to obtain user information.
  'userDetail.userUpdateApiError.infoInvalid.INVALID_TOKEN': ' (ERR-03-120)', // Failed to update role. You must have at least 1 user with the Admin role.
  'userDetail.userUpdateApiError.other.ERR_UNKNOWN': ' (ERR-03-121)', // Failed to update user information.
  'userDeactivate.message.corporateContactEmail.DEACTIVATE_NOT_ALLOWED': ' (ERR-03-122)', // Corporate contact email need to be changed before the deactivate action.
  'userAdd.errors.regMailSentFailure.ERR_UNKNOWN': ' (ERR-03-124)', // Registration mail send failed.
  'userDeleteDialog.message.corporateContactEmail.DELETE_NOT_ALLOWED_CORPORATE_CONTACT_EMAIL_EXIST': ' (ERR-03-125)', // Corporate contact email need to be changed before the deactivate action. 

  // Device Service Errors
  'lostMode.message.deviceAlreadyLostMode.DEVICE_IN_LOST_MODE': ' (ERR-04-100)', // Device already in lost mode.
  'lostMode.message.alreadyDisabled.LOST_MODE_ALREADY_DISABLED':
    ' (ERR-04-101)', // Lost mode already disabled.
  'deviceList.deviceListApiError.noData.INFO_NO_DATA': ' (ERR-04-102)', // Unregistered device.
  'deviceList.errors.noDevice.INFO_NO_DATA': ' (ERR-04-103)', // No device available.
  'common.error.unauthorizedAccess.INFO_NOTHING_KEY': ' (ERR-04-104)', // Unauthorized for this device.
  'deviceInfo.deviceNotFoundApiError.INFO_NO_DEVICE': ' (ERR-04-105)', // Device access denied.
  'deviceList.deviceListApiError.unknown.ERR_UNKNOWN': ' (ERR-04-108)', // Unable to retrieve device list.

  // Login Errors
  'login.loginApiError.unknown.ERR_UNKNOWN': ' (ERR-09-100)', // Unable to log in.

  // Report Errors
  'report.apiError.dateOver.INFO_DATA_OVER': ' (ERR-06-100)', // Date range too long.
  'report.apiError.dateOverWeek': ' (ERR-06-101)', // Date range exceeds week limit.
  'report.apiError.dateOverMonth': ' (ERR-06-102)', // Date range exceeds month limit.
  'transCountList.transCountListApiError.failed.ERR_UNKNOWN': ' (ERR-06-103)', // Translation count download failed.

  // Profile Settings Errors
  'personalSetting.message.imgType.WARN_INPUT_PARAM': ' (ERR-07-100)', // Invalid image type.
  'common.error.accessDenied.ACCESS_PERMISSION_DENIED': ' (ERR-07-101)', // Access denied.
  'common.error.unknown.ERR_UNKNOWN': ' (ERR-07-107)', // Unknown error occurred.
  'common.error.serverErr.ERR_UNKNOWN.PROFILE_SETTINGS': ' (ERR-07-108)', // Server error.

  // Security Settings
  'SecuritySettings.apiResponse.alreadyUpdated.TRANS_HISTORY_PREVENTION_ALREADY_UPDATED':
    ' (ERR-08-100)', // Security settings already updated.
  
  // Remote Device Service Errors
  'common.error.unavailableGroup.WARN_INVALID_AUTH': ' (ERR-05-100)', // The group you are looking for is currently unavailable.
  'lostMode.message.alreadyFactoryRestored.DEVICE_RESTORE_INITIATED': ' (ERR-05-101)', // Already Factory Restored but Not yet Applied.
  'groupDetail.error.invalidURL.WARN_NO_AUTH_LIST': ' (ERR-05-102)', // URL not valid.
  'groupDetail.error.unauthorizedUser.INFO_NOTHING_KEY': ' (ERR-05-103)', // You are Not Authorized for access.
  'remoteDevice.message.setupFailed.ERR_UNKNOWN': ' (ERR-05-104)', // Failed to setup the remote settings. Please try again.
};

export default errorMessageCodes;

/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { useForm, Controller } from 'react-hook-form';
import clsx from 'clsx';
import {
  Box,
  Button,
  Typography,
  TextField,
  ClickAwayListener,
  Tooltip,
  Skeleton,
  IconButton,
} from '@mui/material';
import type { DeviceDetailInput } from '../../types/inputs/deviceDetailInput';
import { FEATURE_PERMISSION } from '../../constants/constants';
import useDeviceDetail from '../../hooks/useDeviceDetail';
import { useStyles } from '../Templates/CommonStyle';
import useAccountInfo from '../../hooks/useAccountInfo';
import Loader from '../Atoms/Loader';
import { ReactComponent as EditPenIcon } from '../../images/icon/edit-pen.svg';
import { ReactComponent as SaveEditIcon } from '../../images/icon/save-edit.svg';
import { ReactComponent as Close } from '../../images/icon/close-blue.svg';
import DeviceDetailsStatusCard from './DeviceDetailsStatusCard';

import { sendGAEvent } from '../../hooks/useGAEvent';
import useLocale, { LOCALE_CODE } from '../../hooks/useLocale';

type Props = {
  imei: string;
  editDisable?: boolean;
  lostModeView?: boolean;
  lostModeEdit?: boolean;
  factoryResetView?: boolean;
  factoryResetEdit?: boolean;
};

const DeviceDetailsCard = React.memo<Props>(
  ({
    imei,
    editDisable,
    lostModeView,
    lostModeEdit,
    factoryResetView,
    factoryResetEdit,
  }): JSX.Element => {
    const classes = useStyles();
    const { accountInfo } = useAccountInfo();
    const [tooltipEnabledDeviceName, setTooltipEnabledDeviceName] =
      useState(false);

    const {
      isCallingApi,
      isCallingUpdateApi,
      t,
      simInfo,
      deviceDetailError,
      // onClickDeviceDeregister,
      editDeviceNameValue,
      targetImei,
      isCallingUpdatePinApi,
      pinUpdateResultCode,
      onClickUpdatePin,
      onClickLostModeChange,
      factoryReset,
      setFactoryReset,
    } = useDeviceDetail(imei);

    // バリデーションフォーム作成
    const {
      handleSubmit,
      formState: { errors },
      control,
      setValue,
      reset,
      watch,
      clearErrors,
    } = useForm<DeviceDetailInput>({
      criteriaMode: 'all', // 全てのエラーを返す
      shouldFocusError: false, // エラーが出た部品にフォーカスをあてない
      mode: 'onChange' || 'onSubmit',
    });

    const { localeLanguage } = useLocale();

    const validateGroupName = (value: string) => {
      const trimmedValue = value.trim();
      if (trimmedValue.length > 60) {
        return t('deviceInfo.message.maxChar');
      }

      return true;
    };
    const { deviceName } = watch();

    // Set the terminal name currently set in the input field when the editing mode turns ON.
    useEffect(() => {
      if (editDeviceNameValue.editState) {
        setValue('deviceName', simInfo.deviceName);
      }
      // simInfo.deviceNameの変更は検知したくないため無効コメント追加
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editDeviceNameValue.editState, setValue]);

    // Tooltip Handle
    const ref = React.useRef<HTMLSpanElement>(null);
    useEffect(() => {
      const compareSize = () => {
        if (ref.current) {
          if (ref.current.scrollWidth > ref.current.clientWidth) {
            setTooltipEnabledDeviceName(true);
          } else {
            setTooltipEnabledDeviceName(false);
          }
        }
      };
      compareSize();
      window.addEventListener('resize', compareSize);

      return () => window.removeEventListener('resize', compareSize);
    }, [isCallingApi, isCallingUpdateApi]);

    const groupNameRef = React.useRef<HTMLSpanElement>(null);
    const [tooltipEnabledGroupName, setTooltipEnabledGroupName] =
      useState(false);
    useEffect(() => {
      const compareSize = () => {
        if (groupNameRef.current) {
          if (
            groupNameRef.current.scrollWidth > groupNameRef.current.clientWidth
          ) {
            setTooltipEnabledGroupName(true);
          } else {
            setTooltipEnabledGroupName(false);
          }
        }
      };
      compareSize();
      window.addEventListener('resize', compareSize);

      return () => window.removeEventListener('resize', compareSize);
    }, [isCallingApi]);
    // End Tooltip Handle

    const formatSimExpDate = (simExpDate?: string): string => {
      if (!simExpDate) {
        return '-';
      }

      const year = parseInt(simExpDate.split('-')[0], 10);
      if (year >= 9999) {
        return t('deviceInfo.label.unlimitedExpire');
      }

      return dayjs(simExpDate).utc().format('YYYY/MM/DD HH:mm');
    };

    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
        }}
        className="device-details"
      >
        <Box className={`${classes.detailsCard} device-details-card`}>
          <Box
            className={`${classes.deviceDetailsLeftCard} device-details-item`}
          >
            <Box
              sx={{ marginBottom: '40px' }}
              className="device-details-item-box"
            >
              <Typography className={classes.itemName}>
                {t('deviceInfo.label.imei')}
              </Typography>
              {isCallingApi ? (
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{ p: '0px', width: '117px', height: '20px' }}
                />
              ) : (
                <Typography className={classes.itemData}>
                  {simInfo.imei}
                </Typography>
              )}
            </Box>
            <Box
              sx={{ marginBottom: '40px' }}
              className="device-details-item-box"
            >
              <Typography className={classes.itemName}>
                {t('deviceInfo.label.expire')}
              </Typography>
              {isCallingApi ? (
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{ p: '0px', width: '92px', height: '20px' }}
                />
              ) : (
                <Typography className={classes.itemData}>
                  {/* {simInfo.expire} */}
                  {formatSimExpDate(simInfo.simExpDate)}
                </Typography>
              )}
            </Box>
            <Box className="device-details-item-box">
              <Typography className={classes.itemName}>
                {t('deviceInfo.label.softwareVersion')}
              </Typography>
              {isCallingApi ? (
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{ p: '0px', width: '92px', height: '20px' }}
                />
              ) : (
                <Typography className={classes.itemData}>
                  {simInfo.softwareVersion ? simInfo.softwareVersion : '-'}
                </Typography>
              )}
            </Box>
          </Box>
          <Box
            className={`${classes.deviceDetailsLeftCardFirstSec} device-details-item pl-sm-30`}
          >
            <Box
              sx={{
                marginBottom: '31px',
              }}
              className="device-details-item-box"
            >
              <Typography className={classes.itemName}>
                {t('deviceInfo.label.deviceName')}
              </Typography>
              {!editDeviceNameValue.editState ? (
                <Box
                  sx={{
                    display: 'flex',
                    height: '30px',
                    gap: simInfo.deviceName ? '16px' : '0px',
                    '@media screen and (max-width:1300px)': {
                      gap: simInfo.deviceName ? '8px' : '0px',
                    },
                  }}
                >
                  <Tooltip
                    placement="top"
                    title={simInfo.deviceName}
                    disableHoverListener={!tooltipEnabledDeviceName}
                    arrow
                    sx={{ marginTop: 0 }}
                    PopperProps={{
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [-7, -7],
                          },
                        },
                      ],
                    }}
                    componentsProps={{
                      tooltip: {
                        className: classes.tooltipStyle,
                      },
                    }}
                  >
                    {isCallingApi ? (
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{
                          p: '0px',
                          width: '92px',
                          height: '20px',
                          marginRight: '16px',
                        }}
                      />
                    ) : (
                      <Typography
                        ref={ref}
                        sx={{
                          overflow: tooltipEnabledDeviceName
                            ? 'hidden'
                            : 'visible',
                        }}
                        className={classes.deviceDetailsDeviceName}
                      >
                        {simInfo.deviceName}
                      </Typography>
                    )}
                  </Tooltip>
                  {isCallingApi ? (
                    <Skeleton
                      variant="text"
                      animation="wave"
                      sx={{
                        p: '0px',
                        width: '30px',
                        height: '30px',
                        marginTop: '-5px',
                      }}
                    />
                  ) : (
                    !editDisable && (
                      <Box
                        sx={{
                          width: '30px',
                          height: '30px',
                          marginTop: '-5px',
                        }}
                      >
                        <Tooltip
                          title={t('common.tooltip.editName')}
                          arrow
                          sx={{ marginTop: 0 }}
                          PopperProps={{
                            modifiers: [
                              {
                                name: 'offset',
                                options: {
                                  offset: [0, -7],
                                },
                              },
                            ],
                          }}
                          componentsProps={{
                            tooltip: {
                              className: classes.iconTooltipStyle,
                            },
                          }}
                        >
                          <Button
                            id="editButton"
                            variant="contained"
                            className={classes.deviceNameEditButton}
                            onClick={() => {
                              editDeviceNameValue.onClickEditButton();
                            }}
                            hidden={
                              !accountInfo.featurePermissions?.DEVICEMANAGEMENT?.DEVICENAME?.includes(
                                FEATURE_PERMISSION.DEVICEMANAGEMENT.DEVICENAME
                                  .EDIT,
                              )
                            }
                            disabled={
                              deviceDetailError.deviceInfoErrorType !== null ||
                              !targetImei
                            }
                          >
                            <EditPenIcon
                              style={{ width: '14.638', height: '14.638' }}
                            />
                          </Button>
                        </Tooltip>
                      </Box>
                    )
                  )}
                </Box>
              ) : (
                <ClickAwayListener onClickAway={() => clearErrors()}>
                  <form
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onSubmit={handleSubmit(
                      editDeviceNameValue.onClickEditSaveButton,
                    )}
                  >
                    <Box className="device-name-input-group">
                      <Controller
                        control={control}
                        name="deviceName"
                        rules={{
                          required: true,
                          validate: validateGroupName,
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            type="text"
                            value={field.value || ''}
                            margin="normal"
                            placeholder={t('deviceInfo.label.placeholder')}
                            error={Boolean(errors.deviceName)}
                            inputProps={{ maxLength: 61 }}
                            className={localeLanguage === LOCALE_CODE.ES ? clsx(classes.editInput, classes.editInputEsSmallFont): classes.editInput}
                            autoFocus
                            autoComplete="off"
                            disabled={isCallingUpdateApi}
                            sx={{
                              '&:hover .MuiOutlinedInput-notchedOutline': {
                                border: errors.deviceName
                                  ? '0.5px solid #E1341E !important'
                                  : '0.5px solid #939598 !important',
                              },
                              '& .MuiOutlinedInput-root': {
                                borderRadius: '6px',
                                '&.Mui-focused fieldset': {
                                  border: errors.deviceName
                                    ? '0.5px solid #E1341E !important'
                                    : '0.5px solid #313133 !important',
                                },
                              },
                            }}
                          />
                        )}
                      />
                      {errors.deviceName && (
                        <Box className={classes.deviceNameError}>
                          <p style={{ padding: '0', margin: '0' }}>
                            {errors.deviceName?.types?.required &&
                              t('deviceInfo.label.emptyMessage')}
                            {errors.deviceName && errors.deviceName.message}
                          </p>
                        </Box>
                      )}
                      <Box className="name-btns">
                        {errors.deviceName ||
                        deviceName?.length === undefined ||
                        deviceName?.trim() === simInfo.deviceName ||
                        deviceName?.trim().length === 0 ||
                        deviceName?.trim().length > 60 ? (
                          <IconButton
                            className={clsx(
                              classes.nameEditSaveIconBtn,
                              classes.deviceDetailsIconBtn,
                            )}
                            disabled
                          >
                            <SaveEditIcon />
                          </IconButton>
                        ) : (
                          <Tooltip
                            PopperProps={{
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: {
                                    offset: [0, -7.5],
                                  },
                                },
                              ],
                            }}
                            componentsProps={{
                              tooltip: {
                                className: classes.iconTooltipStyle,
                              },
                            }}
                            title={t('common.tooltip.save')}
                            arrow
                          >
                            <IconButton
                              className={clsx(
                                classes.nameEditSaveIconBtn,
                                classes.deviceDetailsIconBtn,
                              )}
                              sx={{
                                padding: isCallingUpdateApi
                                  ? '0px !important'
                                  : '6px',
                              }}
                              type="submit"
                              disabled={isCallingUpdateApi}
                            >
                              {!isCallingUpdateApi ? (
                                <SaveEditIcon />
                              ) : (
                                <Loader colorCode="#939598" />
                              )}
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip
                          PopperProps={{
                            modifiers: [
                              {
                                name: 'offset',
                                options: {
                                  offset: [0, -7.5],
                                },
                              },
                            ],
                          }}
                          componentsProps={{
                            tooltip: {
                              className: classes.iconTooltipStyle,
                            },
                          }}
                          title={t('common.tooltip.cancel')}
                          arrow
                        >
                          <IconButton
                            className={clsx(
                              classes.closeIconBtn,
                              classes.deviceDetailsIconBtn,
                            )}
                            aria-label="delete"
                            onClick={() => {
                              editDeviceNameValue.onClickEditCancelButton();
                              reset(); // submit数およびエラー状態をリセット
                              // GA tag
                              sendGAEvent(
                                'press_btn',
                                'btn_name',
                                'cancel_device_name',
                              );
                            }}
                            disabled={isCallingUpdateApi}
                          >
                            <Close />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </form>
                </ClickAwayListener>
              )}
            </Box>
            <Box className="device-details-item-box">
              <Typography className={classes.itemName}>
                {t('deviceInfo.label.lastTranslation')}
              </Typography>
              {isCallingApi ? (
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{ p: '0px', width: '92px', height: '20px' }}
                />
              ) : (
                <Typography className={classes.itemData}>
                  {simInfo.lastUsed
                    ? dayjs(simInfo.lastUsed).utc().format('YYYY/MM/DD HH:mm')
                    : '-'}
                </Typography>
              )}
            </Box>
          </Box>
          <Box className="device-details-item">
            <Box
              sx={{ marginBottom: '40px' }}
              className="device-details-item-box"
            >
              <Typography className={classes.itemName}>
                {t('deviceInfo.label.productType')}
              </Typography>
              {isCallingApi ? (
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{ p: '0px', width: '92px', height: '20px' }}
                />
              ) : (
                <Typography className={classes.itemData}>
                  {simInfo.deviceModel ? simInfo.deviceModel : '-'}
                </Typography>
              )}
            </Box>
            <Box className="device-details-item-box">
              <Typography className={classes.itemName}>
                {t('deviceInfo.label.groupName')}
              </Typography>
              {isCallingApi ? (
                <Skeleton
                  variant="text"
                  animation="wave"
                  sx={{ p: '0px', width: '92px', height: '20px' }}
                />
              ) : (
                <Tooltip
                  placement="top"
                  title={simInfo.groupName}
                  disableHoverListener={!tooltipEnabledGroupName}
                  arrow
                  sx={{ marginTop: 0 }}
                  PopperProps={{
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [-7, -7],
                        },
                      },
                    ],
                  }}
                  componentsProps={{
                    tooltip: {
                      className: classes.tooltipStyle,
                    },
                  }}
                >
                  <Typography
                    ref={groupNameRef}
                    className={classes.deviceDetailsGroupName}
                    sx={{
                      overflow: tooltipEnabledGroupName ? 'hidden' : 'visible',
                    }}
                  >
                    {simInfo.groupName}
                  </Typography>
                </Tooltip>
              )}
            </Box>
          </Box>
        </Box>
        <DeviceDetailsStatusCard
          imei={simInfo.imei}
          lostModeStatus={simInfo.lostMode}
          saveHistoryStatus={simInfo.saveHistory}
          onClickUpdatePin={onClickUpdatePin}
          isCallingUpdatePinApi={isCallingUpdatePinApi}
          pinUpdateResultCode={pinUpdateResultCode}
          onClickLostModeChange={onClickLostModeChange}
          isCallingDetailsApi={isCallingApi}
          modeName={simInfo.modeName}
          modeStatus={simInfo.modeStatus}
          statusColor={simInfo.statusColor}
          factoryReset={factoryReset}
          setFactoryReset={setFactoryReset}
          lostModeDt={simInfo.lostModeDt}
          // featureLimitDt={simInfo.featureLimitDt}
          factoryResetDt={simInfo.factoryResetDt}
          lostModeEdit={lostModeEdit}
          lostModeView={lostModeView}
          factoryResetView={factoryResetView}
          factoryResetEdit={factoryResetEdit}
          productModel={simInfo.deviceModel}
        />
      </Box>
    );
  },
);

export default DeviceDetailsCard;

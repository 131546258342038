/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable prefer-template */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useMemo, useState, useCallback } from 'react';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import {
  API_URL,
  CSV_DOWNLOAD_KEY,
  DATE_FORMAT,
  FEATURE_PERMISSION,
  SERVER_MESSAGE,
} from '../constants/constants';
import callExportLogDataApi from '../apis/callExportLogDataApi';
import useAccountInfo from './useAccountInfo';
import useDateRange, { UseDateRangeValue } from './useDateRange';
import {
  convertStrToDateyyyyMM01T00,
  fetchTodayUTCyyyyMM01T00,
} from '../utils/dateUtil';
import {
  formatDownloadDate,
  validateDownloadDate,
  createDonwloadErrorMessage,
} from '../utils/donwload/downloadDateUtil';
import {
  DownloadDate,
  DOWNLOAD_DATE_ERROR_TYPE,
} from '../types/download/downloadDate';
import useSnackbar from './useSnackbar';
import callExportLogDataTerminateApi from '../apis/callExportLogDataTerminateApi';
import errorMessageCodes from '../constants/errorMessageCodes';

/**
 * 本カスタムフックからの返却値
 */
export type UseLogDataDownloadDialogValue = {
  // ログデータダウンロードダイアログを表示するか否か(true=表示)
  isLogDataDonwloadDialogDisplay: boolean;
  isProgreessDialogDisplay: boolean;
  isCancelDialogDisplay: boolean;
  exportUniqueId: string | null;
  progress: number;
  totalRows: number;
  rows: any;
  // ログデータダウンロードダイアログを表示
  openCancelDialog: () => void;
  openLogDataDownloadDialog: () => void;
  // ログデータダウンロードダイアログを非表示
  closeLogDataDownloadDialog: () => void;
  closeProgressDialog: () => void;
  closeCancelDialog: () => void;
  onClickProgressBarCanelButton: () => void;
  // ダウンロード処理
  onClickDownload: () => void;
  // 日付範囲に関する値
  dateRangeValue: UseDateRangeValue;
  // ダウンロード日付選択に関する値
  downloadDate: DownloadDate;
  // エラータイプ
  alertType: DOWNLOAD_DATE_ERROR_TYPE | null;
  // エラーメッセージ
  errorMessage: string;
  // お知らせポップアップを非表示にする
  onClickCloseNoticeDialog: () => void;
  // お知らせポップアップを表示するか否か
  isNoticeDialogDisplay: boolean;
  isCallingReportApi: boolean;
  isCallingTerminateApi: boolean;
};

type reponseType = {
  message: string;
};

/**
 * ログデータダウンロードダイアログ hooks
 *
 * @returns
 */
const useLogDataDownloadDialog = (): UseLogDataDownloadDialogValue => {
  // アクセスキー
  // アカウント情報
  const { accountInfo } = useAccountInfo();
  const { displaySnackbar } = useSnackbar();

  // datePicker
  const defaultDate = fetchTodayUTCyyyyMM01T00();
  const { startDate, endDate, changeStartDate, changeEndDate } = useDateRange(
    defaultDate,
    defaultDate,
  );
  // ログデータダウンロードダイアログを表示するか否か(true=表示) get/set
  const [isLogDataDonwloadDialogDisplay, setIsLogDataDonwloadDialogDisplay] =
    useState<boolean>(false);
  const [isProgreessDialogDisplay, setIsProgreessDialogDisplay] =
    useState<boolean>(false);
  const [isCancelDialogDisplay, setIsCancelDialogDisplay] =
    useState<boolean>(false);
  const [isCallingReportApi, setIsCallingReportApi] = useState<boolean>(false);
  const [isCallingTerminateApi, setIsCallingTerminateApi] = useState<boolean>(false);
  const [isCancelDownload, setIsCancelDownload] = useState<boolean>(false);
  const [exportUniqueId, setExportUniqueId] = useState<string | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [rows, setRows] = useState([]);

  // お知らせポップアップを表示するか否か
  const [isNoticeDialogDisplay, setIsNoticeDialogDisplay] =
    useState<boolean>(false);

  // アラート状態
  const [alertType, setAlertType] = useState<DOWNLOAD_DATE_ERROR_TYPE | null>(
    null,
  );

  // 言語切り替えhooks(エラーメッセージ返すときに日本語/英語で出し分ける時に使う)
  const { t } = useSwitchLocaleLanguage();

  // Smallest value that can be selected (corporate registration date and time)
  const minDate = useMemo(
    (): Date => convertStrToDateyyyyMM01T00(accountInfo.corpCreatedDt),
    [accountInfo.corpCreatedDt],
  );
  // Maximum value that can be selected (currently month)
  const maxDate = useMemo(
    (): Date => fetchTodayUTCyyyyMM01T00(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLogDataDonwloadDialogDisplay],
  );

  /**
   * エラーメッセージ
   */
  const errorMessage = useMemo((): string => {
    if (!alertType) {
      return '';
    }

    return createDonwloadErrorMessage(
      alertType,
      t,
      DATE_FORMAT,
      minDate,
      maxDate,
    );
  }, [alertType, maxDate, minDate, t]);

  /**
   * ログデータダウンロードダイアログを表示
   */
  const openLogDataDownloadDialog = (): void => {
    if (
      accountInfo.featurePermissions?.REPORTING?.LOGDATA?.includes(
        FEATURE_PERMISSION.REPORTING.LOGDATA.EXPORT,
      )
    ) {
      setIsLogDataDonwloadDialogDisplay(true);
    } else {
      setIsLogDataDonwloadDialogDisplay(false);
      displaySnackbar({
        message: t('common.error.accessDenied'),
        timeout: 3005,
        type: 'error',
      });
    }
  };

  const openCancelDialog = (): void => {
    setIsCancelDialogDisplay(true);
  };

  /**
   * ログデータダウンロードダイアログを非表示
   */
  const changeExportUniqueId = (value: string | null): void => {
    setExportUniqueId(value);
  };

  const changeTotalRows = (value: number): void => {
    setTotalRows(value);
  };

  const changeRows = (value: any, total: number): void => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setRows(value);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    setProgress((value.length / total) * 100);
  };

  const closeLogDataDownloadDialog = (): void => {
    setIsLogDataDonwloadDialogDisplay(false);
  };

  const closeProgressDialog = (): void => {
    setIsProgreessDialogDisplay(false);
  };

  const closeCancelDialog = (isPositionButton?: boolean): void => {
    if (isPositionButton && exportUniqueId !== null && exportUniqueId !== '') {
      setIsCallingTerminateApi(true);
      void callExportLogDataTerminateApi({
        exportUniqueId: exportUniqueId !== null ? exportUniqueId : '',
      })
        .then((response: any) => {
          if (response === SERVER_MESSAGE.NO_INTERNET) {
            return;
          }
          const responseFail = response as reponseType;
          if (
            responseFail?.message === SERVER_MESSAGE.ACCESS_PERMISSION_DENIED
          ) {
            displaySnackbar({
              message: t('exportLogDataProgress.failedMessage'),
              timeout: 3005,
              type: 'error',
            });
          }
        })
        .catch((error) => {
          displaySnackbar({
            message: t('common.error.serverErr') + errorMessageCodes['common.error.serverErr'],
            timeout: 3005,
            type: 'error',
          });
          console.log(error, 'download error');
          setIsCallingTerminateApi(false);
        })
    }else{
      setIsCancelDialogDisplay(false);
      setIsCallingTerminateApi(false);
    }
  };

  /**
   * ダイアログの表示非表示の切り替え時にエラーメッセージを非表示にし、
   * minDate、maxDateの設定をリセットする
   */
  useEffect(() => {
    if (!isLogDataDonwloadDialogDisplay) {
      setAlertType(null);
      // Return the date on which the dialog is selected to the initial display
      const initDefaultDate = fetchTodayUTCyyyyMM01T00();
      changeStartDate(initDefaultDate);
      changeEndDate(initDefaultDate);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogDataDonwloadDialogDisplay]);

  /**
   * 日付バリデーションチェック
   *
   * @returns true=OK / false=バリデーションエラー
   */
  const validateDate = useCallback((): boolean => {
    // エラー文言非表示
    setAlertType(null);

    // 入力値バリデーションチェック
    const result: DOWNLOAD_DATE_ERROR_TYPE | null = validateDownloadDate(
      startDate,
      endDate,
      minDate,
      maxDate,
      DATE_FORMAT,
    );
    if (result !== null) {
      // バリデーションエラー
      setAlertType(result);

      return false;
    }

    return true;
  }, [startDate, endDate, minDate, maxDate]);

  /**
   * 期間開始日または期間終了日が変更された時点で、
   * 日付バリデーションチェック実行
   */
  useEffect(() => {
    validateDate();
  }, [validateDate]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function downloadLargeArray(cleanedResponse: string) {
    const CHUNK_SIZE = 10000; // Adjust chunk size as needed

    // Clean and prepare data
    const tempRows = cleanedResponse
      .trim()
      .split('\n') // Split by newline to get each row
      .map((row) => row.trim()); // Remove any extra spaces

    // Create a ReadableStream to generate data on-the-fly
    const readableStream = new ReadableStream({
      start(controller) {
        const encoder = new TextEncoder();
        const bom = encoder.encode('\uFEFF'); // UTF-8 BOM
        controller.enqueue(bom); // Enqueue BOM at the start

        let index = 0;

        function pushChunk() {
          if (index < tempRows.length) {
            const chunk = tempRows.slice(index, index + CHUNK_SIZE).join('\n'); // Get the current chunk
            controller.enqueue(encoder.encode(chunk + '\n')); // Encode and enqueue the chunk
            index += CHUNK_SIZE; // Move to the next chunk
            setTimeout(pushChunk, 0); // Allow browser to process between chunks
          } else {
            controller.close(); // Close the stream when done
          }
        }

        pushChunk();
      },
    });

    // Convert the stream to a Blob using Response
    const response = new Response(readableStream);
    void response.blob().then((blob) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', 'LogData.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });

    setIsNoticeDialogDisplay(false);
    closeLogDataDownloadDialog();
    displaySnackbar({
      message: t('exportLogDataProgress.successMessage'),
      timeout: 3005,
    });

    closeProgressDialog();
    closeCancelDialog();
    setIsCallingTerminateApi(false);
    setProgress(0);
    setTotalRows(0);
    setRows([]);
    setExportUniqueId(null);
  }

  /**
   * ログデータダウンロード処理
   */
  const onClickDownload = () => {
    setProgress(0);
    setIsProgreessDialogDisplay(true);
    setIsLogDataDonwloadDialogDisplay(false);
    setIsCancelDownload(false);

    setIsCallingReportApi(true);
    // 期間が変更された時点で日付バリエーションチェックが行われているが、
    // フォーカス外れるとエラーが表示されたままダウンロード可能なのでここでもチェックする
    if (!validateDate()) {
      return;
    }

    // すでに上でバリデーションチェックを行っているためnullになることはありえないが、Date型を確定させるために処理入れる
    if (startDate == null || endDate == null) {
      return;
    }
    // ドメイン

    void callExportLogDataApi({
      corpId: accountInfo.corpId,
      from: formatDownloadDate(startDate)?.slice(0, 6),
      to: formatDownloadDate(endDate)?.slice(0, 6),
      setExportUniqueId: changeExportUniqueId,
      setTotalRows: changeTotalRows,
      setRows: changeRows,
    })
      .then((response: any) => {

        if (response === SERVER_MESSAGE.NO_INTERNET) {
          closeProgressDialog();
          closeCancelDialog();
          setIsCallingTerminateApi(false);

          displaySnackbar({
            message: t('common.error.somethingWentWrong') + errorMessageCodes['common.error.somethingWentWrong'],
            timeout: 3005,
            type: 'error',
          });

          return;
        }
        const responseFail = response as reponseType;
        if (responseFail?.message === SERVER_MESSAGE.ACCESS_PERMISSION_DENIED) {
          displaySnackbar({
            message: t('exportLogDataProgress.failedMessage'),
            timeout: 3005,
            type: 'error',
          });

          closeProgressDialog();
          closeCancelDialog();
          setIsCallingTerminateApi(false);
        }
        if (responseFail?.message) {
          setIsNoticeDialogDisplay(false);
          closeLogDataDownloadDialog();
          closeProgressDialog();
          closeCancelDialog();
          setIsCallingTerminateApi(false);

          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        if (response.trim().endsWith("[INTERRUPTED]")) {
          closeProgressDialog();
          closeCancelDialog();
          setProgress(0);
          setTotalRows(0);
          setRows([]);
          setExportUniqueId(null);
          setIsCancelDownload(false);
          setIsCallingTerminateApi(false);
          setIsProgreessDialogDisplay(false);

          displaySnackbar({
            message: t('exportLogDataProgress.cancelDialog.cancelMessage'),
            timeout: 3005,
            type: 'error',
          });

          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        if (!response.trim().endsWith("[COMPLETE]")) {
          closeProgressDialog();
          closeCancelDialog();
          setIsCallingTerminateApi(false);
          setProgress(0);
          setTotalRows(0);
          setRows([]);
          setExportUniqueId(null);

          displaySnackbar({
            message: t('exportLogDataProgress.failedMessage'),
            timeout: 3005,
            type: 'error',
          });

          return;
        }

        if(isCancelDownload){
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        const cleanedResponse = response
          .replace(
            /\[START\]\[TOTAL_ROW=\d+\]\[EXPORT_UNIQUE_ID=[a-zA-Z0-9-]+\]/,
            '',
          ) // Remove the START line
          .replace(/\[COMPLETE\]/, ''); // Remove the COMPLETE marker

        setTimeout(()=> {
          //
        }, 500);

        downloadLargeArray(cleanedResponse);
      })
      .catch((error) => {
        closeLogDataDownloadDialog();
        closeProgressDialog();
        closeCancelDialog();
        setIsCallingTerminateApi(false);
        setIsNoticeDialogDisplay(false);
        setProgress(0);
        setTotalRows(0);
        setRows([]);
        setExportUniqueId(null);
        displaySnackbar({
          message: t('common.error.serverErr') + errorMessageCodes['common.error.serverErr'],
          timeout: 3005,
          type: 'error',
        });
        setIsCallingReportApi(false);
        console.log(error, 'download error');
      })
      .finally(() => setIsCallingReportApi(false));
  };

  const onClickProgressBarCanelButton = () => {
    //
  };

  /**
   * お知らせポップアップを非表示にする
   */
  const onClickCloseNoticeDialog = () => {
    setIsNoticeDialogDisplay(false);
    closeLogDataDownloadDialog();
  };

  return {
    isLogDataDonwloadDialogDisplay,
    isProgreessDialogDisplay,
    isCancelDialogDisplay,
    exportUniqueId,
    progress,
    totalRows,
    rows,
    openCancelDialog,
    openLogDataDownloadDialog,
    closeLogDataDownloadDialog,
    closeProgressDialog,
    closeCancelDialog,
    onClickProgressBarCanelButton,
    onClickDownload,
    dateRangeValue: {
      startDate,
      endDate,
      changeStartDate,
      changeEndDate,
    },
    downloadDate: {
      minDate,
      maxDate,
    },
    alertType,
    errorMessage,
    onClickCloseNoticeDialog,
    isNoticeDialogDisplay,
    isCallingReportApi,
    isCallingTerminateApi
  };
};

export default useLogDataDownloadDialog;

import {
  useRef,
  useState,
  useMemo,
  useCallback,
  useEffect,
  SetStateAction,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TFunction } from 'react-i18next';
import useFilter from './useFilter';
import usePagination from './usePagination';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import useCheckTransition from './useCheckTransition';
import {
  API_CALL_STATE,
  DIRECTION_TYPE,
  DisplayContentState,
  FILTERING_STATE,
  SortConfig,
  TableHeaderData,
  TableRowData,
  USER_LIST_TABLE_DATA_KEY,
} from '../types/datatable/dataTable.d';
import { FilterInput } from '../types/inputs/filterInput.d';
import { UserInfo } from '../types/apis/userListApi.d';
import {
  UserInfoProsessResultState,
  UserListResult,
  UserListTransState,
  USER_INFO_PROSESS_RESULT_CODE,
  USER_INFO_PROSESS_RESULT_TYPE,
  USER_WIDTH_ARRAY,
} from '../types/user/userList.d';
import {
  convertUserListArrayToTableDataArray,
  updateSortConfig,
} from '../utils/datatable/dataTableUtil';
import {
  fetchUserInfoProcessResultFromState,
  fetchUserList,
} from '../utils/user/userListUtil';
import {
  ALL_ERROR_TYPE,
  PAGE_PATH_NAME,
  TABLE_ROW_NUMBER_PER_PAGE,
 SERVER_MESSAGE } from '../constants/constants';
import {
  createTotalPageNumber,
  isUserListCompleteStateType,
} from '../utils/utility';
import { UserDetailState } from '../types/user/userDetail.d';
import { UserAddState } from '../types/user/userAdd.d';
import useAccountInfo from './useAccountInfo';
import useSnackbar from './useSnackbar';
import errorMessageCodes from '../constants/errorMessageCodes';
/**
 * ソート情報
 */
export type SortValue = {
  // ソート設定
  sortConfig: SortConfig;
  // テーブルの[ソート]ボタンがクリックされた場合の処理
  onClickSortButton: (data: TableHeaderData) => void;
};

/**
 * フィルタリング情報
 */
export type FilterValue = {
  // フィルタリングの[検索]ボタンがクリックされた
  onClickFilterSearch: (inputData: FilterInput) => void;
  // 入力中の検索ワード
  filterInputData: FilterInput;
};

/**
 * ページネーション情報
 */
export type PaginationValue = {
  // 表示中のページ番号
  currentPage: number;
  // ページ総数
  totalPages: number;
  // ページネーションの[前へ]ボタンがクリックされた場合の処理
  onClickPrevPage: () => void;
  // ページネーションの[次へ]ボタンがクリックされた場合の処理
  onClickNextPage: () => void;
  // 選択中の1ページごとの表示件数
  currentRowNumberPerPage: number;
  // 表示件数が変更された場合の処理
  onChangeRowNumber: (selectedRowNumber: number) => void;
  onClickFirstPage: () => void;
  onClickLastPage: () => void;
  pageDataIndexFrom: number;
  pageDataIndexTo: number;
};

/**
 * ユーザー一覧取得に関するエラー情報
 */
export type UserListError = {
  // ユーザー一覧取得エラータイプ
  errorType: ALL_ERROR_TYPE | null;
  // エラーメッセージ
  errorMessage: string;
  // アクセスキー不正エラーダイアログの［ログアウト］ボタンクリック処理
  onClickLogoutButton: () => void;
};

/**
 * 本カスタムフックからの返却値
 */
export type UseUserListValue = {
  // ユーザー一覧取得に関するエラー情報
  userListError: UserListError;
  // APIコール中か否か(true=コール中)
  isCallingApi: boolean;
  // テーブルヘッダーに表示する内容
  headerColumns: TableHeaderData[];
  // 表示中のページに表示するテーブル内容
  currentPageDataRows: TableRowData[];
  // 一覧総数
  totalListCount: number;
  // ソート情報
  sortValue: SortValue;
  // フィルタリング情報
  filterValue: FilterValue;
  // ページネーション情報
  paginationValue: PaginationValue;
  // [ユーザー追加]ボタンがクリックされた場合の処理
  onClickAddUserButton: () => void;
  // メール送信成功メッセージ、ユーザー情報更新成功メッセージ
  successMessage: string;
  // メールアドレスリンクがクリックされた場合の処理
  onClickMailAddressLink: (email: string, accountStatus: string) => void;
  callUserListApi: () => void;
  displayContentState: DisplayContentState;
  setDisplayContentState: (value: SetStateAction<DisplayContentState>) => void;
  t: TFunction;
};
/**
 * ユーザー管理画面 hooks
 */
const useUserList = (): UseUserListValue => {
  const { accountInfo } = useAccountInfo();
  // ユーザー一覧取得APIから取得した一覧 get/set
  const originalUserInfos = useRef<UserInfo[]>([]);
  const totalUserCount = useRef<number>(0);
  // フィルタリング用一覧
  // const filterUserInfos = useRef<UserInfo[]>([]);

  // テーブルに表示中のページデータ get/set
  const [currentPageDataRows, setCurrentPageDataRows] = useState<
    TableRowData[]
  >([]);

  // ユーザー一覧取得エラータイプ get/set
  const [errorType, setErrorType] = useState<ALL_ERROR_TYPE | null>(null);
  // APIコール中か否か(true=コール中)
  const [isCallingApi, setIsCallingApi] = useState<boolean>(false);

  // 一覧に表示中のデータ総数(ユーザー件数の総数, フィルタリング結果数) get/set
  const [totalListCount, setTotalListCount] = useState<number>(0);
  // ソート状態 get/set
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: '',
    direction: DIRECTION_TYPE.none,
  });

  const stopFirstApiCall = useRef(true);

  // アクセスキーhooks
  // 言語切り替えhooks
  const { t } = useSwitchLocaleLanguage();
  const { displaySnackbar } = useSnackbar();

  // 画面遷移時に渡されたstateの取得
  const location = useLocation();
  // 画面遷移制御hooks
  const { allowTransition } = useCheckTransition();
  // navigate(画面遷移)
  const navigate = useNavigate();

  // フィルターhooks
  const { onClickFilterSearch, filterInputData } = useFilter('');
  // ページネーション hooks
  const {
    currentPage,
    totalPages,
    onClickPrevPage,
    onClickNextPage,
    currentRowNumberPerPage,
    onChangeRowNumber,
    changeTotalPages,
    changeCurrentPage,
    onClickFirstPage,
    onClickLastPage,
  } = usePagination(TABLE_ROW_NUMBER_PER_PAGE[2], 1);

  // 画面の表示状態を管理
  const [displayContentState, setDisplayContentState] =
    useState<DisplayContentState>({
      apiCallState: API_CALL_STATE.pending, // API呼び出し状態(呼び出し前)
      filteringState: FILTERING_STATE.inactive, // フィルタリング適用状態(フィルタリング解除)
    });
  // 初回レンダリングか否か(true=初回レンダリング)
  const isFirstRenderingRef = useRef(true);
  // 本画面が破棄されたか否か(true=破棄された)
  // 他画面に遷移した場合は破棄されたとみなす
  const isDiscardUserListView = useRef<boolean>(false);
  // 成功メッセージ保管用Ref
  const storageSuccessMessageRef = useRef<string>('');
  // メール送信成功メッセージ、ユーザー情報更新成功メッセージ
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [pageDataIndexFrom, setPageDataIndexFrom] = useState<number>(0);
  const [pageDataIndexTo, setPageDataIndexTo] = useState<number>(1);

  /**
   * テーブルのヘッダーに表示する内容
   */
  const headerColumns = useMemo(
    (): TableHeaderData[] => [
      {
        key: USER_LIST_TABLE_DATA_KEY.USER_NAME,
        value: t('userList.header.userName'),
        isSort: true,
        width: USER_WIDTH_ARRAY[0],
      },
      {
        key: USER_LIST_TABLE_DATA_KEY.MAIL_ADDRESS,
        value: t('userList.header.email'),
        isSort: true,
        width: USER_WIDTH_ARRAY[1],
      },
      {
        key: USER_LIST_TABLE_DATA_KEY.GROUP_NAME,
        value: t('userList.header.group'),
        isSort: true,
        width: USER_WIDTH_ARRAY[0],
      },
      {
        key: USER_LIST_TABLE_DATA_KEY.ROLE_NAME,
        value: t('userList.header.roleName'),
        isSort: true,
        width: USER_WIDTH_ARRAY[2],
      },
      {
        key: USER_LIST_TABLE_DATA_KEY.DATE_ADDED,
        value: t('userList.header.dateAdded'),
        isSort: true,
        width: USER_WIDTH_ARRAY[0],
      },
      {
        key: USER_LIST_TABLE_DATA_KEY.REG_STATUS,
        value: t('userList.header.status'),
        isSort: true,
        width: USER_WIDTH_ARRAY[0],
      },
    ],
    [t],
  );

  const sortDataKey: { [key: string]: string } = {
    mailAddress: 'mail_address',
    userName: 'user_name',
    dateAdded: 'created_dt',
    roleName: 'role_name',
    registrationStatus: 'status_code',
    groupName: 'group_name',
  };

  /**
   * エラーメッセージ
   */
  const errorMessage = useMemo((): string => {
    if (!errorType) {
      return ''; // エラーコンポーネント自体非表示にする
    }

    switch (errorType) {
      case SERVER_MESSAGE.INVALID_TOKEN:
      case SERVER_MESSAGE.EXPIRED_TOKEN:
      case SERVER_MESSAGE.WARN_INVALID_AUTH:
        return ''; // ログイン認証エラー、権限不正エラー時はアクセスキー不正エラーダイアログを表示するのでエラーメッセージは表示しない
      default:
        return t('userList.userListApiError') + errorMessageCodes['userList.userListApiError.ERR_UNKNOWN'];
    }
  }, [errorType, t]);

  /**
   * ユーザー情報操作結果に対応するメッセージを返す
   */
  const userInfoProcessResultMessage = useCallback(
    (result: USER_INFO_PROSESS_RESULT_TYPE): string => {
      switch (result) {
        case USER_INFO_PROSESS_RESULT_CODE.SEND_MAIL_SUCCESS:
          return t('userList.sendMailSuccess');
        case USER_INFO_PROSESS_RESULT_CODE.UPDATE_SUCCESS:
          return t('userList.updateSuccess');
        case USER_INFO_PROSESS_RESULT_CODE.DELETE_SUCCESS:
          return t('userList.userDeleteSuccess');
        default:
          return '';
      }
    },
    [t],
  );

  /**
   * 各種成功メッセージを表示後、
   * 3秒後にメッセージを非表示にする
   */
  const overTimeHideSuccessMessage = useCallback((message: string) => {
    if (!message) {
      return;
    }
    if (isDiscardUserListView.current) {
      return; // 画面が破棄されていたら何もしない
    }
    setSuccessMessage(message);

    setTimeout(() => {
      if (isDiscardUserListView.current) {
        return; // 画面が破棄されていたら何もしない(ここでもチェックしないと3秒待っている間に画面遷移されると警告が出る)
      }
      setSuccessMessage('');
    }, 3000);
  }, []);

  /**
   * [ソート]ボタンがクリックされた
   */
  const onClickSortButton = useCallback(
    (data: TableHeaderData) => {
      if (!data.isSort || isCallingApi) {
        // ソートが許可されていない列だった場合は何もしない
        return;
      }
      /* eslint-disable */
      // ソート情報作成
      const sortConfigUpdate: SortConfig = updateSortConfig(
        data.key,
        sortConfig,
      );
      changeCurrentPage(1);
      setSortConfig(sortConfigUpdate);
    },
    [sortConfig, changeCurrentPage, isCallingApi],
  );

  /**
   * アクセスキー不正エラーダイアログの［ログアウト］ボタンクリック処理
   */
  const onClickLogoutButton = (): void => {
    // アクセスキー不正エラーダイアログを閉じる(errorTypeがnullで非表示)
    setErrorType(null);
  };

  /**
   * 画面表示データを作成(ユーザ一覧取得API呼び出し成功時)
   * ・ユーザ取得APIから取得した一覧をもとに表示用データ作成
   * ・フィルター適用あり/なしで表示用データ作成
   * ・ソート適用
   */
  const createDisplayDataApiFulfilled = useCallback(() => {
    setPageDataIndexFrom((currentPage - 1) * currentRowNumberPerPage + 1);
    setPageDataIndexTo(
      currentPage * currentRowNumberPerPage > totalUserCount.current
        ? totalUserCount.current
        : currentPage * currentRowNumberPerPage,
    );
    const list: UserInfo[] = originalUserInfos.current;

    // 一覧をもとに表示用データに変換
    const tableRowDataArray: TableRowData[] =
      convertUserListArrayToTableDataArray(list);

    setCurrentPageDataRows(tableRowDataArray); // 表示用データセット
    changeTotalPages(
      createTotalPageNumber(totalUserCount.current, currentRowNumberPerPage),
    ); // ページ総数設定セット
    setTotalListCount(totalUserCount.current); // 件数セット
  }, [changeTotalPages, currentPage, currentRowNumberPerPage]);

  /**
   * ユーザ一覧取得API呼び出し
   */
  const callUserListApi = useCallback(
    () => {
      // loadingBar表示
      setIsCallingApi(true);
      // エラータイプ初期化
      setErrorType(null);
      // ユーザ一覧取得
      fetchUserList(
        currentRowNumberPerPage,
        currentPage - 1,
        sortConfig.direction === DIRECTION_TYPE.none
          ? ''
          : sortConfig.direction.toLocaleLowerCase(),
        filterInputData.searchText,
        sortConfig.key ? sortDataKey[sortConfig.key] : '',
      )
        .then((result: UserListResult) => {
          if (result.message === SERVER_MESSAGE.NO_INTERNET) {
            displaySnackbar({
              message: t('common.error.noInternet'),
              type: 'error',
              timeout: 5000,
            });
            setErrorType(result.message);

            return;
          }
          if (result.message === SERVER_MESSAGE.FETCH_OK) {
            // 成功した場合はAPIから返却された一覧をセット
            originalUserInfos.current = result.details.users;
            totalUserCount.current = result.details.totalUserCount;
            // state更新
            setDisplayContentState({
              ...displayContentState,
              apiCallState: API_CALL_STATE.fulfilled,
            });
          } else if (
            result.message === SERVER_MESSAGE.WARN_INVALID_AUTH ||
            result.message === SERVER_MESSAGE.AUTH_NOT_ALLOWED ||
            result.message === SERVER_MESSAGE.USER_ROLE_CHANGED
          ) {
            setErrorType(result.message);
            setIsCallingApi(false);

            return;
          } else {
            // 失敗した場合は空の一覧をセット
            originalUserInfos.current = [];
            totalUserCount.current = 0;
            setErrorType(result.message);
            // state更新
            setDisplayContentState({
              ...displayContentState,
              apiCallState: API_CALL_STATE.rejected,
            });
          }

          // loadingBar非表示
          setIsCallingApi(false);
          // メッセージを表示
          overTimeHideSuccessMessage(storageSuccessMessageRef.current);
        })
        .finally(() => {
          // 何もしない(finally付けないとエラーになる)
          createDisplayDataApiFulfilled();
          setIsCallingApi(false);
        });
    },

    // displayContentStateはここで検知したくないため無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountInfo.locale],
  );

  /**
   * 画面表示データを作成(ユーザ一覧取得API呼び出し失敗時)
   */
  const createDisplayDataApiRejected = useCallback(() => {
    setCurrentPageDataRows([]); // 表示用データセット
    changeTotalPages(1); // ページ総数設定セット
    setTotalListCount(0); // 件数セット
    changeCurrentPage(1); // 1ページ目を表示

    // changeCurrentPageはここで検知したくないため無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeTotalPages]);

  /**
   * state内のユーザー情報操作結果を取得して成功メッセージがある場合は保管 &&
   * state内のユーザー情報操作結果のみ初期化
   * @returns
   */
  const fetchUserInfoProcessResultAndReset = (): void => {
    if (!isUserListCompleteStateType(location.state)) {
      return;
    }
    // stateからユーザー情報操作結果取得
    const userListTransState: UserListTransState = location.state;
    const userInfoProcessResult: USER_INFO_PROSESS_RESULT_TYPE =
      fetchUserInfoProcessResultFromState(userListTransState.userInfoProcState);
    // 結果が「操作なし」以外の場合は操作結果メッセージ取得して保管
    if (userInfoProcessResult !== USER_INFO_PROSESS_RESULT_CODE.NONE) {
      storageSuccessMessageRef.current = userInfoProcessResultMessage(
        userInfoProcessResult,
      );
    }

    // location.state内のユーザー情報操作結果をリセット(画面リロード後にメッセージが再表示されるのを防ぐため)
    const userInfoProsessResultState: UserInfoProsessResultState = {
      userInfoProcessResult: USER_INFO_PROSESS_RESULT_CODE.NONE,
    };
    userListTransState.userInfoProcState = userInfoProsessResultState;
    navigate({ ...location }, { replace: true, state: userListTransState });
  };

  /**
   * 画面初期表示処理
   */
  useEffect(() => {
    // 画面遷移制御
    if (!allowTransition()) {
      return;
    }

    // state内のユーザー情報操作結果を取得して保管 && state内のユーザー情報操作結果のみ初期化
    fetchUserInfoProcessResultAndReset();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * 画面の状態を管理して表示データを作成
   *
   * 状態
   * ・ユーザ一覧取得API呼び出し状態（前/済/取得失敗）
   *
   * 1.ユーザ一覧取得API呼び出し前
   *   ・ユーザ一覧取得API呼び出し
   * 2.ユーザ一覧取得API呼び出し後
   *   ・フィルター適用あり/なしで表示用データ作成
   *   ・ソート適用
   */
  useEffect(() => {
    // 初回レンダリング時は何もしない(一覧取得が2回行われていたので制御追加)
    if (isFirstRenderingRef.current) {
      isFirstRenderingRef.current = false;

      return;
    }

    // API呼び出し前
    if (displayContentState.apiCallState === API_CALL_STATE.pending) {
      // ユーザ一覧取得API呼び出し
      callUserListApi();
    }
    // API呼び出し成功
    else if (displayContentState.apiCallState === API_CALL_STATE.fulfilled) {
      if (!stopFirstApiCall.current) {
        setIsCallingApi(true);
        setErrorType(null);
        fetchUserList(
          currentRowNumberPerPage,
          currentPage - 1,
          sortConfig.direction === DIRECTION_TYPE.none
            ? ''
            : sortConfig.direction.toLocaleLowerCase(),
          filterInputData.searchText,
          sortConfig.key ? sortDataKey[sortConfig.key] : '',
        )
          .then((result: UserListResult) => {
            if (result.message === SERVER_MESSAGE.NO_INTERNET) {
              displaySnackbar({
                message: t('common.error.somethingWentWrong') + errorMessageCodes['common.error.somethingWentWrong'],
                type: 'error',
                timeout: 5000,
              });
              setErrorType(result.message);

              return;
            }
            if (result.message === SERVER_MESSAGE.FETCH_OK) {
              // 成功した場合はAPIから返却された一覧をセット
              originalUserInfos.current = result.details.users;
              totalUserCount.current = result.details.totalUserCount;
            } else if (
              result.message === SERVER_MESSAGE.WARN_INVALID_AUTH ||
              result.message === SERVER_MESSAGE.AUTH_NOT_ALLOWED
            ) {
              setErrorType(result.message);
              setIsCallingApi(false);

              return;
            } else {
              // 失敗した場合は空の一覧をセット
              originalUserInfos.current = [];
              totalUserCount.current = 0;
              setErrorType(result.message);
            }

            // loadingBar非表示
            setIsCallingApi(false);
            // メッセージを表示
            overTimeHideSuccessMessage(storageSuccessMessageRef.current);
          })
          .finally(() => {
            // 何もしない(finally付けないとエラーになる)
            createDisplayDataApiFulfilled();
            setIsCallingApi(false);
          });
      }
      if (stopFirstApiCall.current) {
        stopFirstApiCall.current = false;
      }
    }
    // API呼び出し失敗
    else {
      // 表示用データ作成(失敗用)
      createDisplayDataApiRejected();
    }
  }, [
    displayContentState,
    currentPage,
    currentRowNumberPerPage,
    sortConfig,
    callUserListApi,
    createDisplayDataApiRejected,
  ]);

  /**
   * フィルタリングが更新された場合の処理
   */
  useEffect(() => {
    // フィルタリング実行直後は1ページ目に移動
    changeCurrentPage(1);

    if (!filterInputData.searchText) {
      // フィルターなし
      // filterUserInfos.current = [];
      // state更新
      setDisplayContentState({
        ...displayContentState,
        filteringState: FILTERING_STATE.inactive,
      });

      return;
    }
    // // 検索文字列
    // // フィルター適用
    // state更新
    setDisplayContentState({
      ...displayContentState,
      filteringState: FILTERING_STATE.active,
    });

    // displayContentStateは検知したくなかったので無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeCurrentPage, filterInputData.searchText]);

  /**
   * [ユーザー追加]ボタンがクリックされた場合の処理
   */
  const onClickAddUserButton = (): void => {
    // 画面破棄
    isDiscardUserListView.current = true;
    // [ユーザー招待メール送信]画面に遷移
    const userAddState: UserAddState = {
      transViewName: PAGE_PATH_NAME.USER_LIST,
    };
    navigate(PAGE_PATH_NAME.USER_ADD, { state: userAddState });
  };

  /**
   * [メールアドレス]リンクがクリックされた場合の処理
   */
  const onClickMailAddressLink = useCallback(
    (email: string, accountStatus: string): void => {
      // 画面破棄
      isDiscardUserListView.current = true;
      // ［ユーザー情報編集］画面に遷移
      const userDetailState: UserDetailState = {
        mailAddress: email,
        accountStatus: accountStatus,
      };
      navigate(PAGE_PATH_NAME.USER_DETAIL, { state: userDetailState });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [],
  );

  return {
    userListError: {
      errorType,
      errorMessage,
      onClickLogoutButton,
    },
    isCallingApi,
    t,
    headerColumns,
    currentPageDataRows,
    totalListCount,
    sortValue: {
      sortConfig,
      onClickSortButton,
    },
    filterValue: {
      onClickFilterSearch,
      filterInputData,
    },
    paginationValue: {
      currentPage,
      totalPages,
      onClickPrevPage,
      onClickNextPage,
      currentRowNumberPerPage,
      onChangeRowNumber,
      onClickFirstPage,
      onClickLastPage,
      pageDataIndexFrom,
      pageDataIndexTo,
    },
    onClickAddUserButton,
    successMessage,
    onClickMailAddressLink,
    callUserListApi,
    displayContentState,
    setDisplayContentState,
  };
};

export default useUserList;

import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { TFunction } from 'react-i18next';
import { groupDetail, transToGroups } from '../utils/group/groupDetail';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import type { GroupDetailResult } from '../types/Group/groupDetail';
import {
  GROUP_DETAIL_API_RESULT_CODE,
} from '../apis/callGroupDetailApi';
import {
  isErrorType,
} from '../types/apis/apiErrorTypeGuard';
import useRedirectDialog from './useRedirectDialog';

import { ALL_ERROR_TYPE, SERVER_MESSAGE } from '../constants/constants';
import errorMessageCodes from '../constants/errorMessageCodes';

export type UseGroupDetailValue = {
  groupData: React.MutableRefObject<GroupDetailResult | null | undefined>;
  groupDetailErrorType: ALL_ERROR_TYPE | null;
  t: TFunction;
  setGroupDetailErrorType: (
    value: React.SetStateAction<ALL_ERROR_TYPE | null>,
  ) => void;
  errorMessageGroupDetail: string;
  isCallingGroupDetailApi: boolean;
  onClickGroupDetail: (groupId: string) => void;
  onClickGroupList: () => void;
};

type IDParams = {
  groupId: string;
};

const useGroupDetail = (
  openManageGroupDialog: boolean,
): UseGroupDetailValue => {
  const [isCallingGroupDetailApi, setIsCallingGroupDetailApi] =
    useState<boolean>(false);
  const { displayRedirectDialog } = useRedirectDialog();


  const groupData = useRef<GroupDetailResult | null>();
  const [groupDetailErrorType, setGroupDetailErrorType] =
    useState<ALL_ERROR_TYPE | null>(null);
  const { groupId } = useParams<IDParams>();
  const navigate = useNavigate();
  const { t } = useSwitchLocaleLanguage();

  const onClickGroupDetail = (groupIdNo: string): void => {
    // loading Start
    setIsCallingGroupDetailApi(true);
    /*eslint-disable*/
    groupDetail(groupIdNo)
      .then((result) => {
        switch (result.resultCode) {
          case GROUP_DETAIL_API_RESULT_CODE.SUCCESSFULLY_FETCHED: {
            // Success Message
            groupData.current = result;

            break;
          }
          case GROUP_DETAIL_API_RESULT_CODE.INVALID_TOKEN:
          case GROUP_DETAIL_API_RESULT_CODE.WARN_INPUT_PARAM:
          case GROUP_DETAIL_API_RESULT_CODE.CORPORATION_ACCESS_DENIED:
          case GROUP_DETAIL_API_RESULT_CODE.INFO_NO_GROUP:
          case GROUP_DETAIL_API_RESULT_CODE.AUTH_NOT_ALLOWED:
          case GROUP_DETAIL_API_RESULT_CODE.WARN_INVALID_AUTH: {
            // Set an error code in setUserDeleteERrortype for logout dialog displaylogout dialog display
            setIsCallingGroupDetailApi(false);
            throw result.resultCode;
          }
          default:
            throw result.resultCode;
        }
        // LOADINGBAR hidden (erase a memory leak when erased with Finally)
        setIsCallingGroupDetailApi(false);
      })
      .catch((error: ALL_ERROR_TYPE | unknown) => {
        console.log(error, 'error detail');
        if (isErrorType(error)) {
          setGroupDetailErrorType(error);
          if (
            error === GROUP_DETAIL_API_RESULT_CODE.INFO_NO_GROUP ||
            error === GROUP_DETAIL_API_RESULT_CODE.WARN_INPUT_PARAM
          ) {
            displayRedirectDialog({
              open: true,
              title: t('common.error.invalidGroup'),
              message: t('common.error.unavailableGroup') + 
                (error === GROUP_DETAIL_API_RESULT_CODE.INFO_NO_GROUP ? errorMessageCodes['common.error.unavailableGroup'] : errorMessageCodes['common.error.unavailableGroup.WARN_INPUT_PARAM']),
            });
          }
          if (
            error === GROUP_DETAIL_API_RESULT_CODE.GROUP_ACCESS_DENIED ||
            error === GROUP_DETAIL_API_RESULT_CODE.CORPORATION_ACCESS_DENIED ||
            error === GROUP_DETAIL_API_RESULT_CODE.ACCESS_PERMISSION_DENIED
          ) {
            displayRedirectDialog({
              open: true,
              title: t('common.error.notAuthorized'),
              message: t('common.error.unauthorizedAccess') + 
                (error === GROUP_DETAIL_API_RESULT_CODE.CORPORATION_ACCESS_DENIED ? errorMessageCodes['common.error.unauthorizedAccess'] :
                error === GROUP_DETAIL_API_RESULT_CODE.ACCESS_PERMISSION_DENIED ? errorMessageCodes['common.error.unauthorizedAccess.ACCESS_PERMISSION_DENIED'] :
                errorMessageCodes['common.error.unauthorizedAccess.GROUP_ACCESS_DENIED']),
            });
          }
          if (
            error === GROUP_DETAIL_API_RESULT_CODE.WARN_INVALID_AUTH ||
            error === SERVER_MESSAGE.USER_ROLE_CHANGED ||
            error === SERVER_MESSAGE.SUBSCRIPTION_PLAN_CHANGED ||
            error === GROUP_DETAIL_API_RESULT_CODE.AUTH_NOT_ALLOWED
          ) {
            return;
          }
        } else {
          setGroupDetailErrorType(GROUP_DETAIL_API_RESULT_CODE.ERR_UNKNOWN);
        }
        // LOADINGBAR hidden (erase a memory leak when erased with Finally)
        setIsCallingGroupDetailApi(false);
      });
  };

  const errorMessageGroupDetail = useMemo((): string => {
    if (!groupDetailErrorType) {
      return '';
    }
    switch (groupDetailErrorType) {
      case GROUP_DETAIL_API_RESULT_CODE.WARN_INVALID_AUTH:
        return t('groupDetail.error.unauthorizedUser') + errorMessageCodes['groupDetail.error.unauthorizedUser'];
      case GROUP_DETAIL_API_RESULT_CODE.WARN_INPUT_PARAM:
        return t('groupDetail.error.invalidURL') + errorMessageCodes['groupDetail.error.invalidURL'];
      case GROUP_DETAIL_API_RESULT_CODE.INFO_NO_GROUP:
        return t('common.error.unavailableGroup') + errorMessageCodes['common.error.unavailableGroup'];
      default:
        return t('common.error.serverErr') + errorMessageCodes['common.error.serverErr'];
    }
  }, [groupDetailErrorType]);

  // Invalid GroupId or url moved to grouplist page
  const onClickGroupList = () => {
    transToGroups(navigate);
  };

  useEffect(() => {
    if (!openManageGroupDialog) {
      onClickGroupDetail(groupId!);
    }
  }, [openManageGroupDialog]);

  return {
    groupData,
    groupDetailErrorType,
    t,
    errorMessageGroupDetail,
    isCallingGroupDetailApi,
    onClickGroupDetail,
    setGroupDetailErrorType,
    onClickGroupList,
  };
};

export default useGroupDetail;
